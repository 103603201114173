import { CALIDA_GROUP_COMPANY_IDS, POST_GROUP_COMPANY_IDS, TX_GROUP_COMPANY_IDS } from "../customisations/constants";
import { assignToObjectKeys, simpleDeepMerge } from "../utils/object";

export default Object.assign(commonTranslations(), simpleDeepMerge(commonCustomisations(), customisations()));

function commonTranslations() {
    return {
        addLinks:"Add links?",
        whatYouwrite: "What you write",
        whatIsShown:"What is shown",
        anonymValues:"Values are always recorded anonymously.",
        pauseSurvey: "Pause survey",
        surveyPaused: "Survey paused",
        events: {
            start: {
                title: "Survey starts",
                description: "E-mails/SMS with the invitation to participate is sent out to all",
            },
            reminder: {
                title: "Reminder",
                description: "E-mail/SMS reminding the participants who have not filled out yet",
            },
            end: {
                title: "Survey ends",
                description: "Last day of fillout, after which the results are available."
            },
            today: "Today",
        },
        nextDay: "on the next working day",
        changeTeam: "Change team",
        pickNewTeam: "Pick a new {0} team from the organisation structure below.",
        currentTeam: "Current team",
        activeSurveyMoveWarning:
            "Moving a member during an active survey deletes their current answers. After the move, it will be necessary to refill the survey for the new team.",

        startDateNextSurvey: "Start date of the next survey",
        interval: {
            label: "Survey interval",
            text: "How often do you want to have the survey?",
            everyw: "Every week",
            every2w: "Every 2 weeks",
            every3w: "Every 3 weeks",
            monthly: "Every month",
            every6w: "Every 6 weeks",
            every2m: "Every 2 months",
            every3m: "Every 3 months",
            every4m: "Every 4 months",
        },
        rolesTitle: "Roles",
        roleTitle: "Role",
        adminOfTeams: "Admin of",
        addCompanyAdmin: "Add a company admin",
        tags: "Tags",
        masterfile: "Company master file",
        masterfileText: "Helpful tips and validation tool for the masterfile: ",
        processdatafile: "Process data file",
        processdatafileDescription:
         "Here you will find exciting process data for your organisation. You can also track the development of the process data over several surveys.",
        processDataMoreInfo: "More Info",
        processDataInfo: {
            title: {
                survey: "Survey",
                surveyEndDate: "Survey End Date",
                avgQuestionPerTeam: "Average Questions per Team",
                participants: "Participants",
                respondents: "Respondents",
                participationRate: "Participation Rate (%)",
                totalFF: "Total FFs",
                transparencyRate: "Transparency Rate (%)",
                FFPerRespondents: "FFs per Respondent per Question",
                handlingRate: "FF Handling Rate (%)",
                tasksCreated: "Tasks Created",
                closingRate: "Closing Rate (%)",
                delegationRate: "Delegation Rate (%)",
            },
            desc: {
                survey: "Increasing sequence.",
                surveyEndDate: "The date on which each survey was ended.",
                avgQuestionPerTeam: "The average number of questions presented to each team in the survey.",
                participants: "The total number of individuals invited to participate in the survey.",
                respondents: "The number of participants who actually responded to the survey.",
                participationRate: "The percentage of invited participants who completed the survey.",
                totalFF: "The total count of Feedforwards (FFs) received in the survey.",
                transparencyRate: "The percentage of FFs that were non-anonymous compared to the total number of FFs.",
                FFPerRespondents: "The average number of FFs given by each respondent for each question.",
                handlingRate: "The percentage of FFs that were either discussed or where a task has been created.",
                tasksCreated: "The total number of tasks created, including those not directly derived from FFs.",
                closingRate: "The percentage of tasks that were marked 'done', in relation to the total tasks created.",
                delegationRate: "The percentage of delegated tasks, in relation to the total tasks created.",
            },
        },

        myProfile: "My profile",
        teamDetails: "Team details",
        selectUser: "Select user",
        selectUserQR: "Select a user to download their QR code as a PDF",
        pleaseEnterOneFollowing: "Please enter at least one of the following:",
        errorCreatingUser: "Error creating user",
        userTeamMoveWarning:
            "NB: This user is currently in another team. Adding {0} to {1} removes them from {2}.",
        userTeamMoveConfirm: "To confirm the move, click 'Save' again.",
        selectExistingUser: "Select an existing user",
        addNew: "Create new",
        addExisting: "Add existing",
        createMember: "Create a new member",
        createAdmin: "Create a new admin",
        dialCode: "Dial code",
        loadingProfile: "Loading profile",
        firstName: "First name",
        lastName: "Last name",
        updateName: "Update name",
        updateEmail: "Update email address",
        updateMobile: "Update mobile phone",
        errorMobile: "Error updating mobile number",
        errorEmail: "Error updating e-mail address",
        enterMobileText: "Mobile should be entered with a dialcode eg 4178322222",
        updateLanguage: "Update language",
        deleteProfileConfirmation:
            "Are you sure you wish to delete this profile from the organisation?",
        profileDeleted: "This profile is now deleted",
        directingToPrevious: "Directing to previous page",
        deleteProfile: "Delete profile",
        noDeleteEmailError:
            "Can't delete e-mail since at least one contact method needs to be registered. Please add a mobile number to the profile in order to delete the e-mail address.",
        noDeleteMobileError:
            "Can't delete mobile number since at least one contact method needs to be registered. Please add an e-mail to the profile in order to delete the mobile number.",

        // OLD
        entitiesAdministratedBy: "Teams and structures administrated by {0}",
        someGuides: "Here are some guides for you!",
        "20people": "+20 people",
        addEntityToCompany: "Add to company",
        detailsPage: "Details page",
        createEntityText1:
            "In order to create a new {0} structure or a {1} team, click under where it should go.",
        createEntityLvl0Text1:
            "In order to create a new {0} team, click on the {1} Company.",
        createEntityText2:
            "Once created, you can go to their Details page to add admins/members or continue creating other teams/structures.",
        createEntityQuestion: "What would you like to create?",
        nameofNewTeam: "Name of the new team",
        nameofNewStructure: "Name of the new structure",
        mandatoryLangTooltip:
            "Mandatory question language is set by the organization",
        noActiveSurveys: "No active surveys at the moment",
        teamAdmins: "Team admins",
        structureAdmins: "Structure admins",
        teamMove: "Move team",
        structureMove: "Move structure",
        moveText:
            "Click on the structure under which {0} should be. Unavailable options are grayed out.",
        structureLabel: "Structure",
        moveHere: "Move here",
        currentLocation: "Current location",
        teamRename: "Rename team",
        teamDelete: "Delete team",
        structureRename: "Rename structure",
        structureDelete: "Delete structure",
        deleteEntityConfirmation: "Are you sure you wish to delete {0}?",
        structureDeleteInfo:
            "NB: Teams and structures under {0} will also be deleted. Members from deleted teams will not participate in pulse surveys and will be listed on the 'Members' page without an allocated team.",
        teamDeleteInfo:
            "NB: After deletion members from this team will be listed on the 'Members' page without an allocated team. Teamless members do not participate in pulse surveys.",
        newName: "New name",
        cantMoveTopStructure: "Can't move a top level structure",
        memberRemove: "Remove member",
        adminRemove: "Remove admin",
        removeInfo:
            "PS: Once removed, teamless members can be found and reassigned on the 'Members' page.",
        adminRemoveConfirmation:
            "Are you sure you wish to remove {0} as an admin from {1}?",
        memberRemoveConfirmation:
            "Are you sure you wish to remove {0} as a member from {1}?",
        tfaCountry: "Country",
        tfaInvalid: "Invalid number",
        tfaRemove0: "Please remove 0",
        tfaRequired: "Number is required",
        labelMobilePhone: "Mobilný telefón",
        twoFactorTitle: "Two-Factor Authentication",
        twoFactorIntro:
            "In order to see the results once the survey is finished, we ask you activate two-factor authentification. Please enter your mobile phone number where authentication codes could be sent in the future. If you do not do that you can not access the results.",
        twoFactorNoNr:
            "I do not give my number and will not have access to the results.",
        twoFactorNoNrExplanation:
            "Without providing your number you will not be able to access the results dashboard after the survey. Your number will be used only for authentication purposes. If you choose not to provide your number now you can provide it to your supervisor later to enable two-factor authentication for yourself.",
        twoFactorFirst:
            "This information can be viewed after entering a code sent to your mobile phone.",
        noTeamsFoundWith: "No team names found with {0}",
        removeFromTeam: "Remove member",
        removeAsAdmin: "Remove admin",
        noAdmins: "No admins",
        noMembers: "No members",
        seeDetails: "See details",
        loggingOut: "Odhlásenie",
        calculate: "Výpočet",
        calculateENPSavgTitle: "Výpočet priemernej ENPS",
        calculateENPSavgLabel: "Priemerná hodnota ENPS:",
        calculateENPSavgSelect: "Vyberte subjekty na výpočet priemernej ENPS:",
        pwProtectedMessage:
            "Účet je chránený heslom. Prosím, prihláste sa pomocou svojho hesla.",
        passwordError: "Chyba pri nastavovaní hesla",
        passwordLengthError: "Heslo musí mať aspoň 7 znakov.",
        passwordMismatch: "Heslá sa nezhodujú",
        passwordChangeSuccess: "Heslo sa úspešne zmenilo. Prihláste sa {0}.",
        labelNewPassword: "Nové heslo",
        labelRepeatNewPassword: "Zopakuj nové heslo",
        setNewPassword: "Nastavte nové heslo s najmenej 7 znakmi.",
        getNewPwLink: "Získajte nový odkaz na obnovenie hesla {0}",
        resetPw: "Obnovenie hesla",
        resetPwTokenInvalid: "Neplatné prepojenie na obnovenie hesla",
        forgotPwTitle: "Zabudol si heslo?",
        forgotPwText:
            "Pošleme ti e-mail s odkazom, kde si môžeš obnoviť svoje heslo.",
        fillingOutForTeam: "Vypĺňate pre {0}.",
        orContinueWith: "Alebo pokračujte",
        level: "Level",
        showTodo: "Zobraziť môj zoznam úloh",
        hideTodo: "Skryť môj zoznam úloh",
        statistics: "Štatistika",
        commentTooLong: "Maximálna dĺžka komentára je 280 znakov",
        allDiscussedNr: "Super, diskutuje sa o všetkých {0}",
        allDiscussed: "Super, všetky Feedforwards boli prerokované!",
        notEnoughAnswers: "Tentoraz prieskum nevyplnil dostatočný počet členov",
        notEnoughAnswersTeamName:
            "Tentoraz dotazník nevyplnil dostatočný počet členov tímu {0}",
        startPrep: "Využite vstupy tímu a začnite sa pripravovať",
        expandQuestionComments: "Rozbaľte otázku, aby ste videli komentáre",
        pickValidTeam: "Vyberte si tím, ktorý sa zúčastnil prieskumu",
        devGeneralExplanation:
            "Ide o štandardnú odchýlku všetkých hodnotení v prieskume. Menšie číslo znamená, že členovia tímu majú podobné názory. Vyššie číslo znamená, že tím by mal pravdepodobne prediskutovať niekoľko tém, na ktoré sa názory líšia.",
        avgGeneralExplanation:
            "This is the average for all questions in the survey. Smaller average means there's more potential for improvement.",
        signInWith: "Prihláste sa pomocou ",
        whatsNextTitle: "What's next?",
        whatsNextText: "Uplift {0}, one tick box at a time",
        viewDiscussion: "Zobraziť diskusiu",
        startDiscussion: "Začať diskusiu",
        manageSurvey: "Manage survey",
        discussionFor: "Discussion for",
        helpTitle: "Tips & tricks",
        helpText: "Help for discussing the results can be found here",
        finishSurvey: "Finish survey",
        reviewResults: "Prepare discussion",
        reviewResultsHint: "Check out and sort the questions",
        scheduleMeeting: "Schedule a team meeting",
        scheduleMeetingHint:
            "Organize an approx. 45 min team meeting outside this app for the discussion",
        doDiscussion: "Discuss the results in team",
        doDiscussionHint:
            "Click 'Start Discussion' at the bottom of the page. Discuss Feedforwards as a team and create tasks when necessary",
        resultText1:
            "Here you can review questions and change their discussion order.",
        resultText2:
            "To reorder questions by their importance, click and drag the {0} icon.",
        handBuchText: "Preparation guide",
        thisTimeNoFF: "This time there were {0} from your team",
        bravo: "Bravo",
        wow: "Wow",
        ffFromTeam: "from your team",
        discussionCompletedPraise: "Super, you've discussed all Feedforwards!",
        showStats: "Show statistics",
        hideStats: "Hide statistics",
        questionLegend: "Question legend",
        orderExplanation: "Questions will be in Discussion Mode in this order",
        avgExplanation:
            "Color coded to reflect how well team members have graded this question. The bigger the number, the better!",
        devExplanation:
            "Color coded to reflect whether team members have similar or very different opinions on this question. The smaller the number, the better!",
        nrFFs: "Number of Feedforwards",
        nrFFExplanation:
            "Number of comments left by team members for this question",
        adminLvls: "Administration levels",
        profession: "Profession",
        gender: "Gender",
        office: "Office",
        location: "Location",
        age: "Vek",
        keyboardInfoMessage:
            "Prieskum môžeš dokončiť pomocou čísiel na klávesnici.",
        editAnswers: "Upraviť odpovede",
        activeSurvey: "Active survey",
        activeSurveys: "Active surveys",
        peopleWereInvitedFrom:
            "{0} people {1} were invited to fill out the survey ",
        fromEntity: "from {0}",
        fromWholeCompany: "from the whole company",
        personFilledOut: "1 person filled out the survey",
        peopleFilledOut: "{0} people filled out the survey",
        discussionVideoTitle: "Impulse Team discussion",
        gdpr01: "Podmienka na prijatie súhlasu",
        gdpr02: "Ďakujeme, že ste použili Joineer. V súlade so zákonmi o ochrane údajov by sme ťa chceli informovať, že okrem tvojho mena a e-mailovej adresy zhromažďujeme nasledujúce informácie:",
        gdpr03: "• Anonymné kvantitatívne odpovede na otázky Teambarometer.",

        gdpr04: "• Nepovinné anonymné alebo verejne kvalitatívne informácie.",
        // TODO: support always public comments:
        gdpr04pub: "• Nepovinné anonymné alebo verejne kvalitatívne informácie.",

        gdpr05: "Odpovede sú viditeľné pre členov tímu, správcu tímu a spoločnosti.",
        gdpr06: "Všetky údaje budú uložené na serveroch vo Švajčiarsku a nebudú poskytnuté tretím stranám.",
        gdpr07: "K svojim osobným údajom máte kedykoľvek prístup prostredníctvom prepojenia na túto aplikáciu.",
        gdpr08: "Poprosili by sme ťa zobrať na vedomie a prijať tieto podmienky",
        gdprLinkMessage: "", //For more information click
        gdprLinkTitle: "", //here
        gdprLinkUrl: "", //https://www.app.com
        gdpr09: "Ak máš akékoľvek otázky, neváhaj nás kontaktovať (info@joineer.ch).",
        gdpr10: "Ďakujeme za dôveru.",
        gdpr11: "S pozdravom,",
        gdpr12: "Joineer",
        gdprIgiveMyConsent: "Rozumiem a súhlasím",
        introTitle1: "Vitaj v Teambarometer",
        introTitle2: "Tvoj názor sa počíta - a počíta sa neustále",
        introTitle3:
            "FeedFORWARD - Do budúcnosti, pozitívne a orientovane na riešenia! Nie spätná väzba",
        introTitle4: "Vyplň - Diskutuj - Profituj",
        introTitle5: "Si pripravený?",
        introText1:
            "Sme radi, že sa zapájaš - keďže tvoj názor sa počíta." +
            " Pozorne si prečítaj nasledujúce stránky.",
        introText2:
            "S Joineerom budeš párkrát do roka požiadaný o svoj názor v prieskume." +
            " Takto sa meria spokojnosť.  Pokrok a výsledky sa môžu porovnávať.",
        introText3:
            "Nie sme urna sťažností. Nekritizuj, čo sa pokazilo, no daj nám vedieť, ako sa môžeme zlepšiť." +
            "Do budúcnosti, pozitívne a orientované na riešenia - jednoducho Feedforward!",
        introText4:
            "Po prieskume sa odpovede zobrazia na nástenke." +
            " Najdôležitejšie je, aby si sa rozprával o výsledkoch s tímom!" +
            " To je jediný spôsob, ako môže byť Teambarometer efektívny.",
        introText5L:
            "Všetko je jasné? Ak máš nejaké otázky, neváhaj nás kontaktovať na adrese info@joineer.ch." +
            " Na ďalšej stránke sa prihlásiš  pomocou e-mailu a hesla a poďme na to!",
        introText5:
            "Všetko je jasné? Ak máš nejaké otázky, neváhaj nás kontaktovať na adrese info@joineer.ch. Poďme na to!",
        introSkip: "Preskočiť úvod",
        surveyToFill: "Prieskum na vyplnenie",
        hi: "Ahoj",
        surveyStartTitle: ", super že si tu!",
        startGoMessage:
            "Podeľ sa, čo by sa dalo v budúcnosti zmeniť k lepšiemu - pozitívne a orientované na riešenie s Feedforward.",
        startGoMessage2:
            "Výsledky sú prezentované na nástenke a diskutované v tímoch.",
        pulseSurvey: "Pravidelný prieskum",
        reference: "Reference",
        surveyEndTitle: "Super, takmer hotovo...",
        surveyEndMessage:
            "Podal si všetky svoje Feedforward? Ak áno, klikni na tlačidlo „Odoslať hneď“. Ak chceš niečo zmeniť alebo pridať ďalší komentár, tak pomocou tlačidla „Späť“ sa vrátiš na otázky.",
        startSurvey: "Start survey",
        questionsInSurvey: "Questions in survey",
        pickFromCatalog: "Pick from catalog",
        createQuestion: "Create a question",
        sendReminderTooltip: "Send a reminder e-mail",
        sendReminders: "Send {0} reminders",
        sendReminder: "Send 1 reminder",
        addMembers: "Add members",
        addMember: "Add member",
        addAdmin: "Add admin",
        availableSoon: "Available soon!",
        remind: "Remind",
        remindAll: "Remind all",
        respondedQ: "Responded?",
        hasNotFilled: "This member has not filled out the survey",
        hasFilled: "This member has filled out the survey",
        membersPerPage: "Members per page",
        yes: "yes",
        endSurvey: "End survey",
        general: "General",
        surveysPaused: "Regular surveys are paused",
        noIntervalResults: "No interval results available",
        status: "Status",
        results: "Results",
        planned: "PLANNED",
        ongoing: "ONGOING",
        finished: "FINISHED",
        surveyTitle: "Survey title",
        startDate: "Start date",
        endDate: "End date",
        participants: "Participants",
        total: "Total",
        totalParticipants: "Total participants",
        page: "Strana",
        previousPage: "Predchádzajúca strana",
        nextPage: "Ďalšia strana",
        or: "or",
        none: "none",
        today: "today",
        here: "tu",
        structure: "Organizačná štruktúra",
        roles: {
            superadmin: "Spoločnosť admin",
            admin: "Tím admin",
            member: "Člen",
        },
        greeting: "Vitajte",
        buttons: {
            settings: "Options",
            saveChanges: "Save changes",
            reload: "Reload",
            filter: "Filter",
            close: "Zatvoriť",
            surveyGo: "Poďme na to",
            surveyEnd: "Odoslať teraz",
            go: "Go",
            start: "Start",
            cancel: "Cancel",
            reset: "Reset",
            resetAll: "Reset all",
            resetSelection: "Reset selection",
            back: "Back",
            backdash: "Back to dashboard",
            next: "Next",
            finish: "Finish",
            finalise: "Finalise",
            save: "Save",
            reopen: "Reopen",
            delete: "Delete",
            send: "Send",
            markDone: "Discussed",
            done: "Done",
            moveUp: "Delegate",
            newTask: "New task",
            expand: "Expand all",
            collapse: "Minimise all",
            details: "Details",
            new: "New",
            edit: "Edit",
            add: "Add",
            addLanguage: "Add language",
            addQuestion: "Add question",
            create: "Create",
            askExpert: "Ask the expert",
            goTo: "Prejsť na",
            showAllFF: "Zobraziť všetky Feedforwardy",
            hideAllFF: "Skryť všetky Feedforwardy",
            changeOrder: "Change order",
            continue: "Pokračuj",
            yesDelete: "Yes, delete",
            yesRemove: "Yes, remove",
        },
        snackbar: {
            detailsUpdated: "Details updated",
            remindersSent: "Selected reminders sent!",
            ffClosed: "Feedforward marked as discussed",
            ffOpened: "Feedforward opened",
            ffEscalated: "Feedforward sent to review by management",
            taskClosed: "Task closed",
            taskOpened: "Task reopened",
            taskCreated: "Task created",
            taskDeleted: "Task deleted",
            taskDelegated: "Task delegated",
            emailSent: "Email sent!",
            forgotPWemailSent: "Email sent if account exists",
            downloading: "Downloading",
            downloadError: "Download error!",
            questionDeleted: "Question was deleted",
            questionAdded: "New question was added",
            questionEdited: "Question was edited",
            reordered: "Questions were reordered",
            surveyDeleted: "Survey deleted",
            memberAdded: "Member was added to survey",
            memberRemovedFromSurvey: "Member was removed from survey",
            startChanged: "Survey start date was changed",
            endChanged: "Survey end date was changed",
            titleChanged: "Survey title was changed",
            memberRemoved: "Member was removed",
            adminRemoved: "Admin was removed",
            teamCreated: "Team was created",
            structureCreated: "Structure was created",
            teamDeleted: "Team was deleted",
            structureDeleted: "Structure was deleted",
            structureRenamed: "Structure was renamed",
            teamRenamed: "Team was renamed",
            teamMoved: "Team was moved",
            structureMoved: "Structure was moved",
            newMemberCreated: "New member was created",
            newAdminCreated: "New admin was added",
            surveyUpdated: "Survey was updated",
        },
        tooltips: {
            editTeamName: "Edit the name",
            deleteTranslations: "Delete {lang} translations",
            discussed: "Mark Feedforward as discussed",
            createTask: "Create task from Feedforward",
            createFreeTask: "Create a task not related to any specific Feedforward",
            closeTask: "Mark task as completed",
            moveUp: "Move task up to admins",
            deleteTask: "Delete task",
            reopenFF: "Reopen Feedforward",
            addComment: "Add comment",
            discussedDisabled:
                '"Mark Feedforward as discussed" disabled in preview mode',
            disabled: "Disabled in preview mode",
            hideFF: "Mark Feedforward as handled in a One-on-One and don't show in Discussion mode",
            showFF: "Show Feedforward in Discussion Mode",
            required: "Required",
            pressEnter: "Press Enter to save",
            surveyCanNotBeDeleted: "Vymazať možno len plánované prieskumy.",
        },
        nav: {
            companyAdmins: "Company admins",
            calendar: "Survey calendar",
            dashboard: "Nástenka",
            discussion: "Diskusný režim",
            reports: "Správy",
            delegatedTasks: "Delegované úlohy",
            teamTasks: "Tímové úlohy",
            team: "Tím",
            members: "Členovia",
            interval: "Interval",
            adhoc: "Prieskumy ad hoc",
            questions: "Otázky",
            profile: "Profil",
            logout: "Odhlásenie",
            intercomChat: "Chat with us!",
            qrCodes: "QR letters",
            ffanalysis: "FeedForward Analysis"
        },
        selectSurvey: {
            customised: "Customised",
            choose: "Choose a survey to review in Discussion Mode",
            now: "now",
            team: "Team",
            selectTeam: "Select team",
            placeholder: "Select survey",
            goDiscussion: "Go to discussion mode",
            selectModeLabel: "Select viewing mode",
            preview: "Preview mode",
            prepare: "Prepare",
            previewBody:
                "This is a preview of the Discussion Mode before the actual Teammeeting. You can hide certain Feedforwards in case you prefer to resolve them in a one-on-one meeting",
            present: "Present",
            presentBody:
                "Present and discuss the results with your team. {0} Actionable Feedforwards can be turned into team tasks",
            noTeam: "No such team",
            noSurvey: "No surveys completed yet",
            comeBack:
                "Come back to discuss the results once your first survey is complete!",
            handbuchNeedHelp: "Could use some help with the first steps?",
            handbuchLink: "Check out our {icon} Teamleader Handbook {here}",
            questionOrder: "Poradie diskusií",
            original: "ako v prieskume",
        },
        discussionMode: {
            question: "Question",
            survey: "survey",
            anonym: "Anonymous",
            noFF: 'No Feedforwards, click "Next"',
            noDiscussed: "Feedforwards marked as discussed {0} will appear here",
            noTasks: "Feedforwards turned into tasks {0} will appear here",
            createTask: "Create a task from Feedforward",
            feedforwards: "Feedforwards",
            discussed: "Discussed",
            tasks: "Tasks",
            moveUpTo: "Move task up to:",
            highlight: "Delegate this Feedforward",
            describe: "Describe why this is important to be passed on",
            selectLabel: "For the attention of:",
            unable: "Unable to solve within team?",
            hiddenFFs:
                "The Feedforwards below are hidden from Discussion Mode presentation. The number of hidden Feedforwards will still be shown",
            nrhiddenFFs: "Number of Feedforwards handled in a One-on-One:",
        },
        achievements: {
            title: "Achievements",
            intro: "Here's what's been accomplished already",
        },
        tasks: {
            taskPage: "Stránka s úlohami",
            taskBannerText: "Úlohy vytvorené na základe diskusie",
            createTitle: "Create task",
            taskDisabled: "Creating tasks is disabled in preview mode",
            titlePlaceholder: "Task title",
            title: "Title",
            commentPlaceholder: "Optional comment",
            origin: "Originated from",
            addComment: "Add comment",
            comments: "Comments",
            noComments: "No comments",
            dueDate: "Due date",
            dueBy: "Due by",
            setDueDate: "Set a due date",
            assign: "Assign task to",
            assignedTo: "Assigned to",
            other: "other",
            enterTitle: "Please enter task title",
            myTasks: "My tasks",
            teamTasks: "Team tasks",
            openTasks: "Open tasks",
            completedTasks: "Completed tasks",
            deleteTitle: "Delete task",
            deleteBody: "Are you sure you wish to delete this task permanently?",
            noTasks: "No open tasks",
            delegatedExplanation:
                "List of Feedforwards or team tasks that were delegated for the attention of company admins.",
            noCompletedTasks: "No completed tasks yet",
            noDelegatedTasks: "No delegated tasks yet",
            assigneeRequiredMessage: "Prosím, vyber člena, ktorému chceš úlohu prideliť",
            sendEmail: "Send task summary via e-mail",
            calendarEntry: "With calendar entry",
            calendarEntryNo: "Without calendar entry",
            commentDeletedBy: "Comment deleted by {updatedBy}",
            deleteComment: "Delete this comment?",
            updateComment: "Save changes",
            edited: "edited",
            editComment: "Edit comment",
            afterDelete:
                "After deletion the this will be shown in the task comment history:",
        },
        completion: {
            title: "Well done!",
            tasksCreated: "Tasks created",
            ffDiscussed: "Feedforwards discussed",
            go: "Go to taskboard",
        },
        intro: {
            title: "Select question order",
            intro: "Based on the results we would recommend to discuss Feedforwards in the following order. Drag {0} and drop to change order",
            start: "Start discussion",
        },
        login: {
            title: "Login",
            pw: "Password",
            forgot: "Forgot password?",
            withLink: " with direct link",
            withPassword: " with password",
            checkEmail: "Check your e-mail for a direct login link",
            incorrectEmail: "Incorrect or missing e-mail",
            labelSendLink: "Send link",
            labelLinkSent: "Request successful",

            enterNameAndPassword: "Please enter your email and password.",
            enterEmail:
                "Please enter your e-mail to retrieve a direct log-in link.",
            resetSent:
                "If an account exists with this email, reset password link has been sent to it!",
            getLink: "Retrieve login link",
        },
        admin: {
            title: "Admin board",
            allTeams: "All teams",
            allSurveys: "All surveys",
            selectedTeams: "Selected teams",
            deselectAll: "Deselect all",
            selectAll: "Select all",
            created: "Created on",
            markDone: "Mark as resolved",
            done: "Resolve",
            latestComment: "Latest comment",
            lessComments: "less comments...",
            moreComments: "more comments...",
            empty: "Nothing here yet",
            deleteConfirmation: "Are you sure you wish to delete this permanently?",
        },
        stats: {
            decreased: "decreased",
            increased: "increased",
            avg1: "This is the average team rating for this question. Smaller average means there's more potential for improvement. ",
            avg2: "Compared to the previous survey the result has {0} by {1}%.",
            dev1: "This is the standard deviation of the ratings for this question. Smaller number means the team members have similar opinions. Higher number means that the team should discuss this topic. ",
            dev2: "Compared to the previous survey the differences have {0} by {1}.",
            graphIntro: "Below graphic may help interpret the results",
            devGraph1Label: "Agreed",
            devGraph1Explanation: "One heart, one soul.",
            devGraph2Label: "Diverse",
            devGraph2Explanation: "Deviations in normal range.",
            devGraph3Label: "Need for discussion",
            devGraph3Explanation: "You assess the situation differently.",
            avgGraph1Label: "Improve",
            avgGraph1Explanation: "Great potential for improvement exists.",
            avgGraph2Label: "Neutral",
            avgGraph2Explanation: "Sufficient. Improve selectively.",
            avgGraph3Label: "Keep it up",
            avgGraph3Explanation: "Give and receive praise.",
            histogramTitle: "Distribution of answers",
            histogramNr: "Number of people",
            histogramGrade: "Grade",
            histogramAnswered: " people answered ",
        },
        report: {
            title: "Teambarometer report",
            selectLabel: "Viewing report for",
            company: "Company",
            summary: "Summary",
            all: "All",
            pleaseWait: "Loading report... please wait",
            noData: "No report available",
            searchLabel: "Search",
            name: "Name",
            totalFF: "Total Feedforwards",
            noFFsInSurvey: "No Feedforwards were submitted in this survey",
            closedFF: "Discussed Feedforwards",
            totalTasks: "Total Tasks",
            taskedFF: "Tasked Feedforwards",
            delegatedTasks: "Delegated Tasks",
            closedTasks: "Closed Tasks",
            wholeCompany: "Whole company",
            discussionSummary: "Discussion summary",
            discussionSummaryText:
                "Overview of the result discussions held within the company. Check out in which teams the inputs were already discussed or implemented.",
            download: "Download",
            downloadSummary: "Download summary (.pptx)",
            preparingFile: "Preparing file...",
            presentation: "Presentation",
            matrixTitle: "Matrix heatmap",
            matrixText: "Color-coded overview of answers per team and question.",
            matrixTextMinFill:
                "Teams without enough answers are excluded from the report",
            selectReport: "Select report",
            displayError:
                "Table can not be generated. Please try downloading the results instead.",
            averages: "Averages",
            deviations: "Standard deviations",
            enps: "ENPS",
            enpsScore: "ENPS score",
            answerNr: "Number of answers",
            surveyEnded: "Survey ended",
            "1dayAgo": "{0} day ago",
            xdaysAgo: "{0} days ago",
            companyGoal: "Company goal",
            handleFeedforwards: "handle all Feedforwards",
            howManyHandled: "{0} out of {1} Feedforwards handled",
            handlingRate: "Handling rate",
            FFHandleTitleCompanyAdmin: "How well are your teams progressing?",
            FFHandleTextCompanyAdmin:
                "To make the survey a team success, it's important to handle all Feedforwards soon after the survey.",
            "2weeksafter":
                "2 weeks after the survey the table is colored by Feedforward handling rate. The colors present the % of Feedforwards that are discussed or tasked within the team.",
        },
        errors: {
            selectUser: "Please select a user first",
            submit: "Error submitting survey",
            invalidFormat: "Invalid format",
            notPossibleRunningSurvey: "Not possible during a running survey",
            oops: "Oops!",
            "error-4000":
                "Not enough data available for this survey and selected tags",
            noData: "No data",
            notEnoughData:
                "Not enough data available for this survey and selected tags",
            notEnoughResponseData: "Not enough response data available",
            displayError: "Table can not be generated",
            generalError: "Niečo sa pokazilo",
            featureNotEnabled:
                "Táto funkcia nie je na vašom účte povolená. Pre viac informácií nás kontaktujte na info@joineer.ch alebo +41 44 510 38 00.",
            featureNotAllowed:
                "Táto funkcia nie je povolená pre vašu rolu používateľa",
            missingFields: "Vyplňte všetky polia",
            noPermission: "Žiadne povolenie na zobrazenie tejto stránky",
            incorrectUrl: "Nesprávna adresa URL",
            incorrectEmail: "Nesprávny formát e-mailu",
            login400: "Chýbajúci e-mail alebo iné prihlasovacie údaje",
            login401: "Heslo alebo používateľské meno je nesprávne",
            login401token: "Prihlasovací token je nesprávny",
            login403:
                "Účet je chránený heslom. Prosím, prihláste sa pomocou svojho hesla.",
        },
        dashboard: {
            participation: "Participation",
            surveyResults: "Survey results",
            summary: "Summary",
            resultsFor: "Results for",
            wholeCompany: "Whole company",
            compareWith: "Compare with",
            compareExplanation: "Compare results against the following:",
            questionOverview: "Question overview",
            average: "Average",
            deviation: "Standard deviation",
            companyHistory: "Company average over past surveys",
            compareHistory: "Average over past surveys",
            filterExplanation: "Filter survey results by tags:",
            "dev-good": "Agreed",
            "dev-normal": "Diverse",
            "dev-bad": "Very diverse",
            distributionAnswers: "Distribution of answers",
            surveyDate: "Survey date",
            companyAvg: "Company average",
            positive: "positive",
            negative: "negative",
            noFFs: "No Feedforwards",
            perMember: "na člena",
            otherTags: "Other tags",

            waitingSurvey: "Čaká sa na spustenie prvého prieskumu ",
            waitingTeam: "Čaká sa na váš tím, kým vyplní prieskum",
            surveyStatus: "Stav prieskumu: ",
            meanWhile: "Zatiaľ môžete:",
            here: "tu",
            addMembers: "{0} pridajte nových používateľov",
            editQ: "{0} upravte otázky",
            editInterval: "{0} upravte interval",
        },
        teamMemberDisplay: {
            title: "Členovia, ktorí sa zúčastnili prieskumu",
            noResponse: "Žiadni členovia tohto tímu neodpovedali na prieskum"
        },
        questionEditor: {
            questionsTitle: "Questions",
            questionsIntro:
                "Questions with a yellow {0} background are asked company wide or in a governing department. Questions with a blue {1} background are only asked in the selected team/department.",
            questionsIntroType:
                "Questions of the same type show a link icon {0} when hovering over. Survey statistics to these questions are compared with each other.",
            survey: "Survey",
            company: "Company",
            companyQ:
                "View and edit company wide questions that each team and department has in their survey",
            teamSelectLabel: "Viewing questions for:",
            currentSurvey: "Ongoing survey",
            pastSurvey: "Past survey",
            nextSurvey: "Next survey",
            noEdit: "Past surveys are here for reference and may not be edited",
            adminQuestionNoEdit:
                "This question is fixed within the company or department. May only be adjusted by a department or company admin",
            noQuestionEdit: "This is a higher level question and can not be edited",
            noQuestionEditView:
                "Can only be edited on a higher department or company view level",
            addError: "Question can not be added.",
            editError: "Question can not be edited.",
            deleteError: "Question can not be deleted.",
            nextError: "Can not retrieve next survey.",
            catalogIntro:
                "Be inspired by our catalogue of questions. Open the categories and select a question by clicking on it. Once you have assigned a question to your survey, you can change the illustration and text using the edit icon {0}.",
            questions: {
                question: "Question",
                type: "Type",
                illustration: "Illustration",
                topic: "Topic",
                preview: "preview",
                dragOrder: "Drag {0} and drop to change question order.",
                addQuestion: {
                    en: "English",
                    de: "German",
                    it: "Italian",
                    fr: "French",
                    pl: "Polish",
                    sk: "Slovakian",
                    hu: "Hungarian",
                },
                mandatory: "Mandatory language",
                language: "Language",
                chooseLang: "Choose language",
                example: "Example: ",
                exampleQ: "Are you satisfied with the contents of your job?",
                exampleT: "Work tasks",
            },
            addQuestion: {
                addTitle: "Add question to survey",
                newTitle: "New",
                catalogTitle: "Catalog",
                reuseTitle: "Reuse",
                new: "Make your own custom question to fit your team's needs",
                catalog: "Choose a new question from the catalog",
                reuse: "Add a previously used question to the survey",
                noReuse: "No question history yet",
                newType:
                    "Create a new question measuring your team's responses about a certain topic",
                newLinkedType:
                    "Create a new question that is linked with a previously used question",
                linkedTooltip1:
                    "This option is good for creating questions that measure the same thing, but are worded differently or asked under a new angle.",
                linkedTooltip2:
                    "Connecting similar questions allows convenient and visual statistics between surveys as shown below.",
                linkTitle: "Link question",
                textTitle: "Question text",
                copyNotice:
                    "Texts from the selected question have been copied below for your convenience",
                noLangContact:
                    "If you'd like to add a language not listed here, please {0} us and we're happy to add it manually for you",
                contact: "contact",
                langsUsed:
                    "All {0} default languages are used. If you'd like to add more languages, please {1} us and we're happy to add it manually for you.",
            },
            search: {
                mayAdd: "You may add a previously used question into the survey",
                searchLabel: "Search from existing questions",
                willBeLinked:
                    "Statistics for the question you create now will be compared to the below question:",
                askedBefore: "This question has been asked before in:",
            },
            dialogs: {
                editQ: "Edit question",
                editExplanation: "Edit wording, illustration or add new languages",
                deleteExplanation: "Delete question from survey",
                deleteTitle: "Delete question",
                deleteConfirmation: "Would you like to delete this question?",
                deleteLong:
                    "Deleting the question means that it will not appear in any future surveys, but you will still see it in past statistics",
            },
        },
        forms: {
            search: "Search",
        },
        adhoc: {
            description1: "One-off surveys",
            surveyDetails: "Survey details",
            detailsDescription: "Set title and dates",
            participantsDescription: "Choose who fills out the survey",
            participantsMinFill1:
                "At least 1 member would have to fill out the survey for results to be calculated",
            participantsMinFillLarger:
                "At least {0} members would have to fill out the survey for results to be calculated",
            notEnoughMembers: "Not enough members in survey! Minimum: {0}",
            questionsDescription: "Choose questions for the survey",
            review: "Review",
            reviewDescription: "Summary of the survey",
            activeFilters: "Active filters",
            filterByEntities: "Filter by entities",
            active: "active",
            filterByTags: "Filter by tags",
            reset: "Reset all filters and deletes participants added to survey",
            removeFromSurvey: "Remove participant from survey",
            usersAddedToSurvey: " participants added to survey",
            questionsAdded: "Questions added to the survey",
            surveyPeriod: "Survey period",
            editParticipants: "Go back and edit survey participants",
            editPeriod: "Go back and edit survey period",
            editQuestions: "Go back and edit survey questions",
            editTitle: "Go back and edit survey title",
            datesWrongError:
                "Oops! Please make sure the end date is after the start date",
            surveySuccess: "Survey created successfully",
            surveyDeleteConfirmation:
                "Are you sure you wish to delete this survey?",
            noMembersFound:
                "No members found with current filters. Try removing some of the active filters to broaden the selection or {0} filters",
            manageSurvey: "Manage survey",
            endNow15min:
                "This action will stop the ongoing survey. Changes will take effect in 15 minutes.",
            endNowConfirmation: "Are you sure you wish to end this survey?",
        },
        askExpert: {
            text: "Difficult situation in the team? Unsure how to discuss the results in the team? {0} will be happy to support you in challenging questions.",
            subject: "Subject",
            from: "From:",
            to: "To:",
            messagePlaceholder: "I have a following question...",
            askQuestions: "If you have specific questions, ",
            yourExpertHere: "ask your expert here",
        },
        survey: {
            admin: "Admin",
            team: "Tím",
            opener: "V budúcnosti by bolo dobré",
            publicComment: "Verejný komentár",
            anonComment: "Anonymný komentár",
            infoNumericalValueResponse:
                "Číselná hodnota: Zhromažďuje sa bez vášho mena",
            infoAnonComment:
                "Anonymný komentár: Zhromažďuje sa bez vášho mena",
            infoPublicComment:
                "Verejný komentár: Tvoje meno je viditeľné pre tvoj tím a správcov",
            labelNoAnswerGiven: "Nie je poskytnutá žiadna odpoveď",
            labelCantAnswer: "Na túto otázku nemôžem odpovedať",
        },
        "genericApiErrorMessage": "Operáciu nebolo možné vykonať",
    };
};

function commonCustomisations() {

    return Object.assign({}, txGroup());

    function txGroup() {

        return assignToObjectKeys(TX_GROUP_COMPANY_IDS, {
            // no SK version yet
        }, {});
    }
}

function customisations() {
    return {
        // no customisations yet
    };
}
