export const otp = {
    namespaced: true,
    state: {
        cooldownActive: false,
        cooldownTimeLeft: 0,
        maskedPhoneNumber: null,
    },
    mutations: {
        SET_MASKED_PHONE_NUMBER(state, number) {
            state.maskedPhoneNumber = number;
        },
        startCooldown(state, duration) {
            state.cooldownActive = true;
            state.cooldownTimeLeft = duration;
        },
        decrementCooldown(state) {
            state.cooldownTimeLeft--;
            if (state.cooldownTimeLeft <= 0) {
                state.cooldownActive = false;
            }
        },
        endCooldown(state) {
            state.cooldownActive = false;
            state.cooldownTimeLeft = 0;
        }
    },
    actions: {
        clear({ commit }) {
            commit('SET_MASKED_PHONE_NUMBER', null);
            commit('endCooldown');
        },

        startCooldown({ commit, state }, duration) {
            commit('startCooldown', duration);

            const interval = setInterval(() => {
                commit('decrementCooldown');
                if (!state.cooldownActive) {
                    clearInterval(interval);
                }
            }, 1000);
        },
        endCooldown({ commit }) {
            commit('endCooldown');
        },
        updateMaskedPhoneNumber({ commit }, number) {
            commit('SET_MASKED_PHONE_NUMBER', number);
        },
    }
}
