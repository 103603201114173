<template>
    <v-footer color="transparent" dense>
        <v-spacer></v-spacer>

        <v-menu top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-15" text v-bind="attrs" v-on="on" :ripple="false">
                    <span style="font-size: 14px" class="grey--text"
                        >{{ $i18n.locale.toUpperCase() }}
                    </span>
                    <v-icon class="ml-1" color="grey">language</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    :class="{ 'active-lang': $i18n.locale == lang }"
                    @click="
                        $i18n.locale = lang;
                        setDateLocale();
                    "
                    v-for="lang in languageSelectMenu"
                    :key="lang"
                >
                    <v-list-item-title>{{
                        lang.toUpperCase()
                    }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-footer>
</template>

<script>
import { changeUserLanguage  } from "@/api/settings.api";
export default {
    name: "NavbarBottom",
    data: () => ({}),
    mounted() {},
    computed: {
        languageSelectMenu() {
            return this.$store.state.supportedLangs.length === 0 ? ["en", "de", "fr", "it", "pt"] :  this.$store.state.supportedLangs;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        }
    },
    watch: {},
    methods: {
        setDateLocale() {
            this.$moment.locale(this.$i18n.locale);
            document.documentElement.setAttribute("lang", this.$i18n.locale);

            if(this.loggedIn){
                   changeUserLanguage(this.$i18n.locale)
                .then(() => {
                    this.piwikAnalytics(['trackEvent', 'User language changed', this.$i18n.locale])
                    this.$store.dispatch("changeUserLang", this.$i18n.locale)
                    if (this.$devMode) {
                        console.log(
                            "user language changed to " +
                                (this.$i18n.locale)
                        );
                    }
                })
            }


        },

    }
};
</script>

<style>
.active-lang {
    background: rgb(222, 238, 252) !important;
}
</style>
