import Configuration from "@/services/configuration";
import { auth } from "@/store/auth.module";
import { store } from "@/store/store";

export function routeHandler(
    toData,
    fromData,
    authData,
    loggedIn,
    targetUrl,
) {
    if (!loggedIn) {
        if (fromData.path !== "/login") {
            return targetUrl ? `/login?target=${targetUrl}` : "/login";
        }
    } else {
        let user = authData?.state?.user || {};
        let toDataPath = toData?.path || '';

        if (user.showIntro) {
            if (toDataPath.includes("intro")) {
                return true;
            }
            return `/intro?target=${toDataPath}`;
        }

        if (!user.gdprAccepted) {
            if (toDataPath.includes("gdpr")) {
                return true;
            }
            return `/gdpr?target=${toDataPath}`;
        }

        if (window.sessionStorage.getItem('hasActiveSurvey') === 'true') {
            window.sessionStorage.removeItem('hasActiveSurvey');
            if (toDataPath.includes("/survey/start")) {
                return true;
            }
            return `/survey/start?target=${toDataPath}`;
        }

        const routeRoles = toData?.meta?.roles;
        if (!routeRoles) {
            return true;
        } else {
            const userRoles = (user.authorities || []).map(
                item => item.authority
            );

            // check if user has rights to see the page
            let rolePermission = false;
            routeRoles.forEach(role => {
                if (userRoles.includes(role)) {
                    rolePermission = true;
                }
            });

            if (rolePermission) {
                return true;
            } else {
                l("[routerSecurity]: user not authorised for " + toDataPath);

                return "/404";
            }
        }
    }
}

const routeGuard = async (to, from) => {
    l("[routerSecurity]: beforeEach: " + from.path + " -> " + to.path);

    // If no authentication Required, no guard needed
    if (!to.meta.authRequired) {
        l("[routerSecurity]: No restrictions to access " + to.path);
        return true;
    }

    let loggedIn = auth.state.status.loggedIn;

    return routeHandler(to, from, auth, loggedIn, to.query?.target);

};

export default routeGuard;
