import {httpClient} from "./httpClient";

const END_POINT = "/users";

const getMyDetails = () => httpClient.get("/user");

const updateMyDetails = (user) => httpClient.put("/user", user);

// Get all users for which the logged in user has admin permissions for
const getUsers = () => httpClient.get(END_POINT);

// create a teamless user
/* {
    "firstName": "string",
    "lastName": "string",
    "email": "string",
    "mobile": "string"
  }
 */

const getUserDetails = (user_id) => httpClient.get(`${END_POINT}/${user_id}`);



const updateUserDetails = (user_id, user) =>
    httpClient.put(`${END_POINT}/${user_id}`, user);

const createUser = (user) => httpClient.post(END_POINT, user);

const removeUser = (user_id) => httpClient.delete(`${END_POINT}/${user_id}`);

export { getUsers, createUser, removeUser, getUserDetails, updateUserDetails, getMyDetails, updateMyDetails };
