// check if tutorial 
function showTutorial(name, store, tutrialUrls, locale) {
    const storageKey = `wasTutorialShown_${name}|${store?.state?.auth?.user?.userId}`;
    const showTutorial =
            (!!store?.state?.auth?.user?.userId)
        && (!!tutrialUrls[locale])
        && (store?.getters?.checkFeature('TUTORIAL_VIDEOS') || !!sessionStorage.getItem('TUTORIAL_VIDEOS'))
        && (!localStorage.getItem(storageKey) || !!sessionStorage.getItem('ignore_wasTutorialShown'));

    if (showTutorial) {
        localStorage.setItem(storageKey, 'true');
    }

    return !!showTutorial;
}

function tutorialUrl(tutrialUrls, locale, store) {
    return tutrialUrls[locale]
    	|| tutrialUrls[store?.getters?.mandatoryLang]
    	|| tutrialUrls[store?.state?.supportedLangs[0]]
    	|| tutrialUrls['de'];
}


export {
    showTutorial,
    tutorialUrl,
};

