<template>
    <v-stepper-content :step="step" class="pt-0 px-0 px-sm-2 px-md-5 px-lg-12">
        <v-card
            v-scroll="onScroll"
            class="grey lighten-4 stickyQuestion"
            v-show="showStickyQuestion"
            tile
        >
            <div class="text-center pa-2" style="font-size: 1.05rem">
                <v-icon color="primary">mdi-forum-outline</v-icon>
               <span v-html="parseLinks(translatedText(question.questionText, $i18n.locale))"></span>
            </div>
        </v-card>

        <v-container class="px-0 px-sm-2 px-md-5 px-lg-12 pb-0">
            <div align="center" class="px-3 ">
                <p class="caption text-uppercase grey--text mb-0">
                    {{ translatedText(question.title, $i18n.locale) }}
                </p>
                <v-icon
                    v-if="question.isTeamQuestion"
                    class="mx-2 float-right"
                    large
                    :title="$t('survey.teamQuestion')"
                >{{ '$vuetify.icons.diversity'}}</v-icon>

                <p
                    class="mb-0 mb-sm-1"
                    :class="{
                        questionbig: $vuetify.breakpoint.smAndUp,
                        question: $vuetify.breakpoint.xs,
                        'mb-0': !question.statistic
                    }"
                    v-html="parseLinks(translatedText(question.questionText, $i18n.locale))"
                >
                </p>
            </div>

            <QuestionStats
                align="center"
                :statistics="question.statistic"
                v-if="question.statistic && $vuetify.breakpoint.xsOnly"
            />

            <div class="d-flex justify-space-between">
                <v-btn
                    @click="$emit('back', 'true')"
                    class="align-self-end ml-1 ml-sm-0"
                    >{{ $t("buttons.back") }}</v-btn
                >

                <QuestionStats
                    :statistics="question.statistic"
                    v-if="question.statistic && $vuetify.breakpoint.smAndUp"
                />

                <v-btn
                    @click="$emit('next', 'true')"
                    class="align-self-end mr-1 mr-sm-0"
                    color="primary"
                    >{{ nextLabel }}</v-btn
                >
            </div>
        </v-container>

        <v-container
            class="pt-0"
            v-if="
                question.statistic &&
                    question.statistic.hasOwnProperty('histogram')
            "
        >
            <v-btn
                color="grey darken-2"
                style="font-size: 0.8rem"
                text
                class="d-flex mx-auto"
                :ripple="false"
                @click="showChart = !showChart"
                >{{ $t("buttons.details") }}
                <v-icon small>
                    {{
                        showChart ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon
                >
            </v-btn>
            <div
                v-if="showChart"
                style="max-width:650px"
                class="d-flex flex-column mx-auto"
            >
                <p class="text-center">
                    {{ $t("dashboard.distributionAnswers") }}
                </p>

                <Histogram
                    :histogramRaw="question.statistic.histogram"
                    :questionId="question.id"
                />
            </div>
        </v-container>

        <v-container class="px-0 px-sm-2 px-md-5 px-lg-12">
            <!-- OPEN -->
            <v-card>
                <v-toolbar class="elevation-1" color="grey lighten-5" dense>
                    <v-icon color="grey">mdi-comment-text-outline</v-icon>
                    <v-toolbar-title class="pl-3">{{
                        $t("discussionMode.feedforwards")
                    }}</v-toolbar-title>
                </v-toolbar>

                <div style="background-color: #FFFEF9">
                    <div
                        v-if="
                            question.hasOwnProperty('questionIntro') &&
                                question.questionIntro
                        "
                        class="pa-3"
                        style="font-size:1.2rem"
                    >
                        {{ question.questionIntro.de }}
                    </div>
                    <v-divider></v-divider>
                </div>

                <v-container class="pt-5">
                    <v-row justify="center" class="mt-3" v-if="loading">
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-1"
                        ></v-progress-circular>
                    </v-row>
                    <OpenFeedforward
                        :feedforwardItem="feedforwardItem"
                        :key="feedforwardItem.id"
                        :assignees="assignees"
                        :assignee-required="isAdhoc"
                        :surveyQuestion="question.questionText"
                        v-for="feedforwardItem in openFeedforwards"
                    />

                    <div
                        align="center"
                        class="ma-6 grey--text font-weight-light"
                        v-if="openFeedforwards.length === 0 && !loading"
                    >
                        {{ $t("discussionMode.noFF") }}
                        <br />
                        <v-icon
                            class="display-2 bounce-in"
                            color="grey lighten-1"
                            >mdi-thumb-up-outline</v-icon
                        >
                    </div>

                </v-container>
            </v-card>
            <!-- /OPEN -->

            <!-- CLOSED -->
            <v-card class="mt-5 closedFeedforwards">
                <v-toolbar
                    class="elevation-1 pl-0"
                    color="grey lighten-5"
                    dense
                >
                    <v-avatar class="ml-0" tile height="24" width="26">
                        <img src="../assets/images/discussed.png" />
                    </v-avatar>

                    <v-toolbar-title class>{{
                        $t("discussionMode.discussed")
                    }}</v-toolbar-title>
                </v-toolbar>
                <v-container class="pt-5">
                    <ClosedFeedforward
                        :closedFeedforwardItem="closedFeedforwardItem"
                        :key="closedFeedforwardItem.id"
                        v-for="closedFeedforwardItem in closedFeedforwards"
                    />
                    <div
                        class="ma-3 grey--text"
                        v-if="closedFeedforwards.length === 0"
                    >
                        <i18n
                            path="discussionMode.noDiscussed"
                            class="font-weight-light"
                        >
                            <v-icon class="pb-1" color="green"
                                >mdi-check</v-icon
                            >
                        </i18n>
                    </div>
                </v-container>
            </v-card>
            <!-- /CLOSED-->

            <!-- TASKS -->
            <v-card class="mt-5">
                <v-toolbar
                    class="elevation-1"
                    color="grey lighten-5"
                    dense
                    style="position: relative"
                >
                    <v-icon color="yellow darken-1"
                        >mdi-clipboard-text-outline</v-icon
                    >
                    <v-toolbar-title class="pl-3">
                        {{ $t("discussionMode.tasks") }}
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip
                        bottom
                        color="yellow darken-2"
                        open-delay="800"
                        max-width="300"
                    >
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <v-btn
                                    @click="showTask = !showTask"
                                    small
                                    :ripple="false"
                                    color="grey darken-2"
                                    icon
                                    id="newTaskBtn"
                                >
                                    <v-icon
                                        >{{
                                            showTask ? "mdi-minus" : "mdi-plus"
                                        }}
                                    </v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <span>{{ $t("tooltips.createFreeTask") }}</span>
                    </v-tooltip>
                </v-toolbar>

                <!-- create new task  -->
                <v-expand-transition>
                    <div v-if="showTask" class="mb-2">
                        <CreateTask
                            @panelClosed="showTask = false"
                            :questionId="question.id"
                            :discussionId="discussionId"
                            :assignees="assignees"
                            :assignee-required="isAdhoc"
                            :mode="'extraTask'"
                        />
                        <v-divider></v-divider>
                    </div>
                </v-expand-transition>


                <!-- existing tasks -->
                <v-container class="pt-5">
                    <OpenTask
                        :key="task.id"
                        :task="task"
                        v-for="task in openTasks"
                    />

                    <div class="ma-3 grey--text" v-if="openTasks.length === 0">
                        <i18n
                            path="discussionMode.noTasks"
                            class="font-weight-light"
                        >
                            <v-icon color="yellow darken-1"
                                >mdi-clipboard-plus-outline</v-icon
                            >
                        </i18n>
                    </div>
                </v-container>
            </v-card>
            <!-- /TASKS -->
        </v-container>
    </v-stepper-content>
</template>

<script>
import { getFeedForwards, getTasks } from "../api/discussion.api";
import OpenFeedforward from "@/components/OpenFeedforward.vue";
import ClosedFeedforward from "@/components/ClosedFeedforward.vue";
import OpenTask from "@/components/Taskboard/OpenTask.vue";
import QuestionStats from "@/components/QuestionStats.vue";
import CreateTask from "@/components/CreateTask.vue";
import Histogram from "@/components/Dashboard/Histogram.vue";

export default {
    components: {
        OpenFeedforward,
        ClosedFeedforward,
        Histogram,
        OpenTask,
        QuestionStats,
        CreateTask
    },
    name: "StepperContent",
    props: {
        question: {
            type: Object,
            required: true
        },
        step: {
            type: Number,
            required: true
        },
        nextLabel: {
            type: String,
            required: true
        },
        finished: {
            type: Function,
            required: true
        },
        assignees: {
            type: Array,
            required: true
        },
        isAdhoc: {
            type: Boolean,
            default: false
        }
    },
    provide: function() {
        return {
            reloadTasks: this.loadTasks
        };
    },
    data: () => ({
        feedForwards: null,
        loading: true,
        tasks: null,
        showTask: false,
        showChart: false, //show histogram
        showStickyQuestion: false
    }),
    mounted() {
        getFeedForwards(
            this.$route.params.discussion_id,
            this.question.id
        ).then(response => {
            if (this.showReactions) {
                this.feedForwards = response.data.map(comment => {
                    let obj = { ...comment };

                    if (comment.reactions === null) {
                        obj.reactions = { upvote: [], celebrate: [] };
                    }

                    return obj;
                });
            } else {
                this.feedForwards = response.data;
            }
        });
        this.loadTasks();
    },
    watch: {
        openFeedforwards(newOpen, oldOpen) {
            if (newOpen.length === 0) {
                this.finished(this.step - 1, true);
            } else if (oldOpen.length === 0 && newOpen.length !== 0) {
                this.finished(this.step - 1, false);
            }
        }
    },
    computed: {
        showReactions() {
            return this.$store.getters.checkFeature("REACTIONS_ENABLED");
        },
        discussionId() {
            return this.$route.params.discussion_id;
        },
        openFeedforwards() {
            if (this.feedForwards === null) {
                return [];
            }
            return this.feedForwards.filter(
                ff => ff.status === null || ff.status.toUpperCase() === "OPEN"
            );
        },
        hiddenFeedforwards() {
            if (this.feedForwards === null) {
                return [];
            }
            return this.feedForwards.filter(
                ff => ff.status !== null && ff.status.toUpperCase() === "HIDDEN"
            );
        },
        closedFeedforwards() {
            if (this.feedForwards === null) {
                return [];
            }
            return this.feedForwards.filter(
                ff => ff.status !== null && ff.status.toUpperCase() === "CLOSED"
            );
        },
        openTasks() {
            if (this.tasks === null) {
                return [];
            }
            this.stopLoading();
            return this.tasks.filter(
                task => task.status.toUpperCase() === "OPEN"
            );
        }
    },

    methods: {
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.showStickyQuestion = top > 250;
        },
        stopLoading() {
            this.loading = false;
        },
        loadTasks() {
            getTasks(this.$route.params.discussion_id, this.question.id).then(
                response => (this.tasks = response.data)
            );
        }
    }
};
</script>

<style>
.questionbig {
    font-size: 26px;
}
.question {
    font-size: 18px;
}

.closedFeedforwards .v-toolbar__content {
    padding-left: 5px !important;
}

.stickyQuestion {
    position: fixed !important;
    position: -webkit-fixed !important;
    top: 48px;
    left: 0;
    z-index: 5;
    width: 100vw;
}
</style>
