 const standardIcons =  [
    "balance",
    "book",
    "bulb",
    "cloud",
    "coffee",
    "crab",
    "cup",
    "donut",
    "fridge",
    "hammer",
    "hat",
    "ice",
    "ice_cream",
    "kitty",
    "ladder",
    "laptop",
    "mobile",
    "mouse",
    "mouth",
    "pen",
    "plant",
    "plant2",
    "screwdriver",
    "sun",
    "superman",
    "target",
    "target2",
    "toast",
    "toast2",
    "tree",
    "tree2",
    "tv"
]

const postIcons = [
    "_post/mailbox",
    "_post/phone",
    "_post/kitty",
    "_post/dog",
    "_post/parcel",
    "_post/coffee",
    "_post/balance",
    "_post/stamp",
    "_post/van",
    "_post/helmet",
    "_post/pen",
    "_post/scooter",
    "_post/cup",
    "_post/laptop",
    "_post/envelope",
    "_post/superman"
];

const companyIcons = {
    "3YbECzsMppC27Pmeb": ["_joineer/kitty", "_joineer/book", "_joineer/coffee"],
    "15328txg428123915": [
        "_txgroup/appreciation",
        "_txgroup/coffee",
        "_txgroup/collab",
        "_txgroup/prioritisation",
        "_txgroup/support",
        "_txgroup/team_spirit",
        "_txgroup/thumbs"
    ],
    "91185min134758496": [
        "_txgroup/appreciation",
        "_txgroup/coffee",
        "_txgroup/collab",
        "_txgroup/prioritisation",
        "_txgroup/support",
        "_txgroup/team_spirit",
        "_txgroup/thumbs"
    ],
    "64983brl304366969": ["_brugg/coffee", "_brugg/hammer", "_brugg/hat", "_brugg/pipe"],
    "84318bro741604909": ["_brugg/coffee", "_brugg/hammer", "_brugg/hat", "_brugg/pipe"],
    "56462pip726438287": ["_brugg/coffee", "_brugg/hammer", "_brugg/hat", "_brugg/pipe"],
    "31349bre187255972": ["_brugg/coffee", "_brugg/hammer", "_brugg/hat", "_brugg/pipe"],
    "95696BGP994141134": ["_brugg/coffee", "_brugg/hammer", "_brugg/hat", "_brugg/pipe"],
    "18603srg799469132": [
        "_srg/arrows",
        "_srg/heart",
        "_srg/lab",
        "_srg/mountain",
        "_srg/poster",
        "_srg/puzzle",
        "_srg/team"
    ],
    "63308tip851575972": [
        "_srg/arrows",
        "_srg/heart",
        "_srg/lab",
        "_srg/mountain",
        "_srg/poster",
        "_srg/puzzle",
        "_srg/team"
    ],
    "81812kpt446687047": [
        "_kpt/eigenverantwortung",
        "_kpt/fehler_als_chance",
        "_kpt/grundhaltung",
        "_kpt/offen_kommunizieren",
        "_kpt/rueckmeldung",
        "_kpt/vertrauen",
        "_kpt/vorbildfunktion"
    ],
    "18021ptt784678852": postIcons,
    "17313pof102644794": postIcons,
    "95462pks109762813": postIcons,
    "75925pof181912569": postIcons,
    "47808pos255873195": postIcons,
    "48721PoP165658466": postIcons,
    "77952FLK470061238": postIcons,
    "92333pop839514885": postIcons,
    "77868reg649392509":["_franke/coffee"]
};

const getCompanyIcons = (companyId) => companyIcons.hasOwnProperty(companyId) ? companyIcons[companyId] : false

export { getCompanyIcons, standardIcons }


