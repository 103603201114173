<template>
    <v-tooltip left color="transparent" open-delay="100">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="grey" class="mr-1">
                <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
        </template>

        <v-card :max-width="screenshot ? 700 : 400" elevation="18">
            <v-card-text
                >{{ text }}

                <div class="mt-2" v-if="screenshot">
                    <v-img
                        contain
                        :src="require(`@/assets/images/${screenshot}.jpg`)"
                    ></v-img>
                </div>
            </v-card-text>
        </v-card>
    </v-tooltip>
</template>

<script>
export default {
    props: ["text", "screenshot"]
};
</script>
