import { getInitials } from "@/utils/globalFunctions";


export default class LoginUser {
    username: string;
    password: null;
    constructor(username, password) {
        this.username = username;
        this.password = password;
    }
}

interface UserProps {
    userId: string;
    companyId: string;
    firstName: string;
    lastName: string;
    email: string;
    loginLink: string;
}

export class CompanyUser {
    id: string;
    companyId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    initials: string;
    email: string;
    loginLink: string;


    constructor(user: UserProps) {
        this.id = user.userId;
        this.companyId = user.companyId;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.fullName = user.firstName + " " + user.lastName;
        this.initials = getInitials(user.firstName, user.lastName);
        this.email = user.email;
        this.loginLink = user.loginLink;
    }
}

export class Profile {
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    language: string;
    tags: string[];
    constructor(user) {
        this.email = user.email;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.mobile = user.mobile;
        this.language = user.language;
        this.tags = user.tags;
    }
}

// Profile page
export type UserDB = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    mobile: string | null;
    language: string | null;
    passwordActive: boolean;
    tags: string[];
    roles: Role[];
    teamName: string;
    teamId: string;
    adminOfTeams: string[];
}

//Member list
type memberDB = {
    id: string;
    firstName: string;
    lastName: string;
    displayName: string;
    teamName: string;
    roles: Role[];
    editable: boolean
}

export type userIdName = {
    id: string;
    displayName: string
}

type Authority =
    | "ROLE_STRONG"
    | "ROLE_ROOT"
    | "ROLE_MEMBER"
    | "ROLE_SUPERADMIN"
    | "ROLE_ADMIN";

type Role =
    | "MEMBER"
    | "ADMIN"
    | "SUPERADMIN"
    | "ROOT";

type AuthorityObject = {
    authority: Authority;
};

type LocalStorageUser = {
    password: null;
    username: string;
    authorities: AuthorityObject[];
    accountNonExpired: boolean;
    accountNonLocked: true;
    credentialsNonExpired: boolean;
    enabled: boolean;
    userId: string;
    companyId: string;
    firstName: string;
    lastName: string;
    language: string;
    email: string;
    accessToken: string;
    resumeToken: string;
    hasPassword: boolean;
    gdprAccepted: boolean;
    showIntro: boolean;
    traceLevel: number;
    superAdmin: boolean;
    teamAdmin: boolean;
    isImpersonator: boolean|null;
    impersonatorId: string|null;
};
