<template>
<!-- participants of given survey discussion -->
<v-card flat>
      <h4 class="ml-4">{{ $t('participants') }}
      </h4>
    <v-card-text>


List of team members who took part in this survey   <br><br>

<div v-for="(participant, i) in participants" :key="i" class="d-flex align-center mb-2">
    <v-avatar color="yellow darken-1" class="mr-2" size="34">
        <v-icon color="white">mdi-account</v-icon>

    </v-avatar>
  Firstname{{i+1}}  Lastname{{i+1}}
</div>


    </v-card-text>

</v-card>
</template>

<script>
export default {
    props: [],
    components: {  },
    data: () => ({
        participants: 6
    }),
    methods: {},
    computed: {}
};
</script>
