<template>
    <!-- id="askExpertSection" -->
    <!-- class="askExpertText" -->
    <div v-if="askExpertSettings">
        <div id="askExpertDisplay">
            <span v-html="translatedText(askExpertSettings.title, $i18n.locale)"></span>
            {{ $t("askExpert.askQuestions") }}

            <a
                class="text-decoration-none font-weight-bold"
                @click="showDialog = true"
                >{{ $t("askExpert.yourExpertHere") }}</a
            >
        </div>

        <v-dialog v-model="showDialog" max-width="700" persistent>
            <v-card>
                <v-toolbar dark color="primary darken-2" class="elevation-1 ">
                    <v-row align="center">
                        <div
                            v-html="translatedText(askExpertSettings.title, $i18n.locale)"
                            class="askExpertText ml-3"
                            style="font-size: 1.3rem"
                        ></div>
                        <v-spacer></v-spacer>
                        <v-btn icon text @click="close"
                            ><v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-row>
                </v-toolbar>

                <v-container class="pa-8">
                    <p>
                        <i18n path="askExpert.text">
                            <strong>{{
                               translatedText(askExpertSettings.expertName, $i18n.locale)
                            }}</strong>
                        </i18n>
                    </p>

                    <div v-if="user" class="d-flex">
                        <!-- fields -->
                        <div class="ml-2" style="width: 100%">
                            <div class="mb-2 d-flex ">
                                <input
                                    style="width:60px"
                                    type="text"
                                    disabled
                                    class="squareField mr-2"
                                    :value="$t('askExpert.from')"
                                />
                                <input
                                    style="width:100%"
                                    type="text"
                                    disabled
                                    class="squareField"
                                    :value="
                                        user.firstName +
                                            ' ' +
                                            user.lastName +
                                            ' <' +
                                            user.email +
                                            ' >'
                                    "
                                />
                            </div>

                            <div class="mb-2 d-flex">
                                <input
                                    style="width:60px"
                                    type="text"
                                    disabled
                                    class="squareField mr-2"
                                    :value="$t('askExpert.to')"
                                />

                                <input
                                    style="width:100%"
                                    type="text"
                                    disabled
                                    class="squareField"
                                    :value="
                                        translatedText(askExpertSettings.toName, $i18n.locale)
                                    "
                                />
                            </div>

                            <div class="d-flex">
                                <input
                                    style="width:60px; border: none; "
                                    type="text"
                                    disabled
                                    class="squareField mr-2"
                                    :value="$t('askExpert.subject')"
                                />

                                <input
                                    style="width:100%"
                                    type="text"
                                    disabled
                                    class="squareField"
                                    :value="
                                       translatedText(askExpertSettings.subject, $i18n.locale)
                                    "
                                />
                            </div>
                        </div>
                    </div>

                    <div id="expertMessageField">
                        <v-textarea
                            outlined
                            class="rounded-0 mt-5"
                            v-model="message"
                            auto-grow
                            rows="4"
                            :placeholder="
                                translatedText(askExpertSettings.placeholder, $i18n.locale)
                            "
                        >
                        </v-textarea>
                    </div>

                    <v-btn
                        large
                        depressed
                        :ripple="false"
                        color="primary"
                        :loading="loading"
                        @click="submit"
                        id="sendAskExpertBtn"
                        ><v-icon left>mdi-email-outline</v-icon>
                        {{ $t("buttons.send") }}
                    </v-btn>

                    <v-row justify="center" class="my-3" v-if="loading">
                        <v-progress-circular
                            size="45"
                            indeterminate
                            color="grey lighten-1"
                        >
                            <v-icon class="pulse" color="grey"
                                >mdi-email-outline</v-icon
                            >
                        </v-progress-circular>
                    </v-row>

                    <v-alert
                        tile
                        v-if="!!notificationMessage"
                        outlined
                        :type="notificationType"
                        text
                        >{{ notificationMessage }}</v-alert
                    >
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    getAskExpertSettings,
    sendAskExpertMessage
} from "@/api/askExpert.api";
export default {
    data: () => ({
        showDialog: false,
        message: "",
        notificationMessage: null,
        notificationType: null,
        loading: false,
        askExpertSettings: null
    }),
    created() {
        if (this.$store.getters.checkFeature("ASK_EXPERT")) {
            this.getAskExpertSettings();
        }
    },
    methods: {
        getAskExpertSettings() {
            this.loading = true;
            this.notificationMessage = null;

            getAskExpertSettings()
                .then(res => {
                    this.askExpertSettings = res.data;
                    l("Ask expert settings: ", s(this.askExpertSettings));
                })
                .catch(error => {
                    this.notificationType = "error";
                    this.notificationMessage = this.parseError(error);
                    console.log(error.message);
                })
                .finally(() => (this.loading = false));
        },
        close() {
            this.showDialog = false;
            this.message = "";
            this.notificationMessage = null;
        },
        submit() {
            this.loading = true;
            this.notificationMessage = null;

            sendAskExpertMessage(this.message)
                .then(() => {
                    this.notificationType = "success";
                    this.notificationMessage = this.$t("snackbar.emailSent");
                    this.piwikAnalytics(['trackEvent','Ask Expert', 'Email sent']);

                    setTimeout(() => {
                        this.close();
                    }, 1800);
                })
                .catch(error => {
                    this.notificationType = "error";
                    this.notificationMessage = this.parseError(error);
                })
                .finally(() => (this.loading = false));
        }
    },
    computed: {
        user() {
            return this.$store.state.auth.user;
        }
    }
};
</script>

<style>
#askExpertSection {
    position: absolute;
    bottom: 2%;
    right: 2%;
}

.askExpertText {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 3px;
}
#askExpertBtn {
    border-radius: 10px;
    border: 1.2px solid rgb(121, 119, 119);
    color: white;
    padding: 5px 8px;
    background-color: #ed4c2d;
    font-size: 1.2rem;
    text-transform: uppercase;
    transition: 0.15s all linear;
}

#askExpertBtn:hover {
    background-color: #da4021;
}

.squareBtn {
    border-radius: 0;
    border: 1px solid lightgrey;
    padding: 16px;
    transition: all 0.15s linear;
    background-color: rgb(230, 244, 255);
}

.squareBtn:hover {
    background-color: rgb(232, 243, 253);
}

.squareBtn:hover i,
.squareBtn:hover .grey--text {
    color: rgb(73, 124, 206) !important;
}

.squareField {
    border: 1px solid lightgrey;
    font-size: 0.9rem;
    padding: 3px 8px;
    margin-bottom: 2px;
}

#expertMessageField .v-input__slot fieldset {
    color: lightgrey !important;
}
</style>
