<template>

    <div
        class="d-flex align-center justify-center"
        v-if="topGrade"
        :class="{ 'font-l': $vuetify.breakpoint.smAndUp }"
    >
    <AvgTooltip
                            :stats="statistics"
                            :questionAverage="true"
                        >
                        <template v-slot:trigger>
                            <div  class="stats">
                    <span class="stat-text"
                        >Ø {{ curatedNumber(statistics.average) }}</span
                    >
                    <span
                        v-if="statistics.changeAverage"
                        :class="{
                            'green--text': avgChangeGood,
                            'red--text': !avgChangeGood
                        }"
                    >
                        {{
                            avgChangeGood
                                ? "&UpperRightArrow;"
                                : "&LowerRightArrow;"
                        }}<span>{{ curatedNumber(avgChange) }}%</span></span
                    >
                </div>
                        </template>
                    </AvgTooltip>





        <!-- deviation -->

        <DevTooltip
                            :change="statistics.changeDeviation"
                            :deviation="statistics.deviation"
                            :questionDev="true"
                        >     <template v-slot:trigger>
                            <div  class="stats">
                    <span class="stat-text ml-6"
                        >&LeftRightArrow;
                        {{ curatedNumber(statistics.deviation) }}</span
                    >
                    <span
                        v-if="statistics.changeDeviation"
                        :class="{
                            'green--text': devChangeGood,
                            'red--text': !devChangeGood
                        }"
                    >
                        {{
                            devChangeGood
                                ? "&LowerRightArrow;"
                                : "&UpperRightArrow;"
                        }}<span>{{
                            curatedNumber(statistics.changeDeviation)
                        }}</span></span
                    >
                </div>
                        </template></DevTooltip>

    </div>
</template>

<script>
import ScaleGraph from "@/components/ScaleGraph";
import {
    calculateAvgChange,
    devTextColor,
    avgTextColor
} from "@/utils/dashboardCode.js";
export default {
    name: "",
    props: ["statistics"],
    components: { ScaleGraph },
    data: () => ({
        //    for testing
        // statistics: {
        //     average: 3.8,
        //     deviation: 1.3038404810405297,
        //     changeAverage: -0.13333333333333333,
        //     changeDeviation: -0.3038404810405297
        // }
    }),
    computed: {
        avgChangeGood() {
            return this.statistics.changeAverage >= 0;
        },
        devChangeGood() {
            return this.statistics.changeDeviation <= 0;
        },
        avgChange() {
            return calculateAvgChange(
                this.topGrade,
                this.statistics.changeAverage
            );
        },
        topGrade() {
            return this.$store.state.topGrade;
        }
    },
    methods: {
        curatedNumber(value) {
            let nr = Number(value.toFixed(2));
            return nr < 0 ? nr * -1 : nr;
        },
        avgTextColor(avg) {
            return this.topGrade ? avgTextColor(this.topGrade, avg) : "";
        },
        devTextColor(dev) {
            return this.topGrade ? devTextColor(this.topGrade, dev) : "";
        }
    }
};
</script>

<style>
.stats {
    display: inline-block;
}

.stat-text {
    font-weight: bold;
}
</style>
