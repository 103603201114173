<template>
    <img
        alt="icon"
        :width="width"
        height="auto"
        rel="preload"
        :class="imgClass"
        :src="imgPath"
    />
</template>

<script>
import { ICON_BASE_URL } from "@/utils/globalconsts";
export default {
    props: ["rating", "icon", "width", "imgClass", "fallback"],
    components: {},
    mounted() {
        this.checkImgLink();
    },
    data: () => ({
        imgValid: null,

    }),
    methods: {
        checkImgLink() {
            // detects whether image can be loaded or not
            let img = new Image();
            img.src = `${ICON_BASE_URL}${this.icon}/${this.rating}.png`;

            img.onload = () => (this.imgValid = true);
            img.onerror = () => (this.imgValid = false);
        }
    },
    computed: {
        imgPath() {
            if (this.imgValid) {
                return `${ICON_BASE_URL}${this.icon}/${this.rating}.png`;
            } else {
                if (this.fallback) {
                    return require(`@/assets/images/${this.fallback}/${this.rating}.png`)
                } else {
                    return require("@/assets/images/empty.png");}
            }
        },
    },
    watch: {
        // watch for dynamic variables to reactively show fallback image
        rating() {
            this.checkImgLink();
        },
        icon() {
            this.checkImgLink();
        },
    }
};
</script>
