<template>
    <div>
        <v-row no-gutters>
            <v-col>
                <div class="text-small">
                    {{ comment.author.displayName }}
                    [{{ comment.createdAt | moment("DD/MM/YYYY, kk:mm") }}]:
                </div>

                <p
                    v-if="!edit && !comment.removed"
                    class="comment-format"
                >
                    {{ comment.comment }}
                    <span
                        v-if="comment.updatedAt"
                        class="text-caption grey--text"
                    >
                        ({{ $t("tasks.edited") }})</span
                    >
                </p>

                <div v-if="edit">
                    <v-textarea
                        ref="editCommentField"
                        background-color="#F9F9F9"
                        class="comment-format mb-1"
                        hide-details
                        v-model="commentText"
                        solo
                        dense
                        flat
                    ></v-textarea>
                    <v-btn small @click="editComment" class="mr-2">{{
                        $t("tasks.updateComment")
                    }}</v-btn>
                    <v-btn small text @click="cancelEdit">{{
                        $t("buttons.cancel")
                    }}</v-btn>
                </div>

                <p v-if="comment.removed" class="text-caption mt-1 mb-1 grey--text">
                    ({{
                        $t("tasks.commentDeletedBy", {
                            updatedBy: comment.updatedBy.displayName
                        })
                    }})
                </p>
            </v-col>

            <!-- comment management buttons -->
            <v-col style="max-width: 20px" v-if="!comment.removed && openTask">
                <v-btn
                    v-if="comment.author.id === currentUser.userId"
                    @click="edit = !edit; editError = null"
                    icon
                    x-small
                    :aria-label="$t('tasks.editComment')"
                    ><v-icon color="grey lighten-1">mdi-pencil</v-icon></v-btn
                >
                <v-btn
                    v-if="
                        userRoles.includes('ROLE_ADMIN') ||
                            userRoles.includes('ROLE_SUPERADMIN') ||
                            comment.author.id === currentUser.userId
                    "
                    @click="deleteDialog = true"
                    icon
                    x-small
                    :aria-label="$t('tasks.deleteComment')"
                    ><v-icon color="grey lighten-1"
                        >mdi-trash-can</v-icon
                    ></v-btn
                >
            </v-col>
        </v-row>

        <v-alert v-if="editError" dense outlined type="error" text>{{
            editError
        }}</v-alert>

        <!-- DELETE CONFIRMATION DIALOG -->
        <v-dialog v-model="deleteDialog" max-width="450">
            <v-card>
                <v-card-title
                    class="headline white--text grey darken-2"
                    primary-title
                >
                    <v-icon color="white" class="mr-2">mdi-trash-can</v-icon>
                    {{ $t("tasks.deleteComment") }}
                </v-card-title>

                <v-container class="pa-5">
                    <v-card outlined class="pa-2 pt-0">
                        <div class="text-small">
                            {{ comment.author.displayName }}
                            [{{
                                comment.createdAt | moment("DD/MM/YYYY, kk:mm")
                            }}]:
                        </div>
                        <p class="comment-format">{{ comment.comment }}</p>
                    </v-card>

                    <p class="mt-4 mb-1" style="font-size: 14px">
                        {{ $t("tasks.afterDelete") }}
                    </p>

                    <v-card outlined class="pa-2 pt-0">
                        <div class="text-small">
                            {{ comment.author.displayName }}
                            [{{
                                comment.createdAt | moment("DD/MM/YYYY, kk:mm")
                            }}]:
                        </div>
                        <p class="text-caption mb-0 grey--text">
                            ({{
                                $t("tasks.commentDeletedBy", {
                                    updatedBy:
                                        currentUser.firstName +
                                        " " +
                                        currentUser.lastName
                                })
                            }})
                        </p>
                    </v-card>
                </v-container>

                <v-alert
                    v-if="deleteError"
                    dense
                    outlined
                    type="error"
                    text
                    class="ma-2"
                    ><span style="word-break:break-all">{{
                        deleteError
                    }}</span></v-alert
                >

                <v-card-actions class="pb-4">
                    <v-btn color="grey darken-1" text @click="deleteDialog = false">{{
                        $t("buttons.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn dark color="grey" @click="removeComment">{{
                        $t("buttons.delete")
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { deleteComment, updateComment } from "@/api/tasks.api";

export default {
    props: { taskId: String, comment: Object, openTask: Boolean },
    components: {},
    data: () => ({
        commentText: "",
        edit: false,
        loading: false,
        editError: null,
        deleteError: null,
        deleteDialog: false
    }),
    methods: {
        cancelEdit() {
            this.commentText = this.comment.comment;
            this.edit = false;
            this.editError = null;
        },
        editComment() {
            this.loading = true;
            this.errorMessage = null;
            let newComment = {
                comment: this.commentText
            };
            updateComment(this.taskId, this.comment.id, newComment)
                .then(response => {
                    this.piwikAnalytics(['trackEvent', 'Task actions', 'Comment edited'])
                    this.comment.comment = response.data.comment;
                    this.comment.removed = response.data.removed;
                    this.comment.updatedAt = response.data.updatedAt;
                    this.comment.updatedBy = response.data.updatedBy;
                    this.edit = false;
                })
                .catch(error => {
                    this.editError = this.parseError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        removeComment() {
            this.loading = true;
            this.deleteError = null;
            deleteComment(this.taskId, this.comment.id)
                .then(response => {
                    this.piwikAnalytics(['trackEvent', 'Task actions', 'Comment deleted'])
                    this.comment.comment = response.data.comment;
                    this.comment.removed = response.data.removed;
                    this.comment.updatedAt = response.data.updatedAt;
                    this.comment.updatedBy = response.data.updatedBy;
                    this.deleteDialog = false;
                })
                .catch(error => {
                    this.deleteError = this.parseError(error);
                })
                .finally(() => (this.loading = false));
        }
    },
    mounted() {
        this.commentText = this.comment.comment;
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        userRoles() {
            return this.$store.state.auth.user.authorities.map(
                item => item.authority
            );
        }
    },
    watch: {
        edit(val) {
            if (val) {
                this.$nextTick(() => this.$refs.editCommentField.focus());
            }
        },
        deleteDialog(val) {
            if (!val) {
                this.deleteError = null;
            }
        }
    }
};
</script>
