<template>
    <!-- Card showing discussion stats -->

    <v-card outlined class="mb-1 pa-3">
  <!--       <div>
            <span style="font-weight: bold">: </span>{{ stats.statistic }}
        </div> -->

        <v-container class="pb-0">
            <v-row justify="center">
                <v-col class="text-center px-6"
                    ><h4 class="mb-2">Feedforwards</h4>

                    <div >
                        <div class="pos-relative bounce-in">
                            <v-icon
                                style="font-size: 130px;"
                                color="grey lighten-3"
                                >mdi-comment</v-icon
                            >
                            <div
                                class="radialLabel pos-absolute"
                                style=" left: 50%; transform: translate(-50%, 0); top: 25%"
                            >
                                {{ stats.totalFeedForwards }}

                                <div
                                    class="text-caption mt-4" style="line-height: 0.8rem"
                                    v-if="feedForwardsPerMember"

                                >
                                    {{ feedForwardsPerMember }}
                                    {{ $t("dashboard.perMember") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col class="text-center px-6">
                    <h4 class="mb-4">{{ $t("dashboard.participation") }}</h4>

                    <v-progress-circular
                        v-if="participation"
                        color="primary"
                        class="bounce-in"
                        :value="participation"
                        size="130"
                        width="13"
                        :rotate="-90"
                    >
                        <div class="centered">
                            <div class="radialLabel">{{ participation }}%</div>
                            <div
                                v-if="participation && participation != 0"
                                class="text-caption grey--text text--darken-2 mt-3"
                            >
                                {{ stats.respondents }}/{{ stats.participants }}
                                <v-icon small color="grey darken-2"
                                    >mdi-account</v-icon
                                >
                            </div>
                        </div>
                    </v-progress-circular>

                    <h1 v-else class="grey--text text--lighten-1">
                        ?
                    </h1>
                </v-col>

                <v-col class="text-center px-6">
                    <h4 class="mb-4">
                        {{ $t("dashboard.average") }}
                        <AvgTooltip
                            :stats="stats.statistic"
                            :generalAverage="true"
                        >
                        <template v-slot:trigger>
                            <v-icon

                color="grey lighten-1"
                class="cursor-pointer d-print-none"
                >mdi-help-circle-outline</v-icon
            >
                        </template>
                    </AvgTooltip>
                    </h4>
                    <h1 v-if="invalidStats" class="grey--text text--lighten-1">
                        ?
                    </h1>

                    <RoundChart
                        v-else
                        :size="130"
                        :width="13"
                        :valid="stats.valid"
                        :statistics="stats.statistic"
                        :topGrade="topGrade"
                        :addOnChar="'%'"
                        :compareMode="false"
                        :minView="true"
                    />
                </v-col>
                <v-col class="text-center px-6"
                    ><h4 class="mb-4">
                        {{ $t("dashboard.deviation") }}
                       <DevTooltip
                            :change="stats.statistic.changeDeviation"
                            :deviation="stats.statistic.deviation"
                            :generalDev="true"
                        >     <template v-slot:trigger>
                            <v-icon

                color="grey lighten-1"
                class="cursor-pointer d-print-none"
                >mdi-help-circle-outline</v-icon
            >
                        </template></DevTooltip>
                    </h4>
                    <h1 v-if="invalidStats" class="grey--text text--lighten-1">
                        ?
                    </h1>

                    <DeviationNr
                        v-else
                        :valid="stats.statistic.valid"
                        class="mx-auto"
                        :topGrade="topGrade"
                        :deviation="stats.statistic.deviation"
                        :change="stats.statistic.changeDeviation"
                        :id="discussionId"
                        :compareStat="false"
                        :minView="true"
                    />

                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="showParticipantsDialog" max-width="500">
            <v-card>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showParticipantsDialog = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </div>
                <Participants :discussionId="discussionId" />
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import DeviationNr from "@/components/Dashboard/DeviationNr.vue";
import RoundChart from "@/components/Dashboard/RoundChart.vue";
import Participants from "@/components/SelectSurvey/Participants.vue";
import {
    roundedPercentage,
    roundUp,
    roundUpOnlyInteger,
    avgTextColor,
    devTextColor
} from "@/utils/dashboardCode.js";
import { getDiscussion } from "@/api/discussion.api";
import ScaleGraph from "@/components/ScaleGraph";
export default {
    props: ["discussionId", "stats"],
    components: { Participants, ScaleGraph, RoundChart, DeviationNr },
    data: () => ({
        feedForwardsPerMember: null,
        participation: null,
        discussionData: null,
        showParticipantsDialog: false
    }),
    mounted() {
        this.setStatistics();
    },
    methods: {
        setStatistics() {
            getDiscussion(this.discussionId).then(response => {
                this.discussionData = response.data;
                this.feedForwardsPerMember = roundUpOnlyInteger(
                    this.stats.totalFeedForwards / this.stats.respondents
                );

                this.participation = roundedPercentage(
                    this.stats.participants,
                    this.stats.respondents
                );
            });
        },
        roundUp(val) {
            return roundUp(val);
        },
        avgTextColor(avg) {
            return avgTextColor(this.topGrade, avg);
        },
        devTextColor(dev) {
            return devTextColor(this.topGrade, dev);
        }
    },
    computed: {
        topGrade() {
            return this.$store.state.topGrade;
        },
        invalidStats() {
            if (this.stats) {
                return this.stats.statistic === null;
            } else {
                return null;
            }
        }
    },
    watch: {
        discussionId() {
            this.setStatistics();
        }
    }
};
</script>

<style></style>
