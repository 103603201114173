<template>
    <div class="py-12">
        <v-progress-circular
            indeterminate
            color="grey lighten-2"
            class="d-flex mx-auto"
        ></v-progress-circular>
    </div>
</template>

<script>
export default {
    props: [""],
    components: {  },
    data: () => ({
    }),
    methods: {},
    computed: {}
};
</script>
