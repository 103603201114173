<template>
    <v-avatar
        :size="size ? size : 50"
        :color="!imageFound ? 'teal' : 'transparent'"
    >
        <img v-if="imageFound" :src="imgUrl" @error="handleError" :alt="alt" />
        <!-- alternative fallback
        <img :src="imgUrl" @error="$event.target.src ='https://tbpics.joineer.com/catalogicons/nodata.png'"> -->

        <slot v-else name="fallback"></slot>
    </v-avatar>
</template>

<script>
export default {
    props: ["imgUrl", "alt", "size"],
    components: {},
    data: () => ({
        imageFound: true
    }),
    methods: {
        handleError() {
        /*     console.log("Category icon url " + this.imgUrl + " not valid, using fallback") */
            this.imageFound = false;
        }
    },
    computed: {}
};
</script>
