import { httpClient, cachedHttpClient, generateCacheId } from "./httpClient";

const settings = (companyId, userId) => {
    window.l('before settings call');

    if (companyId && userId) {
        return cachedHttpClient.get("/settings", { cache: { ttl: 200, }, generateKey: generateCacheId(companyId, userId)});
    } else {
        return httpClient.get("/settings");
    }
};

// retrieves userLanguage, feature flags etc
// fallback to languages[0]
const changeUserLanguage = (lang) => httpClient.put(`user/language/${lang}`);

const introShown = () => httpClient.put("user/introshown");

const acceptGDPR = () => httpClient.put("user/gdpraccepted");

export { settings, changeUserLanguage, introShown, acceptGDPR }


