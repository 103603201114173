<template>
  <v-img
   class="mt-1"
    v-bind="$attrs"
    style="display: inline-block; max-width: 25px"
    :src="require(`@/assets/images/flags/${locale}.png`)"
    contain
    width="25"
  ></v-img>
</template>

<script>
export default {
  props: ["locale"],
  computed: {},
};
</script>
