//checks if we're dealing with a valid number
const isValidNr = function(val) {
    return val !== null && val !== undefined && !isNaN(val);
};

// % of one number from total. rounded to full integer if possible / 50.00 --> 50
const roundedPercentage = function(total, part) {
    if (isValidNr(total) && isValidNr(part)) {
        let answer = (part / total) * 100;
        return Number(answer).toFixed(0);
    } else {
        return null;
    }
};
//always rounds up to 2 comma places
const roundUp = val => (isValidNr(val) ? Number(val).toFixed(2) : null);

// rounds up to 2 comma places, but removes comma places only if 0.00
const roundUpOnlyInteger = function(val) {
    if (isValidNr(val)) {
        if (Number.isInteger(val)) {
            return Number(val).toFixed(0);
        }
        return Number(val).toFixed(2);
    } else {
        return null;
    }
};

// returns the avg chart color depending on how "healthy" the average grade is
// NB: if you change this,also change avgTextColor
const avgChartColor = function(topGrade, average) {
    if (isValidNr(average)) {
        if (topGrade === 6) {
            if (average >= 5) {
                return "green";
            } else if (average >= 4) {
                return "yellow darken-1";
            } else if (average >= 3) {
                return "orange";
            } else {
                return "red";
            }
        } else if (topGrade === 10) {
            if (average >= 9) {
                return "green";
            } else if (average >= 8) {
                return "yellow darken-1";
            } else if (average >= 5) {
                return "orange";
            } else {
                return "red";
            }
        }
    } else {
        return "grey";
    }
};

// returns the text color class depending on how "healthy" the average grade is
// NB: if you change this,also change avgChartColor
const avgTextColor = function(topGrade, average) {
    if (isValidNr(average)) {
        if (topGrade === 6) {
            if (average >= 5) {
                return "green--text";
            } else if (average >= 4) {
                return "yellow--text text--darken-2";
            } else if (average >= 3) {
                return "orange--text";
            } else {
                return "red--text";
            }
        } else if (topGrade === 10) {
            if (average >= 9) {
                return "green--text";
            } else if (average >= 8) {
                return "yellow--text text--darken-2";
            } else if (average >= 5) {
                return "orange--text";
            } else {
                return "red--text";
            }
        }
    } else {
        return "grey--text text--darken-1";
    }
};

//returns the text color class depending on how "healthy" the average grade is
//follows same logic as components/ScaleGraph.vue
const devTextColor = function(topGrade, deviation) {
    if (isValidNr(deviation)) {
        if (topGrade === 6) {
            if (deviation > 1.25) {
                return "red--text";
            } else if (deviation > 0.65) {
                return "orange--text";
            } else {
                return "green--text";
            }
        } else if (topGrade === 10) {
            if (deviation > 3.5) {
                return "red--text";
            } else if (deviation > 1.5) {
                return "orange--text";
            } else {
                return "green--text";
            }
        }
    } else {
        return "grey--text text--darken-1";
    }
};

// increased deviation (differences) = bad, decreased deviation good
const devChangeColor = function(num) {
    if (!isValidNr(num) || num === 0) {
        return "grey";
    } else if (num > 0) {
        return "red";
    } else {
        return "green";
    }
};

const avgChangeColor = function(num) {
    if (!isValidNr(num) || num === 0) {
        return "grey";
    } else if (num > 0) {
        return "green";
    } else {
        return "red";
    }
};

const calculateAvgChange = function(topGrade, avgChange) {
    let curatedChange = avgChange;
    //add this when we need to match TB calculation logic with intermediate rounding
    //curatedChange = Math.floor(curatedChange * 100) / 100;

    curatedChange = (curatedChange * 100) / topGrade; //percentage change compared to last result

    return Number(curatedChange.toFixed(2)); //round to 2 decimal points
};

const calculateHandleRate = function(total, closed, tasked) {
    if (total === 0) {
        return 0;
    } else {
        let handled = closed + tasked;
        let handleRate = (handled / total) * 100;
        // round up to 2 decimal places if not full integer
        if (handleRate % 1 != 0) {
            handleRate = handleRate.toFixed(2);
        }

        return handleRate;
    }
};

export {
    roundedPercentage,
    roundUpOnlyInteger,
    isValidNr,
    roundUp,
    avgChartColor,
    avgTextColor,
    devTextColor,
    calculateAvgChange,
    devChangeColor,
    avgChangeColor,
    calculateHandleRate
};
