<template>
    <nav class="d-print-none">
        <v-navigation-drawer
            v-model="drawer"
            style="height: 100%"
            clipped
            color="grey lighten-4"
            app
        >
            <div class="d-flex align-center pa-3" v-if="user">
                <v-avatar class="mr-2" color="amber" size="30">
                                        <span
                                            class="white--text"
                                            style="font-size: 1rem"
                                        >
                                            {{ initials(user) }}
                                        </span>
                </v-avatar>
                <div class="text-caption ml-2">
                    <div>{{ $t("greeting") }}, {{ user.firstName }} {{ user.lastName.replace(/ /g, '&nbsp;') }}</div>
                    <div class="grey--text">
                        {{ renderedRoles }}
                    </div>
                </div>
            </div>

            <v-divider></v-divider>
            <v-list
                id="navList"
                v-if="flags && availableMenuItems"
                dense
                class="pt-0"
            >
                <div v-for="item in availableMenuItems" :key="item.title">
                    <v-divider
                        v-if="
                            item.hasOwnProperty('upperSeparator') &&
                            item.upperSeparator
                        "
                    ></v-divider>
                    <v-list-item
                        :to="item.link"
                        class=""
                        :ripple="false"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                            >{{
                                item.title.includes("nav.")
                                    ? $t(item.title)
                                    : item.title
                            }}<span class="newLabel" v-if="item.beta">BETA</span
                            ><span class="newLabel" v-if="item.new"
                                >{{ $t("buttons.new") }}
                            </span></v-list-item-title
                        >
                    </v-list-item>
                    <v-divider v-if="item.separator"></v-divider>
                </div>

                <v-list-group
                    v-if="isCompanyAdmin"
                    v-model="adminMenuExpanded"
                    prepend-icon="mdi-cog"
                    :ripple="false"
                >
                    <template v-slot:activator>
                        <v-list-item-title>Administration</v-list-item-title>
                    </template>
                    <v-list-item v-if="isTemplateEditorEnabled" :to="getEmailTemplatesLink" :ripple="false">
                        <v-list-item-icon class="ml-4">
                            <v-icon>mdi-email-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{
                            $t("nav.emailTemplates")
                        }}</v-list-item-title><span class="newLabel">BETA</span>
                    </v-list-item>
                    <v-list-item to="/calendar" :ripple="false">
                        <v-list-item-icon class="ml-4">
                            <v-icon>event</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{
                            $t("nav.calendar")
                        }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/qr" :ripple="false">
                        <v-list-item-icon class="ml-4">
                            <v-icon>mdi-qrcode</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                            >{{ $t("nav.qrCodes") }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="!flags?.includes('MASTERFILE_DISABLED')" to="/masterfile" :ripple="false">
                        <v-list-item-icon class="ml-4">
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Masterfile</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/processdatafile" :ripple="false">
                        <v-list-item-icon class="ml-4">
                            <v-icon>mdi-chart-line</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t("processdatafile") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/companyadmins" :ripple="false">
                        <v-list-item-icon class="ml-4">
                            <v-icon>mdi-account-cog</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                            >{{ $t("nav.companyAdmins") }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-img
                class="mt-5"
                contain
                src="@/assets/LogoJoineer.png"
                height="30px"
            >
            </v-img>


            <div class="versionLabel">
                {{ version }}
            </div>
        </v-navigation-drawer>

        <v-app-bar
            app
            dark
            dense
            clipped-left
            color="primary"
            class="d-print-none"
        >
            <v-app-bar-nav-icon fab @click="toggleDrawer"></v-app-bar-nav-icon>
            <v-toolbar-title
                class="pl-3"
                style="font-size: 20px"
                v-if="$vuetify.breakpoint.smAndUp"
                >{{ pageTitle }}
                <span v-if="addOnEnabled">| Teambarometer</span>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on">
                        <span style="font-size: 14px"
                            >{{ $i18n.locale.toUpperCase() }}
                        </span>
                        <v-icon class="ml-1">language</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        :class="{ 'active-lang': $i18n.locale == lang }"
                        @click="
                            $i18n.locale = lang;
                            setDateLocale();
                        "
                        v-for="lang in languageSelectMenu"
                        :key="lang"
                    >
                        <v-list-item-title>{{
                            lang.toUpperCase()
                        }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn icon @click="logOut" :title="$t('nav.logout')">
                <v-icon>input</v-icon>
            </v-btn>
        </v-app-bar>
    </nav>
</template>

<script>
import { changeUserLanguage } from "@/api/settings.api";
import Configuration from "@/services/configuration";
import { auth } from "@/store/auth.module";
import { VERSION } from "@/utils/globalconsts.js";
import { setAvailableMenuItems } from "@/utils/navigationMenu.js";

export default {
    name: "Navbar",
    data: () => ({
        availableMenuItems: null,
        version: "",
        adminMenuExpanded: false,
        adminRoutes: ["QR", "calendar", "masterfile", "companyadmins"],
    }),
    components: {},
    mounted() {
        this.setMenuItems();
        this.version = VERSION;
        if (this.adminRoutes.includes(this.$route.name)) {
            this.adminMenuExpanded = true;
        }
    },
    computed: {
        showReactions() {
            return this.$store.getters.checkFeature("REACTIONS_ENABLED");
        },
        flags() {
            return this.$store.getters.featureFlags;
        },
        getEmailTemplatesLink() {
            return `/email-templates/${this.$store.state.auth.user.companyId}`;
        },
        isTemplateEditorEnabled() {
            return  this.$store.getters.checkFeature("TEMPLATE_EDITOR_ENABLED");
        },
        languageSelectMenu() {
            return this.$store.state.supportedLangs;
        },
        pageTitle() {
            return this.user ? this.$store.state.userSettings?.companyName : this.$t(this.$route.meta.title);
        },
        userRoles() {
            return this.$store.getters["auth/userRoles"];
        },
        renderedRoles() {
            if (this.userRoles && this.userRoles.length > 0) {
                let role;
                //display user friendly roles
                if (this.userRoles.includes("ROLE_SUPERADMIN")) {
                    role = this.$t("roles.superadmin");
                } else if (this.userRoles.includes("ROLE_ADMIN")) {
                    role = "Admin";
                } else if (this.userRoles.includes("ROLE_MEMBER")) {
                    role = this.$t("roles.member");
                }

                if (this.userRoles.includes("ROLE_ROOT")) {
                    role = role.concat(", Root");
                }
                return role;
            } else {
                return "";
            }
        },
        user() {
            return auth.state.user;
        },

        drawer: {
            // whether left side navigation menu is visible)
            get() {
                return this.$store.state.navDrawer;
            },
            set(newVal) {
                this.setDrawer(newVal);
            },
        },
        addOnEnabled() {
            return (
                this.$route.name !== "adhoc-create" &&
                this.$route.name !== "adhoc-dashboard"
            );
        },
        isCompanyAdmin() {
            return this.$store.getters["auth/isCompanyAdmin"];
        },
    },
    watch: {
        flags: {
            // watch user feature flags and set menu items when they change
            deep: true,
            handler: "setMenuItems",
        },
        "$route.name"(val) {
            if (this.adminRoutes.includes(val)) {
                this.adminMenuExpanded = true;
            }
        },
    },
    methods: {
        changeLang(lang) {
            changeUserLanguage(lang).then(() => {
                this.piwikAnalytics([
                    "trackEvent",
                    "User language changed",
                    this.$i18n.locale,
                ]);
                this.$store.dispatch("changeUserLang", lang);

                if (this.$devMode) {
                    console.log("user language changed to " + lang);
                }
            });
        },
        setMenuItems() {
            let items = setAvailableMenuItems(
                this.flags,
                this.userRoles
            );

            if (this.$devMode) {

                this.availableMenuItems = items;

            } else {

                this.availableMenuItems = items.filter(item => {
                    return !item.title.includes("nav.ffanalysis");
                });
            }

        },
        logOut() {
            window.l('in logOut');
            this.$store.dispatch("auth/logout");
        },
        setDrawer(val) {
            this.$store.commit("SET_NAV", val);
        },
        toggleDrawer() {
            this.$store.commit("TOGGLE_NAV");
        },
        setDateLocale() {
            this.$moment.locale(this.$i18n.locale);
            document.documentElement.setAttribute("lang", this.$i18n.locale);
            this.changeLang(this.$i18n.locale);
        },
    },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
    width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(185, 185, 185);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(151, 151, 151);
}

#navList .v-list-item__title {
    line-height: 1.2rem;
}

.active-lang {
    background: rgb(222, 238, 252) !important;
}

.versionLabel {
    font-size: 0.8rem;
    color: rgb(157, 157, 157);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
</style>
