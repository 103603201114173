<template>
    <v-dialog v-model="dialogState" max-width="400">
        <v-card>
            <v-toolbar flat color="grey lighten-5">
                <v-toolbar-title>{{ $t("updateName") }} </v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn @click="$emit('dialog', false)" icon
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text>
                <v-form ref="updateNameForm" class="my-4">
                    <v-text-field
                        v-model="newFirstName"
                        :rules="nameRules"
                        :label="$t('firstName')"
                    >
                    </v-text-field>

                    <v-text-field
                        v-model="newLastName"
                        :rules="nameRules"
                        :label="$t('lastName')"
                    >
                    </v-text-field>
                </v-form>

                <v-alert v-if="error" type="error" outlined text>
                    {{ error }}
                </v-alert>
                <v-btn
                    :disabled="loading"
                    large
                    @click="updateName"
                    color="primary"
                    >{{ $t("buttons.save") }}
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { updateMyDetails } from "@/api/users.api";
import { updateUserDetails } from "@/api/users.api";
import { nameRules } from "@/utils/formHelpers";

export default {
    props: ["user", "dialog", "isMyProfile"],
    data: () => ({
        newFirstName: null,
        newLastName: null,
        loading: false,
        error: null,
        nameRules: [],
    }),
    mounted() {
        this.setFields();
    },
    methods: {
        updateName() {
            this.piwikAnalytics(['trackEvent','User management actions', 'Updated name via Profile']);
            this.error = null;
            this.nameRules = nameRules;
            this.$nextTick(() => {
                if (this.$refs.updateNameForm.validate()) {
                    this.loading = true;
                    let updatedUser = { ...this.user };
                    updatedUser.firstName = this.newFirstName;
                    updatedUser.lastName = this.newLastName;

                    if (this.isMyProfile) {
                        updateMyDetails(updatedUser)
                            .then(() => {
                                //updates the name in the left side menu visuals
                                this.$store.dispatch("showSnack", [true,"detailsUpdated"]);
                                this.$store.commit("auth/updateName", updatedUser)
                                this.$emit("dialog", false);
                                this.$emit("refresh");
                            })
                            .catch((error) => {
                                this.error = this.parseError(error);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    } else {
                        updateUserDetails(this.user.id, updatedUser)
                            .then(() => {
                                this.$store.dispatch("showSnack", [true,"detailsUpdated"]);
                                this.$emit("dialog", false);
                                this.$emit("refresh");
                            })
                            .catch((error) => {
                                this.error = this.parseError(error);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                }
            });
        },
        setFields() {
            this.nameRules = [];
            this.error = null;
            this.newFirstName = this.user.firstName;
            this.newLastName = this.user.lastName;
        },
    },
    computed: {
        dialogState: {
            get() {
                return this.dialog;
            },
            set(val) {
                this.$emit("dialog", val);
            },
        },
    },
    watch: {
        dialog(val) {
            if (val) {
                this.setFields();
            }
        },
    },
};
</script>

<style scoped>
.langFlag {
    margin-right: 6px;
    margin-top: -2px;
}
</style>
