<template>
    <div class="ff-container">
        <v-card
            :class="[
                {
                    'grey lighten-4': feedforwardItem.status !== null && feedforwardItem.status.toUpperCase() === 'HIDDEN'
                }
            ]"
            class="quick-fade-in ff-card"
        >
            <v-container class="pos-relative">
                       <VotingBar
                       v-if="showReactions"
                        :userId="userId"
                        :feedforwardId="feedforwardItem.id"
                        :reactions="feedforwardItem.reactions"
                        @addUpvote="feedforwardItem.reactions.upvote.push(userId)"
                        @removeUpvote="
                            feedforwardItem.reactions.upvote = feedforwardItem.reactions.upvote.filter(
                                id => id !== userId
                            )
                        "
                        @addCelebrate="feedforwardItem.reactions.celebrate.push(userId)"
                        @removeCelebrate="
                            feedforwardItem.reactions.celebrate = feedforwardItem.reactions.celebrate.filter(
                                id => id !== userId
                            )
                        "
                    />

                <v-row dense align="center" no-gutters>
                    <v-col class="pl-3">
                        <p
                            class="text-small font-normal"
                        >
                            {{
                                feedforwardItem.author
                                    ? feedforwardItem.author.displayName
                                    : `${$t("discussionMode.anonym")}`
                            }}:
                        </p>
                        <p class="mb-1 mt-0">
                            {{ feedforwardItem.answer }}
                        </p>
                    </v-col>
                </v-row>
            </v-container>
            <v-expand-transition>
                <div v-if="showTask">
                    <CreateTask
                        @panelClosed="togglePanel"
                        :discussionId="discussionId"
                        :feedforwardItem="feedforwardItem"
                        :surveyQuestion="surveyQuestion"
                        :assignees="assignees"
                        :mode="'ffTask'"
                        :assignee-required="assigneeRequired"
                        @FFTasked="feedforwardItem.status = $event"
                    />
                </div>
            </v-expand-transition>
        </v-card>

        <!-- buttons -->
        <div
            class="mt-1"
            style="z-index: 1;"
            v-if="feedforwardItem.status === null ||  feedforwardItem.status.toUpperCase() !== 'HIDDEN'"
        >
            <v-tooltip bottom color="green" open-delay="800">
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <v-btn
                            class="ff-btn closeFeedforwardBtn"
                            :aria-label="$t('tooltips.discussed')"
                            @click="closeFF"
                            block
                            small
                            v-ripple="{ class: 'success--text' }"
                        >
                            <v-icon
                                color="green"
                                :left="$vuetify.breakpoint.smAndUp"
                                >mdi-check</v-icon
                            >
                            <span v-if="$vuetify.breakpoint.smAndUp">{{
                                $t("buttons.markDone")
                            }}</span>
                        </v-btn>
                    </div>
                </template>
                <span>{{$t("tooltips.discussed")}}
                </span>
            </v-tooltip>

            <v-tooltip bottom color="yellow darken-2" open-delay="800">
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <v-btn
                            class="ff-btn openCreateTaskBtn"
                            :disabled="showTask"
                            block
                            small
                            @click="showTask = !showTask"
                            :aria-label="$t('tooltips.createTask')"
                            v-ripple="{ class: 'yellow--text' }"
                        >
                            <v-icon
                                color="yellow darken-1"
                                :left="$vuetify.breakpoint.smAndUp"
                                >mdi-clipboard-plus-outline</v-icon
                            ><span v-if="$vuetify.breakpoint.smAndUp">{{
                                $t("buttons.newTask")
                            }}</span>
                        </v-btn>
                    </div>
                </template>
                <span>{{ $t("tooltips.createTask") }}</span>
            </v-tooltip>
        </div>
        <!-- buttons -->
    </div>
</template>

<script>

import {
    closeFeedForward,
    hideFeedForward,
    openFeedForward
} from "../api/feedforward.api";
export default {
    name: "OpenFeedforward",
    components: {
        CreateTask: () => import("@/components/CreateTask"),  VotingBar: () => import("@/components/Voting/VotingBar.vue"),
    },
    props: {
      feedforwardItem: {
            type: Object,
            required: true
        },
        surveyQuestion: {
            type: Object,
            required: true
      },
      assignees: {
            type: Array,
            required: true
      },
      assigneeRequired: {
            type: Boolean,
            default: false
      },
    },
    data: () => ({
        showTask: false
    }),
    computed: {

        discussionId() {
            return this.$route.params.discussion_id;
        },
         showReactions() {
            return this.$store.getters.checkFeature("REACTIONS_ENABLED");
        },
            userId() {
            return this.$store.state.auth.user.userId;
        }
    },
    methods: {
        togglePanel() {
            this.showTask = false;
        },
        closeFF() {
            closeFeedForward(this.feedforwardItem.id);
            this.piwikAnalytics(['trackEvent','Discussion actions', 'Feedforward closed']);
            this.feedforwardItem.status = "CLOSED";
            this.$store.dispatch("showSnack", [true, "ffClosed"]); //notification for user

        },
        hideFF() {
            hideFeedForward(this.feedforwardItem.id);
            this.feedforwardItem.status = "HIDDEN";
        },
        openFF() {
            openFeedForward(this.feedforwardItem.id);
            this.feedforwardItem.status = "OPEN";
        }
    }
};
</script>
