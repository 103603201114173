import VTiptap from "@peepi/vuetify-tiptap";
import moment from 'moment';
import Vue from 'vue';
import vueDebounce from 'vue2-debounce'; //Vue 3 <- use vue-debounce
import vueMoment from 'vue-moment';
import VueYoutube from 'vue-youtube';

import window from './globals';
import '@/components/global/register'; // globally register reusables

import App from './App.vue';
import i18n  from './i18n'; //set up translations
import vuetify from './plugins/vuetify';
import Configuration from "./services/configuration";
import {store} from "./store/store"; //import Vuex
import router from './router';
import globalFunctions from './utils/globalFunctions';

require('@/styles/main.css'); //styling


Vue.prototype.$devMode = window.$devMode;
Vue.prototype.$localMode = window.$localMode;
Vue.prototype.l = function(...args){ return window.l(...args);};
Vue.prototype.le = function(...args){ return window.le(...args);};
Vue.prototype.lj = function(...args){ return window.lj(...args);};
Vue.prototype.lw = function(...args){ return window.lw(...args);};

Vue.use(vueDebounce, {
  lock: false,
  listenTo: ['keyup', 'change', 'input'],
  defaultTime: '300ms',
  fireOnEmpty: false,
  cancelOnEmpty: false,
  trim: false,
});
Vue.use(VTiptap);
Vue.use(vueMoment, { moment });
Vue.use(VueYoutube);

Vue.mixin(globalFunctions);

Vue.config.productionTip = false;
Vue.config.performance = false;

window.l('i18n', i18n);

window.jvm = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App as any)
});

// enable for translation debugging
if (false) {
  const ___t = window.jvm.$i18n._t.bind(window.jvm.$i18n);
  window.jvm.$i18n._t = (...args) => {
    //window.l('[_t]', ...args);
    return ___t(...args);
  };
}

if (false) {
  const ___te = window.jvm.$i18n._te.bind(window.jvm.$i18n);
  window.jvm.$i18n._te = (...args) => {
    window.l('[_te]', ...args);
    return ___te(...args);
  };
}

window.jvm.$mount('#app');

