<template>
    <v-dialog v-model="dialogState" max-width="400">
        <v-card>
            <v-toolbar flat color="grey lighten-5">
                <v-toolbar-title>{{ $t("updateEmail") }} </v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn @click="$emit('dialog', false)" icon
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text>
                <v-form ref="updateEmailForm" class="my-4">
                    <v-text-field
                        :rules="emailRules"
                        placeholder=" "
                        label="E-mail"
                        name="email"
                        v-model="newEmail"
                        prepend-icon="email"
                        type="text"
                        autocomplete="off"
                        @keydown.enter.prevent="updateEmail"
                        clearable
                    ></v-text-field>
                </v-form>
                <v-alert v-if="error" type="error" outlined text>
                    <div>
                        <strong> {{ $t("errorEmail") }}</strong>
                    </div>
                    <div class="mt-2">
                        {{ error }}
                    </div>
                </v-alert>
                <v-btn
                    :disabled="loading"
                    large
                    @click="updateEmail"
                    color="primary"
                    >{{ $t("buttons.save") }}
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { emailRules, required } from "@/utils/formHelpers";
import { updateUserDetails } from "@/api/users.api";
import { updateMyDetails } from "@/api/users.api";

export default {
    props: ["user", "dialog", "isMyProfile"],
    data: () => ({
        newEmail: null,
        loading: false,
        error: null,
        emailRules: [],
    }),
    mounted() {
        this.setFields();
    },
    methods: {
        updateEmail() {
            this.error = null;
            this.piwikAnalytics(['trackEvent','User management actions', 'Updated email via Profile']);

            if (!this.newEmail && !this.user.mobile) {
                l("no email or phone both!");
                this.emailRules = required;
                this.error = this.$t("noDeleteEmailError");
                return;
            }


            if(this.user.mobile && !this.newEmail){
                //allow deleting email if user has a mobile
                this.emailRules = []
            }else{
                //standard email validation
                this.emailRules = emailRules;
            }

            this.$nextTick(() => {
                if (this.$refs.updateEmailForm.validate()) {
                    this.loading = true;
                    let updatedUser = { ...this.user };
                    updatedUser.email = this.newEmail ? this.newEmail : null;

                    if (this.isMyProfile) {
                        if(this.$devMode){
                            l("Updating my email");
                        }

                        updateMyDetails(updatedUser)
                            .then((resp) => {
                                this.$store.commit("auth/updateEmail", resp?.data?.email)
                                this.$store.dispatch("showSnack", [true,"detailsUpdated"]);
                                this.$emit("dialog", false);
                                this.$emit("refresh");

                            })
                            .catch((error) => {
                                this.error = this.parseError(error);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    } else {
                        if(this.$devMode){
                            l("Updating user email")
                        }
                        updateUserDetails(this.user.id, updatedUser)
                            .then(() => {
                                this.$store.dispatch("showSnack", [true,"detailsUpdated"]);
                                this.$emit("dialog", false);
                                this.$emit("refresh");
                            })
                            .catch((error) => {
                                this.error = this.parseError(error);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                } else {
                    l("rules not passing")
                }
            });
        },
        setFields() {
            this.error = null;
            this.emailRules = [];

            if (this.user.email) {
                this.newEmail = JSON.parse(JSON.stringify(this.user.email));
            } else {
                this.newEmail = null;
            }
        },
    },
    computed: {
        dialogState: {
            get() {
                return this.dialog;
            },
            set(val) {
                this.$emit("dialog", val);
            },
        },
    },
    watch: {
        dialog(val) {
            if (val) {
                this.setFields();
            }
        },
        newEmail(val) {
            if (!val) {
                l("no value watcher")
                // remove email validation rules in case user wants to delete their e-mail address. updateEmail() allows saving it only when user has a mobile nr registered
                this.emailRules = [];
            }
        },
    },
};
</script>
