import { l, le, lj, ls, lw, j, s } from './utils/devUtils';
import * as process from "process";

window.$localMode = process.env.NODE_ENV === "development" && process.env.VUE_APP_ENV === "local";
window.l = l;
window.lj = lj;
window.le = le;
window.ls = ls;
window.lw = lw;
window.j = j;
window.s = s;

window.l('process.env', process.env);

import Configuration from "./services/configuration";

window.$devMode =
     process.env.NODE_ENV === "development"
  || Configuration.value('backendUrl').includes("staging")
  || Configuration.value('backendUrl').includes("dev");


export default window;
