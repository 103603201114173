<template>
    <v-dialog v-model="dialogState" max-width="400">
        <v-card>
            <v-toolbar flat color="grey lighten-5">
                <v-toolbar-title>{{ $t("updateMobile") }} </v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn @click="$emit('dialog', false)" icon
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text class="mt-3">
                {{ $t("enterMobileText") }}

                <v-form ref="updateNumberForm" class="my-4">
                    <v-text-field
                        :rules="numberRules"
                        :label="$t('labelMobilePhone')"
                        name="phone"
                        placeholder=" "
                        v-model.trim="newMobile"
                        prepend-icon="mdi-cellphone"
                        type="text"
                        autocomplete="off"
                        @keydown.enter.prevent="updateMobile"
                    ></v-text-field>
                </v-form>
                <v-alert v-if="error" type="error" outlined text>
                    <div>
                        <strong> {{ $t("errorMobile") }}</strong>
                    </div>
                    <div class="mt-2">
                        {{ error }}
                    </div>
                </v-alert>
                <v-btn
                    :disabled="loading"
                    large
                    @click="updateMobile"
                    color="primary"
                    >{{ $t("buttons.save") }}
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { fullNumberRules, required } from "@/utils/formHelpers";
import { updateUserDetails } from "@/api/users.api";
import { updateMyDetails } from "@/api/users.api";

export default {
    props: ["user", "dialog", "isMyProfile"],
    data: () => ({
        newMobile: null,
        loading: false,
        error: null,
        numberRules: [],
    }),
    mounted() {
        this.setFields();
    },
    methods: {
        updateMobile() {
            this.error = null;
            this.piwikAnalytics(['trackEvent','User management actions', 'Updated mobile via Profile']);

            if (!this.newMobile && !this.user.email) {
                this.numberRules = required;
                this.error = this.$t("noDeleteMobileError");
                return;
            }

            this.numberRules = fullNumberRules;

            this.$nextTick(() => {
                if (this.$refs.updateNumberForm.validate()) {
                    this.loading = true;
                    let updatedUser = { ...this.user };
                    updatedUser.mobile = this.newMobile
                        ? this.newMobile.trim()
                        : null;

                    if (this.isMyProfile) {
                        updateMyDetails(updatedUser)
                            .then(() => {
                                this.$store.dispatch("showSnack", [true,"detailsUpdated"]);
                                this.$emit("dialog", false);
                                this.$emit("refresh");
                            })
                            .catch((error) => {
                                this.error = this.parseError(error);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    } else {
                        updateUserDetails(this.user.id, updatedUser)
                            .then(() => {
                                this.$store.dispatch("showSnack", [true,"detailsUpdated"]);
                                this.$emit("dialog", false);
                                this.$emit("refresh");
                            })
                            .catch((error) => {
                                this.error = this.parseError(error);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                }
            });
        },
        setFields() {
            this.error = null;
            this.numberRules = [];

            if (this.user && this.user.mobile) {
                this.newMobile = this.user.mobile;
            } else {
                this.newMobile = null;
            }
        },
    },
    computed: {
        dialogState: {
            get() {
                return this.dialog;
            },
            set(val) {
                this.$emit("dialog", val);
            },
        },
    },
    watch: {
        dialog(val) {
            if (val) {
                this.setFields();
            }
        },
        newMobile(val) {
            if (!val) {
                // remove number validation rules in case user wants to delete theirmobile. updateMobile() allows saving it only when user has a mobile nr registered
                this.numberRules = [];
            }
        },
    },
};
</script>
