import { render, staticRenderFns } from "./EmailDialog.vue?vue&type=template&id=91a61a80"
import script from "./EmailDialog.vue?vue&type=script&lang=js"
export * from "./EmailDialog.vue?vue&type=script&lang=js"
import style0 from "./EmailDialog.vue?vue&type=style&index=0&id=91a61a80&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports