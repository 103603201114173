export const entities = {
    namespaced: true,
    state: {
        expandedEntities: []

    },
    mutations: {
        EXPAND_ENTITY(state, id) {
            if(!state.expandedEntities.includes(id)){
                state.expandedEntities.push(id)
                        }
        },

        COLLAPSE_ENTITY(state, id) {
            state.expandedEntities = state.expandedEntities.filter(item => item !== id)

        },
        RESET_ENTITY_EXPAND(state){
            state.expandedEntities = []
        }

    },
    getters: {

    },
    actions: {

    }
};
