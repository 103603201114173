import {httpClient} from "./httpClient";
const END_POINT = '/askexpert';

const getAskExpertSettings = () => httpClient.get(END_POINT);

const sendAskExpertMessage = (message) => httpClient.post(`${END_POINT}/message`,{"message": message} );

export {
    getAskExpertSettings,
    sendAskExpertMessage
}
