<template>
    <div>
        <!-- /profile/:userID -->
        <v-card outlined max-width="600">
            <div v-if="loading" class="py-12">
                <v-progress-circular
                    indeterminate
                    size="35"
                    color="grey lighten-2"
                    class="d-flex mx-auto"
                ></v-progress-circular>
                <div class="text-caption text-center mt-3">
                    {{ $t("loadingProfile") }}
                    ...
                </div>
            </div>
            <div v-else>
                <div v-if="user">
                    <v-toolbar flat class="bg-toolbar">
                        <v-icon>mdi-account</v-icon>
                        <v-toolbar-title class="ml-2 text-h5"
                            >{{ $t("nav.profile") }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-btn
                            class="mr-2"
                            :disabled="isMyProfile"
                            @click="deleteDialog = true"
                            icon
                            color="red darken-3"
                            ><v-icon>delete</v-icon></v-btn
                        >
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-container>
                        <v-list two-line subheader id="profileList">
                            <!-- Name -->
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar class="mr-2" color="amber" size="30">
                                        <span
                                            class="white--text"
                                            style="font-size: 1rem"
                                        >
                                            {{ initials(user) }}
                                        </span>
                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        {{ $t("report.name") }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{ user.firstName }}
                                        {{ user.lastName }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn
                                        icon
                                        @click="updateNameDialog = true"
                                        id="updateNameTrigger"
                                    >
                                        <v-icon color="primary">edit</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>

                            <v-divider> </v-divider>

                            <!--  E-Mail -->
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>mail</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        E-mail
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{ user.email ? user.email : "-" }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn
                                        icon
                                        @click="updateEmailDialog = true"
                                    >
                                        <v-icon color="primary">edit</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>

                            <v-divider> </v-divider>

                            <!--  mobile phone -->
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>mdi-cellphone</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        {{ $t("labelMobilePhone") }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{
                                            user.mobile ? user.mobile : "-"
                                        }}</v-list-item-title
                                    >
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn
                                        @click="updateMobileDialog = true"
                                        icon
                                    >
                                        <v-icon color="primary">edit</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>

                            <!-- language -->
                            <v-divider> </v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>label</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        {{
                                            $t(
                                                "questionEditor.questions.language"
                                            )
                                        }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{
                                            user.language
                                                ? user.language.toUpperCase()
                                                : "-"
                                        }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn
                                        icon
                                        @click="updateLangDialog = true"
                                    >
                                        <v-icon color="primary">edit</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>

                            <!-- tags -->
                            <v-divider> </v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>label</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        {{ $t("tags") }}
                                    </v-list-item-subtitle>
                                    <div
                                        v-if="user.tags && user.tags.length > 0"
                                    >
                                        <div
                                            v-for="tag in user.tags"
                                            :key="tag"
                                            class="displayTag"
                                        >
                                            {{ tag }}
                                        </div>
                                    </div>
                                </v-list-item-content>

                            </v-list-item>

                            <!-- roles -->
                            <v-divider> </v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>label</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        {{ $t("rolesTitle") }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{ renderedRoles(user.roles) }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <!-- Team -->
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>mdi-account-group</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        {{ $t("nav.team") }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{ user.teamName }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn
                                        icon
                                        @click="updateTeamDialog = true"

                                    >
                                        <v-icon color="primary">edit</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>

                            <!-- Admin Of Teams -->
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>label</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        {{ $t("adminOfTeams") }}
                                    </v-list-item-subtitle>
                                    <div v-if="user.adminOfTeams && user.adminOfTeams.length > 0">
                                        <div v-for="team in user.adminOfTeams" :key="team">
                                            {{ team }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        -
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-alert
                            v-if="errorLoadingUser"
                            type="error"
                            outlined
                            text
                        >
                            {{ errorLoadingUser }}
                        </v-alert>
                    </v-container>
                    <UpdateEmailDialog
                        @refresh="initialize"
                        :dialog="updateEmailDialog"
                        :user="user"
                        @dialog="updateEmailDialog = $event"
                    />
                    <UpdateLangDialog
                        @refresh="initialize"
                        :dialog="updateLangDialog"
                        :user="user"
                        @dialog="updateLangDialog = $event"
                    />
                    <UpdateNameDialog
                        @refresh="initialize"
                        :dialog="updateNameDialog"
                        :user="user"
                        @dialog="updateNameDialog = $event"
                    />
                    <UpdateMobileDialog
                        @refresh="initialize"
                        :dialog="updateMobileDialog"
                        :user="user"
                        @dialog="updateMobileDialog = $event"
                    />
                    <UpdateTeamDialog
                        @refresh="initialize"
                        :dialog="updateTeamDialog"
                        :user="user"
                        @dialog="updateTeamDialog = $event"
                        :currentTeam="{ name: user.teamName, id: user.teamId }"
                    />
                    <DeleteProfileDialog
                        :dialog="deleteDialog"
                        :user="user"
                        @dialog="deleteDialog = $event"
                    />
                </div>
                <v-alert
                    v-if="errorLoadingUser"
                    type="error"
                    text
                    outlined
                    class="ma-3"
                >
                    {{ errorLoadingUser }}
                </v-alert>
            </div>
        </v-card>
    </div>
</template>

<script lang="ts">
import UpdateEmailDialog from "@/components/Profile/UpdateEmailDialog.vue";
import UpdateLangDialog from "@/components/Profile/UpdateLangDialog.vue";
import UpdateNameDialog from "@/components/Profile/UpdateNameDialog.vue";
import UpdateTeamDialog from "@/components/Profile/UpdateTeamDialog.vue";
import UpdateMobileDialog from "@/components/Profile/UpdateMobileDialog.vue";
import DeleteProfileDialog from "@/components/Profile/DeleteProfileDialog.vue";
import { getUserDetails } from "../api/users.api";
import { Component, Vue, Watch } from "vue-property-decorator";
import { UserDB } from "@/models/user";
@Component({
    components: {
        UpdateEmailDialog,
        UpdateLangDialog,
        UpdateNameDialog,
        UpdateMobileDialog,
        DeleteProfileDialog,
        UpdateTeamDialog,
    },
})
export default class Profile extends Vue {
    mounted() {
        this.userId = this.$route.params.user_id;
        this.initialize();
    }
    userId: string | null = null;
    user: UserDB | null = null;
    loading: boolean = false;
    errorLoadingUser: string | null = null;
    updateEmailDialog: boolean = false;
    updateLangDialog: boolean = false;
    updateNameDialog: boolean = false;
    updateMobileDialog: boolean = false;
    deleteDialog: boolean = false;
    updateTeamDialog: boolean = false;

    initialize() {
        this.errorLoadingUser = null;
        this.loading = true;
        getUserDetails(this.userId)
            .then((response) => {
                this.user = response.data;
                window.l("User data: \n", window.s(this.user));
            })
            .catch((error) => {
                this.errorLoadingUser = this.parseError(error);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    renderedRoles(roles) {
        if (roles.length > 0) {
            let mappedRoles = roles.map((role) => {
                if (role === "SUPERADMIN") {
                    return this.$t("roles.superadmin");
                } else if (role === "ADMIN") {
                    return "Admin";
                } else if (role === "MEMBER") {
                    return this.$t("roles.member");
                } else if (role === "ROOT") {
                    return "Root";
                } else {
                    return role;
                }
            });

            return mappedRoles.sort().join(", ");
        } else {
            return "-";
        }
    }

    get myUserId() {
        if (this.$store.state.auth.user) {
            return this.$store.state.auth.user.userId;
        } else {
            return null;
        }
    }

    get isMyProfile() {
        if (this.myUserId) {
            return this.myUserId === this.userId;
        } else {
            return false;
        }
    }

    @Watch("$route")
    routeWatcher(to, from) {
        if (to.params.user_id !== this.userId) {
            // fetch new content when jumping between viewing different users
            this.userId = to.params.user_id;
            this.initialize();
        }
    }
}
</script>
