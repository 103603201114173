<template>
    <!-- globally usable slider where you can drag from 1 to top grade. emits a mapped value usable with icons (so slider value 9 emits 6 when top grade is 10) -->
    <div>
        <v-slider
            v-if="topGrade && sliderRating"
            :tick-labels="labels[topGrade]"
            track-color="grey"
            :thumb-color="color"
            v-model="sliderRating"
            :color="color"
            step="1"
            min="1"
            :max="topGrade"
            ticks="always"
            tick-size="3"
            thumb-label
            always-dirty
            @input="changeRating"
        ></v-slider>
    </div>
</template>

<script>
export default {
    props: ["rating"],
    components: {},
    mounted() {
        if (this.topGrade == 6) {
            this.gradeToPicIndexMap = [0, 1, 2, 3, 4, 5, 6];
        } else if (this.topGrade == 10) {
            this.gradeToPicIndexMap = [0, 1, 1, 2, 2, 3, 3, 4, 5, 6, 6];
        }

        this.sliderRating = this.rating;
    },
    data: () => ({
        labels: {
            6: [1, 2, 3, 4, 5, 6],
            10: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        gradeToPicIndexMap: [],
        sliderRating: null
    }),
    methods: {
        changeRating(val) {
            //emits a mapped value usable by icons to parent component
            this.$emit("ratingChange", this.gradeToPicIndexMap[val]);
        }
    },
    computed: {
        topGrade() {
            return this.$store.state.topGrade;
        },
        color() {
            if (this.topGrade === 10) {
                if (this.sliderRating > 8) {
                    return "green";
                } else if (this.sliderRating > 6) {
                    return "orange";
                } else {
                    return "red";
                }
            }

            if (this.topGrade === 6) {
                if (this.sliderRating > 4) {
                    return "green";
                } else if (this.sliderRating > 3) {
                    return "orange";
                } else {
                    return "red";
                }
            }
        }
    }
};
</script>
