<template>
    <div class="ff-container">
        <v-card class="ff-card quick-fade-in" color="#F8FEEF">
            <v-container class=" pos-relative">
                           <VotingBar
                       v-if="showReactions"
                        :userId="userId"
                        :feedforwardId="closedFeedforwardItem.id"
                        :reactions="closedFeedforwardItem.reactions"
                        @addUpvote="closedFeedforwardItem.reactions.upvote.push(userId)"
                        @removeUpvote="
                            closedFeedforwardItem.reactions.upvote = closedFeedforwardItem.reactions.upvote.filter(
                                id => id !== userId
                            )
                        "
                        @addCelebrate="closedFeedforwardItem.reactions.celebrate.push(userId)"
                        @removeCelebrate="
                            closedFeedforwardItem.reactions.celebrate = closedFeedforwardItem.reactions.celebrate.filter(
                                id => id !== userId
                            )
                        "
                    />
                <p class="text-small font-normal font-italic">
                    {{
                        closedFeedforwardItem.author
                            ? closedFeedforwardItem.author.displayName
                            : `${$t("discussionMode.anonym")}`
                    }}:
                </p>
                <div class="text-dark-grey font-italic">
                    {{ closedFeedforwardItem.answer }}
                </div>
            </v-container>
        </v-card>

        <div class="mt-1">
            <v-tooltip color="grey" bottom open-delay="800">
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="ff-btn reopenFeedforwardBtn"
                        v-on="on"
                        small
                        block
                        @click="openFF"
                        :ripple="false"
                    >
                        <v-icon
                            :left="$vuetify.breakpoint.smAndUp"
                            color="grey lighten-1"
                            >mdi-upload</v-icon
                        ><span v-if="$vuetify.breakpoint.smAndUp">{{
                            $t("buttons.reopen")
                        }}</span>
                    </v-btn>
                </template>
                <span>{{ $t("tooltips.reopenFF") }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import { openFeedForward } from "../api/feedforward.api";
import VotingBar from "@/components/Voting/VotingBar.vue";
export default {
    name: "ClosedFeedforward",
    props: ["closedFeedforwardItem"],
    components: {VotingBar},
    data: () => ({}),
    methods: {
        openFF() {
            openFeedForward(this.closedFeedforwardItem.id);
            this.piwikAnalytics(['trackEvent','Discussion actions', 'Feedforward reopened']);
            this.closedFeedforwardItem.status = "OPEN";
            this.$store.dispatch("showSnack", [true, "ffOpened"]); //notification for user
        }
    },
    computed:{
            showReactions() {
            return this.$store.getters.checkFeature("REACTIONS_ENABLED");
        },         userId() {
            return this.$store.state.auth.user.userId;
        }
    }
};
</script>

<style>
.createTask {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
</style>
