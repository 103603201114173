<template>
    <div class="d-flex mb-1" :class="{'align-center': !isExpanded}">
        <v-expansion-panels
            v-model="panel"
            class="openTask quick-fade-in"

        >
            <v-expansion-panel class="task">
                <v-expansion-panel-header
                    class="py-2"
                    @click="isExpanded = !isExpanded"
                    @keyup.space.prevent
                >
                    <v-row align="center" no-gutters>
                        <v-col style="max-width: 40px" class="taskIcon">
                            <v-icon
                                v-if="!overdue"
                                color="yellow darken-1"
                                class="px-1"
                                >mdi-clipboard-text-outline</v-icon
                            >
                            <v-icon v-else color="#E96B24" class="px-1"
                                >event</v-icon
                            >
                        </v-col>
                        <v-col>
                            <div v-if="!edit">
                                {{ task.summary }}
                                <v-icon
                                    class="fade-in"
                                    v-show="isExpanded"
                                    small
                                    color="grey lighten-1"
                                    @click.stop="edit = true"
                                    >mdi-pencil</v-icon
                                >
                            </div>

                            <v-text-field
                                @click.native.stop
                                ref="editTitle"
                                v-else
                                hide-details
                                solo
                                dense
                                flat
                                background-color="transparent"
                                v-model="task.summary"
                                @keyup.enter="editTitle"
                                @blur="editTitle"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <v-container
                        :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
                    >
                        <div class="text-caption grey--text">
                            {{ $t("admin.created") }}:
                            {{ task.createdAt | moment("DD/MM/YYYY, kk:mm") }}
                        </div>

                        <!-- task origin -->
                        <span class="boxHeader">{{ $t("tasks.origin") }}:</span>
                        <v-card outlined>
                            <TaskOrigin :task="task" />
                        </v-card>
                        <!-- /task origin -->

                        <!-- comments -->
                        <div v-if="task.comments.length > 0">
                            <span class="boxHeader"
                                >{{ $t("tasks.comments") }}:</span
                            >

                            <v-card width="100%" outlined>
                                <v-container>
                                    <div
                                        v-for="(comment, k) in task.comments"
                                        :key="comment.id"
                                    >
                                        <Comment
                                            :comment="comment"
                                            :task-id="task.id"
                                            :openTask="true"
                                        />
                                        <v-divider
                                            v-if="k + 1 < task.comments.length"
                                            class="my-1"
                                        ></v-divider>
                                    </div>
                                </v-container>
                            </v-card>
                        </div>

                        <!-- /comments -->
                        <div :class="{ 'mt-2': task.comments.length === 0 }">
                            <div class="d-flex align-center">
                                <div
                                    v-show="task.comments.length == 0"
                                    class="font-weight-light grey--text ml-3"
                                >
                                    {{ $t("tasks.noComments") }}
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    :ripple="false"
                                    text
                                    small
                                    color="accent darken-1"
                                    @click="addComment = !addComment"
                                    class="ma-1"
                                >
                                    <v-icon small class="mr-1">{{
                                        addComment
                                            ? "mdi-chevron-up"
                                            : "mdi-comment-plus-outline"
                                    }}</v-icon
                                    >{{ $t("tooltips.addComment") }}
                                </v-btn>
                            </div>
                        </div>
                        <v-expand-transition>
                            <div v-show="addComment">
                                <AddComment :task="task" />
                            </div>
                        </v-expand-transition>
                        <v-row justify="space-between" class="pt-4" no-gutters>
                            <!-- date picker -->
                            <v-col cols="12" sm="4">
                                <v-menu
                                    v-model="pickermenu"
                                    :close-on-content-click="true"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            clearable
                                            @click:clear="clearDeadline"
                                            dense
                                            :value="
                                                task.dueDate
                                                    ? renderDate(task.dueDate)
                                                    : ''
                                            "
                                            :label="pickerLabel"
                                            readonly
                                            v-on="on"
                                        >
                                            <template v-slot:prepend>
                                                <v-icon
                                                    :color="
                                                        overdue
                                                            ? 'red'
                                                            : 'grey darken-1'
                                                    "
                                                    >event</v-icon
                                                >
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        no-title
                                        v-model="pickerDeadline"
                                        @input="pickermenu = false"
                                        :locale="$i18n.locale"
                                        :first-day-of-week="1"
                                        :min="today"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <!-- /date picker -->

                            <!-- person picker -->
                            <v-col cols="12" sm="5">
                                <v-select
                                    dense
                                    prepend-icon="account_circle"
                                    :items="assigneeList"
                                    item-value="id"
                                    item-text="displayName"
                                    :label="assignLabel"
                                    v-model="person"
                                    style="font-size: 12px"
                                >
                                </v-select>
                            </v-col>
                            <!-- /person picker -->
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="$route.name !== 'Task'"
                                color="grey"
                                class="text-caption ml-n2"
                                style="text-transform: none"
                                text
                                x-small
                                :to="`/task/${task.id}`"
                                ><v-icon
                                    class="mr-1"
                                    x-small
                                    style="color: inherit"
                                    >mdi-page-next-outline</v-icon
                                >
                                {{ $t("tasks.taskPage") }}</v-btn
                            >
                        </div>
                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <!-- btns -->
        <div :class="{'mt-1': isExpanded}">
            <v-tooltip bottom color="green" open-delay="1000">
                <template v-slot:activator="{ on }">
                    <task-button
                        class="closeTaskBtn"
                        v-on="on"
                        @click="close"
                        :label="$t('buttons.done')"
                        :icon="'mdi-check'"
                        :iconColor="'green'"
                    ></task-button>
                </template>
                <span>{{ $t("tooltips.closeTask") }}</span>
            </v-tooltip>

            <v-tooltip bottom color="orange" open-delay="1000">
                <template v-slot:activator="{ on }">
                    <task-button
                        v-show="isExpanded"
                        v-on="on"
                        @click="escalateDialog = true"
                        :label="$t('buttons.moveUp')"
                        :icon="'mdi-account-arrow-right'"
                        :iconColor="'orange'"
                    ></task-button>
                </template>
                <span>{{ $t("tooltips.moveUp") }}</span>
            </v-tooltip>

            <EscalateDialog
                :task="task"
                :dialog="escalateDialog"
                @dialogStatus="escalateDialog = $event"
            />

            <v-tooltip bottom color="blue darken-1" open-delay="1000">
                <template v-slot:activator="{ on }">
                    <task-button
                        v-show="isExpanded"
                        v-on="on"
                        @click="emailDialog = true"
                        :label="'E-mail'"
                        :icon="'mdi-email-outline'"
                        :iconColor="'blue lighten-1'"
                    ></task-button>
                </template>
                <span>{{ $t("tasks.sendEmail") }}</span>
            </v-tooltip>

            <EmailDialog
                :task="task"
                :emailDialog="emailDialog"
                @emailDialogStatus="emailDialog = $event"
            />

            <v-tooltip bottom color="grey darken-2" open-delay="1000">
                <template v-slot:activator="{ on }">
                    <task-button
                        v-show="isExpanded"
                        v-on="on"
                        @click="deleteDialog = true"
                        :label="$t('buttons.delete')"
                        :icon="'mdi-trash-can'"
                    ></task-button>
                </template>
                <span>{{ $t("tooltips.deleteTask") }}</span>
            </v-tooltip>

            <v-dialog v-model="deleteDialog" max-width="450">
                <v-card>
                    <v-card-title
                        class="headline white--text grey darken-2"
                        primary-title
                    >
                        <v-icon color="white" class="mr-2"
                            >mdi-trash-can</v-icon
                        >
                        {{ $t("tasks.deleteTitle") }}
                    </v-card-title>

                    <v-card-text class="mt-2">
                        {{ $t("tasks.deleteBody") }}

                        <div class="d-flex align-center mt-3">
                            <v-icon color="yellow darken-1" class="px-1"
                                >mdi-clipboard-text-outline</v-icon
                            >

                            <div>
                                <div class="font-weight-medium body-1">
                                    {{ task.summary }}
                                </div>

                                <div class="text-caption grey--text">
                                    {{ $t("admin.created") }}:
                                    {{
                                        task.createdAt |
                                            moment("DD/MM/YYYY, kk:mm")
                                    }}
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pb-4">
                        <v-btn
                            color="grey darken-1"
                            text
                            @click="deleteDialog = false"
                            >{{ $t("buttons.cancel") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            dark
                            class="deleteTaskBtn"
                            color="grey"
                            @click="removeTask"
                            >{{ $t("buttons.delete") }}</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <!-- btns-->
    </div>
</template>

<script lang="ts">
import {
    assignTask,
    changeDate,
    closeTask,
    changeTitle,
    deleteTask
} from "@/api/tasks.api";

import EscalateDialog from "@/components/Taskboard/EscalateDialog.vue";
import EmailDialog from "@/components/Taskboard/EmailDialog.vue";
import Comment from "@/components/Taskboard/Comment.vue";
import AddComment from "@/components/AddComment.vue";
import { getTeamMembers } from "@/api/teams.api";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { task } from "@/models/task";
import { DateISO8601 } from "@/models/dates";
import { userIdName } from "@/models/user";
import {getEscalateAdmins} from "@/api/admin.api";
import {getAdhocSurveyMembers} from "@/api/adhoc.api";

@Component({
    components: {
        EscalateDialog,
        AddComment,
        EmailDialog,
        Comment,
    },
})
export default class OpenTask extends Vue {
    @Prop() task: task;
    @Prop() startOpen: boolean;

    mounted() {
        if (this.startOpen) {
            this.isExpanded = true;
            this.panel = 0;
        }

        if (this.task.delegated) {
            getEscalateAdmins().then((response) => {
                this.assignees = response.data;
            });
        } else {

            if (this.task.entityId) {
                getTeamMembers(this.task.entityId).then((response) => {
                    this.assignees = response.data;
                });
            } else if (this.task.surveyId) {
                getAdhocSurveyMembers(this.task.surveyId).then((response) => {
                    this.assignees = response.data;
                });
            } else {
                this.assignees = [];
            }
        }
    }

    panel: number | null = null; //when 0, means that panel is expanded
    assignees: userIdName[] = [];
    addComment: boolean = false; //add comment field visibility state
    isExpanded: boolean = false; //expansion panel state
    escalateDialog: boolean = false; //move up dialog
    deleteDialog: boolean = false; //deletetask dialog
    emailDialog: boolean = false; //send task via e-mail dialog
    pickermenu: boolean = false; //date picker visibility state
    today: string = new Date().toISOString().substr(0, 10); //for disabling past dates in date picker
    edit: boolean = false; //edit task title

    renderDate(date): DateISO8601 {
        //@ts-ignore
        return this.$moment(date).format("DD/MM/YYYY");
    }
    close(): void {
        closeTask(this.task.id).then(() => {
            this.task.status = "CLOSED";

            this.piwikAnalytics(["trackEvent", "Task actions", "Completed"]);
            this.$store.dispatch("showSnack", [true, "taskClosed"]); //notification for user
            this.$emit("showNotification", 1);
        });
    }
    removeTask(): void {
        deleteTask(this.task.id).then(() => {
            this.task.status = "DELETED";

            this.piwikAnalytics(["trackEvent", "Task actions", "Deleted"]);
            if(this.separatePage){
               //redirect to taskboard
               this.$router.push("/team-tasks");
            }
        });
        this.deleteDialog = false;
        this.$store.dispatch("showSnack", [true, "taskDeleted"]); //notification for user
    }
    clearDeadline(): void {
        this.task.dueDate = null;
        changeDate(this.task.id, { date: null }).then(()=>{
            this.$store.dispatch("showSnack", [true,"detailsUpdated"]);
        });;
    }
    editTitle(): void {
        this.edit = false;
        changeTitle(this.task.id, this.task.summary);

        this.piwikAnalytics(["trackEvent", "Task actions", "Title edited"]);
    }
    findTeamMember(userId): userIdName {
        return this.assignees.filter((member) => member.id === userId)[0];
    }

    get pickerLabel(): string {
        return !this.task.dueDate
            ? `${this.$t("tasks.setDueDate")}:`
            : `${this.$t("tasks.dueDate")}:`;
    }
    get assignLabel(): string {
        return !this.task.assignedTo.id
            ? `${this.$t("tasks.assign")}:`
            : `${this.$t("tasks.assignedTo")}:`;
    }

    get separatePage(): boolean{
        return this.$route.name === 'task'
    }

    get pickerDeadline() {
        if (!this.task.dueDate) {
            return new Date().toISOString().substr(0, 10);
        } else {
            return new Date(this.task.dueDate).toISOString().substr(0, 10);
        }
    }

    set pickerDeadline(value) {
        //@ts-ignore
        this.task.dueDate = new Date(value);
        changeDate(this.task.id, { date: this.task.dueDate }).then(()=>{
            this.$store.dispatch("showSnack", [true,"detailsUpdated"]);
        });


        this.piwikAnalytics(["trackEvent", "Task actions", "Due date changed"]);
    }

    get person(): userIdName {
        return this.task.assignedTo;
    }

    set person(value) {
        this.task.assignedTo = this.findTeamMember(value);
        assignTask(this.task.id, value).then(()=>{
            this.$store.dispatch("showSnack", [true,"detailsUpdated"]);
        });

        this.piwikAnalytics(["trackEvent", "Task actions", "Reassigned"]);
    }
    get assignedToExistingMember(): boolean {
        // returns true if assignee is still an active part of the team
        if (this.assignees) {
            for (let i = 0; i < this.assignees.length; i++) {
                if (this.assignees[i].id === this.task.assignedTo.id) {
                    return true;
                }
            }
            return false;
        } else {
            return true;
        }
    }

    get assigneeList() {
        if (this.assignedToExistingMember || !this.task.assignedTo.id) {
            return this.assignees;
        } else {
            //  generate a new assignees list where the past team member is a disabled option
            let newAssignees = [...this.assignees];
            newAssignees.unshift({
                //@ts-ignore
                disabled: true,
                id: this.task.assignedTo.id,
                displayName: this.task.assignedTo.displayName,
            });
            return newAssignees;
        }
    }
    get overdue(): boolean {
        // true if task due date is set and it's in the past
        if (
            //@ts-ignore
            new Date(this.task.dueDate).setHours(0, 0, 0, 0) -
                new Date().setHours(0, 0, 0, 0) >=
                0 ||
            !this.task.dueDate
        ) {
            //first date is in future, or today
            return false;
        }

        return true;
    }

    @Watch("edit")
    onEditChanged(value: string, oldValue: string) {
        if (value) {
            this.$nextTick(() =>
                (this.$refs.editTitle as HTMLImageElement).focus()
            );
        }
    }
}
</script>

<style>
.v-label {
    font-size: 0.9rem;
}
</style>
