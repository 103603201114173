var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.question)?_c('v-card',{staticClass:"transitioned",class:{
            'my-3': _vm.commentsExpanded,
            'hover-pointer': _vm.question.ffCount > 0,
            'rounded-tl-0': _vm.i > 0,
            'rounded-tr-0': _vm.i > 0,
            'rounded-bl-0': _vm.i + 1 < _vm.totalQuestions,
            'rounded-br-0': _vm.i + 1 < _vm.totalQuestions
        },staticStyle:{"margin-top":"-1px"},attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex align-center pa-2 py-3 DMquestion",staticStyle:{"font-size":"1rem"},on:{"click":_vm.toggleShowComments}},[_c('div',{staticClass:"dragHandle d-flex hover-drag mr-1"},[_c('v-icon',{staticStyle:{"margin-right":"-2px"},attrs:{"x-small":""}},[_vm._v("mdi-arrow-up-down")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-menu")])],1),_c('div',{staticClass:"ml-2 d-flex flex-column"},[_c('div',{staticStyle:{"font-size":"1.2rem"},domProps:{"innerHTML":_vm._s(_vm.parseLinks(_vm.translatedText(_vm.question.questionText, _vm.$i18n.locale)))}}),_c('div',{staticClass:"d-flex mt-1 align-center"},[(_vm.question.statistic)?_c('div',{attrs:{"title":_vm.$t('dashboard.average')}},[_vm._v(" Ø "),_c('strong',{class:_vm.avgTextColor(_vm.question.statistic.average)},[_vm._v(_vm._s(_vm.roundUp(_vm.question.statistic.average)))])]):_vm._e(),(_vm.question.statistic)?_c('div',{staticClass:"ml-2",attrs:{"title":_vm.$t('dashboard.deviation')}},[_c('span',[_vm._v("↔")]),_c('strong',{class:_vm.question.statistic.hasOwnProperty('deviation')
                                    ? _vm.devTextColor(_vm.question.statistic.deviation)
                                    : ''},[_vm._v(_vm._s(_vm.roundUp(_vm.question.statistic.deviation)))])]):_vm._e(),_c('div',{staticClass:"mx-2",staticStyle:{"position":"relative"}},[(_vm.question.ffCount < 100)?_c('div',[_c('v-icon',{staticStyle:{"font-size":"1.5rem"},attrs:{"color":"grey"}},[_vm._v("mdi-comment-outline")]),_c('div',{staticStyle:{"position":"absolute","top":"40%","left":"50%","transform":"translate(-50%, -50%)","font-size":"0.7rem","color":"grey"}},[_vm._v(" "+_vm._s(_vm.question.ffCount)+" ")])],1):_c('div',[_c('v-icon',{staticStyle:{"font-size":"1.8rem"},attrs:{"color":"grey"}},[_vm._v("mdi-comment-outline")]),_c('div',{staticStyle:{"position":"absolute","top":"40%","left":"50%","transform":"translate(-50%, -50%)","font-size":"0.65rem","color":"grey"}},[_vm._v(" "+_vm._s(_vm.question.ffCount)+" ")])],1)])])]),_c('v-spacer'),_c('v-icon',{staticClass:"mx-2",class:{ hidden: !_vm.question.isTeamQuestion },attrs:{"large":"","title":_vm.$t('survey.teamQuestion')}},[_vm._v(_vm._s('$vuetify.icons.diversity'))]),(_vm.canShowAction)?_c('v-btn',{staticClass:"mr-2 plain-txt learningNugget",attrs:{"text":"","title":_vm.translatedText(
                   _vm.action?.descriptions,
                  _vm.$i18n.locale
                )},on:{"click":_vm.handleAction}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}],staticClass:"primary--text",staticStyle:{"font-size":"1rem"}},[_vm._v("Learning Nugget")]),_c('span',{staticStyle:{"font-size":"1.5rem"}},[_vm._v(" 🎓")])]):_vm._e(),_c('v-btn',{staticClass:"mx-1",class:{ hidden: !(_vm.question.ffCount > 0) },attrs:{"small":"","icon":"","ripple":false,"title":_vm.commentsExpanded ? 'Hide Feedforwards' : 'Show Feedforwards'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleShowComments.apply(null, arguments)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.commentsExpanded ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),(_vm.loadingError)?_c('v-card',{staticClass:"red lighten-5 pa-3 d-flex",staticStyle:{"color":"red"},attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"red","large":""}},[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.loadingError)+" ")],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){_vm.loadingError = null}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-expand-transition',[(_vm.commentsExpanded && _vm.comments)?_c('div',{},_vm._l((_vm.comments),function(feedforwardItem,i){return _c('div',{key:feedforwardItem.id,staticClass:"pos-relative"},[(i < _vm.comments.length)?_c('v-divider',{staticClass:"mb-4"}):_vm._e(),(_vm.showReactions)?_c('VotingBar',{attrs:{"userId":_vm.userId,"feedforwardId":feedforwardItem.id,"reactions":feedforwardItem.reactions},on:{"addUpvote":function($event){return feedforwardItem.reactions.upvote.push(_vm.userId)},"removeUpvote":function($event){feedforwardItem.reactions.upvote = feedforwardItem.reactions.upvote.filter(
                                id => id !== _vm.userId
                            )},"addCelebrate":function($event){return feedforwardItem.reactions.celebrate.push(_vm.userId)},"removeCelebrate":function($event){feedforwardItem.reactions.celebrate = feedforwardItem.reactions.celebrate.filter(
                                id => id !== _vm.userId
                            )}}}):_vm._e(),_c('div',{staticClass:"d-flex px-5"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"yellow darken-1","size":"34"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account")])],1),_c('div',[_c('p',{staticClass:"mb-0 grey--text"},[_vm._v(" "+_vm._s(feedforwardItem.author ? feedforwardItem.author.displayName : `${_vm.$t("discussionMode.anonym")}`)+": ")]),_c('p',{},[_vm._v(" "+_vm._s(feedforwardItem.answer)+" ")])])],1)],1)}),0):_vm._e()]),_c('v-dialog',{model:{value:(_vm.actionDialog),callback:function ($$v) {_vm.actionDialog=$$v},expression:"actionDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Actions")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.action)+" ")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }