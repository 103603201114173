<template>
    <!-- checklist for how to deal with a discussion -->
    <div class="pa-4 pt-2" id="dmTodoList">
        <div class="d-flex align-center">
            <div class="mr-2">
                <v-img

                    contain
                    width="50"
                    :src="require('@/assets/images/target.png')"
                ></v-img>
            </div>

            <div>
                <!-- style="font-size: 3rem" -->
                <h3>{{ $t("whatsNextTitle") }}</h3>
                <i18n class="dark-text" path="whatsNextText">
                    {{ selectedTeam }}
                </i18n>
            </div>
        </div>

        <!-- todo list -->
        <div class="ml-2 mt-5 todoList" style="">
            <div class="todo">
                <v-icon
                    color="green"
                    style="margin-left: 5px; margin-right: 5px"
                    >mdi-checkbox-marked-outline</v-icon
                >

                <div>{{ $t(todos.finishSurvey.text) }}</div>
            </div>

            <div
                @mouseover="highlight"
                @mouseleave="$emit('highLightQuestions', false)"
            >
                <div class="todo">
                    <v-btn
                        @click="toggleReviewResults"
                        icon
                        :color="todos.reviewResults.done ? 'green' : ''"
                        :ripple="false"
                    >
                        <v-icon>{{
                            todos.reviewResults.done
                                ? "mdi-checkbox-marked-outline"
                                : "mdi-checkbox-blank-outline"
                        }}</v-icon>
                    </v-btn>

                    <div>{{ $t(todos.reviewResults.text) }}</div>
                </div>

                <div
                    v-if="!todos.reviewResults.done"
                    class="ml-9 text--secondary"
                >
                    {{ $t(todos.reviewResults.hint) }}
                </div>
            </div>

            <div class="todo">
                <v-btn
                    :disabled="!todos.reviewResults.done"
                    @click="toggleDiscussionScheduled"
                    :color="todos.discussionScheduled.done ? 'green' : ''"
                    icon
                    :ripple="false"
                >
                    <v-icon>{{
                        todos.discussionScheduled.done
                            ? "mdi-checkbox-marked-outline"
                            : "mdi-checkbox-blank-outline"
                    }}</v-icon>
                </v-btn>

                <div>{{ $t(todos.discussionScheduled.text) }}</div>
            </div>
            <div
                v-if="
                    todos.reviewResults.done && !todos.discussionScheduled.done
                "
                class="ml-9 text--secondary"
            >
                {{ $t(todos.discussionScheduled.hint) }}
            </div>

            <div class="todo">
                <v-btn
                    :disabled="!todos.discussionScheduled.done"
                    @click="toggleDiscussionDone"
                    icon
                    :color="todos.discussionDone.done ? 'green' : ''"
                    :ripple="false"
                >
                    <v-icon>{{
                        todos.discussionDone.done
                            ? "mdi-checkbox-marked-outline"
                            : "mdi-checkbox-blank-outline"
                    }}</v-icon>
                </v-btn>

                <div>{{ $t(todos.discussionDone.text) }}</div>
            </div>
            <div
                v-if="
                    todos.discussionScheduled.done && !todos.discussionDone.done
                "
                class="ml-9 text--secondary"
            >
                {{ $t(todos.discussionDone.hint) }}
            </div>
        </div>
    </div>
</template>

<script>
import {
    getTodos,
    setTodos,
    getDiscussion,
    markDiscussionDone,
    markDiscussionOpen
} from "@/api/discussion.api";
export default {
    props: ["discussionId", "selectedTeam", "FFhandleRate"],
    components: {},
    mounted() {
        this.initialize();
    },
    data: () => ({
        rawTodos: null,
        // ["RESULT_READ","DISCUSSION_SCHEDULED"]
        expandReviewInfo: true,
        todos: {
            finishSurvey: {
                text: "finishSurvey",
                done: true
            },
            reviewResults: {
                text: "reviewResults",
                reference: "RESULT_READ",
                hint: "reviewResultsHint",
                done: false
            },
            discussionScheduled: {
                text: "scheduleMeeting",
                reference: "DISCUSSION_SCHEDULED",
                hint: "scheduleMeetingHint",
                done: false
            },
            discussionDone: {
                text: "doDiscussion",
                hint: "doDiscussionHint",
                done: false
            }
        }
    }),
    methods: {
        highlight() {
            if (!this.todos.reviewResults.done) {
                this.$emit("highLightQuestions", true);
            }
        },
        initialize() {
            getTodos(this.discussionId).then(response => {
                if (this.$devMode) {
                    console.log(
                        "discussion todos: " + JSON.stringify(response.data)
                    );
                }
                this.rawTodos = response.data;
                this.$emit("setTodos", response.data);

                this.todos.reviewResults.done = this.rawTodos.includes(
                    "RESULT_READ"
                );

                this.todos.discussionScheduled.done = this.rawTodos.includes(
                    "DISCUSSION_SCHEDULED"
                );
            });
            this.setDoneState();
        },
        setDoneState() {
            getDiscussion(this.discussionId).then(response => {
                if (this.$devMode) {
                    console.log("discussion status: " + response.data.status);
                }

                const status = response.data.status;
                //handle discussions with 100% handle rate that haven't been marked as "done"
                if (this.FFhandleRate === 100 && status !== "DONE") {
                    //update GUI
                    this.todos.reviewResults.done = true;
                    this.todos.discussionScheduled.done = true;
                    this.todos.discussionDone.done = true;

                    //update db as "DONE"
                    markDiscussionDone(this.discussionId);

                    //update parent component
                    this.$emit("discussionDone", true);
                } else {
                    if (status == null || status == "OPEN") {
                        this.todos.discussionDone.done = false;
                        this.$emit("discussionDone", false);
                    }
                    if (status === "DONE") {
                        //update GUI
                        this.todos.reviewResults.done = true;
                        this.todos.discussionScheduled.done = true;
                        this.todos.discussionDone.done = true;

                        //update parent component
                        this.$emit("discussionDone", true);
                    }
                }
                this.setTodoList();
            });
        },
        setTodoList() {
            let payload = [];

            if (this.todos.reviewResults.done) {
                payload.push("RESULT_READ");
            }
            if (this.todos.discussionScheduled.done) {
                payload.push("DISCUSSION_SCHEDULED");
            }
            this.rawTodos = payload;
            this.$emit("setTodos", payload);
            setTodos(this.discussionId, payload);
        },
        toggleReviewResults() {

            this.piwikAnalytics(['trackEvent','Discussion actions', 'Click ToDo', "Results reviewed"]);
            if (
                !this.todos.discussionScheduled.done &&
                !this.todos.discussionDone.done
            ) {
                this.todos.reviewResults.done = !this.todos.reviewResults.done;
                this.setTodoList();
            }
        },
        toggleDiscussionScheduled() {

             this.piwikAnalytics(['trackEvent','Discussion actions', 'Click ToDo', "Discussion Scheduled"]);
            if (!this.todos.discussionDone.done) {
                this.todos.discussionScheduled.done = !this.todos
                    .discussionScheduled.done;
                this.setTodoList();
            }
        },
        toggleDiscussionDone() {
             this.piwikAnalytics(['trackEvent','Discussion actions', 'Click ToDo', "Results discussed"]);
            if (this.todos.discussionScheduled.done) {
                this.todos.discussionDone.done = !this.todos.discussionDone
                    .done;

                if (this.todos.discussionDone.done) {
                    markDiscussionDone(this.discussionId);
                    this.$emit("discussionDone", true);
                } else {
                    markDiscussionOpen(this.discussionId);
                    this.$emit("discussionDone", false);
                }
            }
        }
    },
    computed: {},
    watch: {
        discussionId() {
            this.initialize();
        }
    }
};
</script>

<style>
.todo {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}
</style>
