import i18n from "../i18n"; //translations

const emailRules = [
    (v) => !!v || i18n.messages[i18n.locale]["tooltips"]["required"],

    (v) => {
        const pattern =
            /^(([a-zA-Z0-9_\-\.\+]+)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (
            pattern.test(v) ||
            i18n.messages[i18n.locale]["errors"]["incorrectEmail"]
        );
    },

    /*    v =>
        /.+@.+\..+/.test(v) ||
        i18n.messages[i18n.locale]["errors"]["incorrectEmail"],
    v =>
        (v || "").indexOf(" ") < 0 ||
        i18n.messages[i18n.locale]["errors"]["incorrectEmail"] */
];

const pwRules = [
    (v) => !!v || i18n.messages[i18n.locale]["tooltips"]["required"],
    (v) => v.length >= 7 || i18n.messages[i18n.locale]["passwordLengthError"],
];

const numberRules = [
    (v) => /^[0-9]*$/.test(v) || i18n.messages[i18n.locale]["tfaInvalid"],
];

const fullNumberRules = [
    (v) =>
        /^[0-9\+]*$/.test(v.trim()) || i18n.messages[i18n.locale]["tfaInvalid"],
];

const swissNumberRules = [
    (v) => /^[^0]/.test(v) || i18n.messages[i18n.locale]["tfaRemove0"],
    (v) => (v && v.length <= 9) || i18n.messages[i18n.locale]["tfaInvalid"],
];

const required = [
    (v) => !!v || i18n.messages[i18n.locale]["tooltips"]["required"],
];
const nameRules = [
    (v) => !!v || i18n.messages[i18n.locale]["tooltips"]["required"],
    (v) => (v && v.length < 40) || "Too long",
];

export {
    emailRules,
    pwRules,
    numberRules,
    fullNumberRules,
    swissNumberRules,
    required,
    nameRules,
};
