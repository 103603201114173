<template>
    <v-icon :small="small" :large="large" v-if="entity" :color="computedColor">
        {{ legend[entity.level].icon }}
    </v-icon>
</template>

<script>
import entityIconLegend from './EntityIcon.data.js';
export default {
    props: ["entity", "small", "large", 'disabled'],
    components: {},
    data: () => ({
        legend: entityIconLegend,
    }),
    computed:{
        computedColor() {
            return this.disabled
                ? 'grey'
                : (this.entity.hasSufficientResponses ?? true)
                    ? this.legend[this.entity.level].color
                    : 'grey-lighten-2';
        },
    }
};
</script>
