import Vue from "vue";
import Vuex from "vuex";

import i18n from "../i18n";

import { getEscalateAdmins } from "@/api/admin.api";
import { auth } from "./auth.module";
import { questionEditor } from "./questionEditor.module";
import { adhoc } from "./adhoc.module";
import { entities } from "./entities.module";
import { otp } from "./otp.module";


Vue.use(Vuex);


const stateTemplate = {
    anonym: false, //default anonym answer mode for a survey fill out (public by default)
    loggingOut: false, //styling variable to hide rest of the UI after user clicks "logout"
    snackBar: {
        show: false,
        type: null,
        duration: 1700,
        MIN_DURATION: 500,
        options: null,
    },
    navDrawer: true,
    supportedLangs: [],
    escalateAdmins: null,
    userSettings: null,
    topGrade: null,
    contactLinks: {
        de: "https://joineer.com/ueber-uns/#kontaktdaten",
        en: "https://joineer.com/kontakt-en/#kontaktdaten",
        fr: "https://joineer.com/kontakt-fr/#kontaktdaten",
        it: "https://joineer.com/kontakt-it/#kontaktdaten"
    },

};

export const store = new Vuex.Store({
    modules: {
        adhoc,
        auth,
        questionEditor,
        entities,
        otp
    },

    state: Object.assign({}, stateTemplate),

    mutations: {
        SET_ANONYM_MODE(state, mode) {
            state.anonym = mode;
        },
        SET_LOGOUT_MODE(state, mode) {
            state.loggingOut = mode;
        },
        CHANGE_USER_LANG(state, lang) {
            state.userSettings.userLanguage = lang;
        },
        SET_ESCALATE_ADMINS(state, adminData) {
            state.escalateAdmins = adminData;
        },
        SET_ROLE(state, role) {
            state.userRole = role;
        },
        SET_SETTINGS(state, settings) {
            state.userSettings = settings;
            state.supportedLangs = settings.languages;
            state.topGrade = settings.topGrade;

            if (settings.anonymDefault) {
                state.anonym = true
            }

        },
        changeSnackStatus(state, argOptions) {
            let optionsArray;

            // allow type as the only param
            optionsArray = typeof argOptions === 'string'
                ? [true, argOptions]
                : [...argOptions];

            // default to showing the message
            if (typeof optionsArray[0] !== 'boolean') {
                optionsArray.unshift(true);
            }

            // allow [type, msg], dont treat number strings as a msg
            if ((typeof optionsArray[2] === 'string')
                && (String(parseInt(optionsArray[2], 10)) !== optionsArray[2])) {

                optionsArray[3] = { msg: optionsArray[2] };
                optionsArray[2] = undefined;
            }

            // allow [type, duration, msg]
            if (typeof optionsArray[3] === 'string') {
                optionsArray[3] = { msg: optionsArray[3] };
            }

            let [newSnackStatus, newType, duration, options] = optionsArray;

            state.snackBar.show = newSnackStatus;
            state.snackBar.type = newType;
            state.snackBar.duration = duration;
            state.snackBar.options = options;
        },
        SET_NAV(state, newVal) {
            state.navDrawer = newVal;
        },
        TOGGLE_NAV(state) {
            state.navDrawer = !state.navDrawer;
        },
        RESET(state){
            Object.assign(state, stateTemplate)
        }
    },

    actions: {
        changeDefaultAnonym(context, mode){
             // when user changes the default anonymity toggle in the survey question
            console.log("setting survey question anonym mode to: " + mode)
            context.commit("SET_ANONYM_MODE", mode);

        },
        setLogoutMode(context, mode){
            context.commit("SET_LOGOUT_MODE", mode);

        },
        fetchEscalateAdmins(context) {
            // for escalate task/FF autocomplete
            getEscalateAdmins().then(response => {
                context.commit("SET_ESCALATE_ADMINS", response.data);
            });
        },
        reset(context) {
            context.commit("RESET");
            context.commit("entities/RESET_ENTITY_EXPAND");
            context.commit("questionEditor/RESET");
            context.commit("adhoc/RESET_ALL");
            context.commit("auth/logout");
        },
        changeUserLang(context, lang) {
            context.commit("CHANGE_USER_LANG", lang);
        },
        loadTeam(context, members) {
            context.commit("SET_TEAM", members);
        },
        showSnack(context, options, msg) {
            context.commit("changeSnackStatus", options);
        },

        setRole(context, role) {
            context.commit("SET_ROLE", role);
        },
        setSettings(context, settings) {
            context.commit("SET_SETTINGS", settings);
        }
    },

    getters: {
        surveyRunning(state) {
            return state.userSettings?.pulseSurveyRunning || false;
        },
        featureFlags(state) {
            return state.userSettings?.featureFlags || null;
        },
        checkFeature: (state) => (feature) => {
            return state.userSettings?.featureFlags.includes(feature) || null;
        },
        mandatoryLang(state) {
            return state.userSettings?.languages[0] || null;
        },
        contactLink(state){
            return state.contactLinks.hasOwnProperty(i18n.locale)
                ? state.contactLinks[i18n.locale]
                : state.contactLinks.en;
        },
    },

    strict: true
});
