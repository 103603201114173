<template>
   <v-data-table
        v-if="displayUsers"
        :headers="headers"
        :item-class="memberColor"
        :items="displayUsers"
        hide-default-footer
        :no-data-text="noDataText"
        :options="{ itemsPerPage: -1 }"
        mobile-breakpoint="0"
        :hide-default-header="!showHeader"
    >
        <template v-slot:item.displayName="{ item }">
            <div class="d-flex align-center">
                <v-avatar class="mr-2" v-if="$vuetify.breakpoint.mdAndUp" color="amber" size="30">
                    <span class="white--text" style="font-size: 1rem">
                        {{ item.initials }}
                    </span>
                </v-avatar>
                <div >
                    {{ item.displayName }}
                    <span
                        v-if="item.new"
                        class="text-caption green--text text-uppercase"
                        >({{ $t("buttons.new") }})</span
                    >
                </div>
            </div>
        </template>
        <template v-slot:item.delete="{ item }">
            <v-btn
                icon
                color="red darken-2"
                :title="(superAdmins || admins ) ? $t('removeAsAdmin') : $t('removeFromTeam')"
                @click="removeEmit(item)"
            >
                <v-icon>remove_circle_outline</v-icon>
            </v-btn>
        </template>
        <template v-slot:item.details="{ item }">
            <v-btn
                icon
                color="primary"
                small
                @click="handleNav(item.id)"
                :title="$t('seeDetails')"
            >

                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </template></v-data-table
    >
</template>

<script>
export default {
    props: ["users", "noDataText", "hideDelete", "hideNav", "superAdmins", "admins", "showHeader","members"],
    mounted() {
        this.displayUsers = this.users.map(user =>{
           let mappedUser = {...user}
           if(mappedUser.hasOwnProperty('userId')){
            mappedUser.id = mappedUser.userId
           }
             if(!mappedUser.hasOwnProperty('displayName')){
            mappedUser.displayName = mappedUser.firstName + " " + mappedUser.lastName
           }

           mappedUser.initials = this.initials(mappedUser)
           return mappedUser
        })
    },
    components: {},
    data: () => ({
        displayUsers: null,
    }),
    methods: {
             handleNav(id) {
            this.$router.push(`/profile/${id}`);
        },
        memberColor(item) {
            return item.new ? "greenBg" : "";
        },
        removeEmit(user){
            if(this.superAdmins){
                this.$emit('removeSuperadmin', user.id)
            } else if(this.admins){
                this.$emit('removeAdmin', user.id)
            }else{
                this.$emit('removeMember', user.id)
            }

        }
    },
    computed: {
        headers() {
            return [
                { text: this.$t("report.name"), value: "displayName" },
                { text: "", value: "delete", sortable: false },
                { text: "", value: "details", sortable: false },
            ];
        },
    },
};
</script>
