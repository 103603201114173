import Vue from 'vue';
import Vuetify from 'vuetify';
import colors from 'vuetify/lib/util/colors';
import 'vuetify/dist/vuetify.min.css';
import Diversity3Icon from '@/components/global/Diversity3Icon';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            diversity: { component: Diversity3Icon },
        },
    },
    theme: {
        cspNonce: '5Zzy2raSR_a5z-DoyR2XYhM_NBC-4enLW9Oxj7ycHhE',
        themes: {
            light: {
                primary: colors.blue.base, // blue
                //'lighten-6': '#e6f4ff', // light blue
                accentblue: "#d6efff",
                joineer: "#9c2d56", // joineer red
                secondary: "#757575",
                accent: "#2196F3",
                error: "#F44336",
                info: "#FF9800",
                success: "#8BC34A",
              },
        },
    },
});
