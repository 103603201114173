<template>
    <div id="app">
        <v-app>
            <div v-if="isImpersonating" class="impersonation-banner">
                You are currently impersonating another user.
                <v-btn @click="stopImpersonation" color="red" outlined>
                    Stop Impersonating
                </v-btn>
            </div>
            <Snackbar v-if="showSnack" />

            <div class="pa-12 mx-auto text-center" v-if="logoutMode">
                <v-progress-circular
                    indeterminate
                    size="30"
                    color="primary"
                    class="mx-auto mt-10"
                ></v-progress-circular>
                <div class="text-caption mt-7">{{ $t("loggingOut") }}</div>
            </div>

            <Navbar class="d-print-none" v-if="!logoutMode && topNav" />
            <v-main>
                <v-container class="noPaddingMobile" v-if="!logoutMode">
                    <v-slide-x-reverse-transition :hide-on-leave="true">
                        <router-view> </router-view>
                    </v-slide-x-reverse-transition>
                </v-container>
            </v-main>

            <v-btn
                class="d-print-none"
                v-scroll="onScroll"
                v-show="
                    fab &&
                    $route.name !== 'login' &&
                    $route.name !== 'select' &&
                    $route.name !== 'question' &&
                    !logoutMode
                "
                fab
                fixed
                bottom
                right
                @click="toTop"
            >
                <v-icon>keyboard_arrow_up</v-icon>
            </v-btn>

            <NavbarBottom class="d-print-none" v-if="!topNav && !logoutMode" />
        </v-app>
    </div>
</template>

<script lang="ts">
import { settings } from "@/api/settings.api.js";
import Navbar from "@/components/Navbar.vue";
import NavbarBottom from "@/components/NavbarBottom.vue";
import Snackbar from "@/components/Snackbar.vue";
import { routes } from "@/router/index.js";

import { Component, Vue, Watch } from "vue-property-decorator";
// @ts-ignore
@Component({
    components: {
        Navbar,
        NavbarBottom,
        Snackbar,
    },
})
export default class App extends Vue {
    created() {
        window.l("[App.vue]: in created, user, loggedIn:", this.user, this.loggedIn);

        const loginRoute = routes.find(route => route.name?.toLowerCase() === 'login');
        const isOnLoginUrl = loginRoute && location.hash.replace(/^#/, '').indexOf(loginRoute.path) === 0;

        if (this.user && this.loggedIn && !isOnLoginUrl) {
            return this.setSettings();
        }
    }

    mounted() {
        window.l('[App] in mounted');

        window.logout = () => { this.$store.dispatch("auth/logout"); }

        if (this.$route.meta?.hasOwnProperty("title")) {
            this.setDocumentTitle(this.$route.meta.title);
        }
    }

    fab: boolean = false;
    onScroll(e): void {
        if (typeof window === "undefined") return;
        const top = window.pageYOffset || e.target.scrollTop || 0;
        this.fab = top > 150;
    }

    toTop(): void {
        this.$vuetify.goTo(0);
    }

    setDocumentTitle(name): void {
        //translate the text in browser tab if possible
        //console.log("setting document title with meta title " + name)
        if (this.$te(name)) {
            document.title = this.$t(name) as string;
        } else {
            document.title = name;
        }
    }

    setSettings(): Promise<any> {
        window.l("[App.vue]: in setSettings");

        // set settings in Vuex
        return settings(this.user?.companyId, this.user?.userId).then((response) => {
            window.l("[App.vue]: in settings().then");

            const storeSetSettingsPromise = this.$store.dispatch("setSettings", response.data);
            window.l('this.$store.dispatch("setSettings", response.data)', storeSetSettingsPromise);

            let userSettings = response.data;
            if (this.user) {
                this.$store.dispatch(
                    "questionEditor/setQuestionIcons",
                    this.user.companyId
                );
            }

            const roles = this.$store.getters["auth/userRoles"];
            console.log("user roles: " + roles);
            let maxRole = "member";
            if (roles.includes("ROLE_ADMIN")) {
                maxRole = "teamAdmin";
            }
            if (roles.includes("ROLE_SUPERADMIN")) {
                maxRole = "companyAdmin";
            }
            //  log some important stats when running locally on dev mode
            if (this.$devMode) {
                console.group("Main details");
                console.log(
                    "%cCOMPANY ID",
                    "font-weight: bold",
                    this.user ? this.user.companyId : "NOT FOUND"
                );

                console.log(
                    "%cUSER ID",
                    "font-weight: bold",
                    this.user ? this.user.userId : "NOT FOUND"
                );

                console.log(
                    "%cSETTINGS",
                    "font-weight: bold",
                    JSON.stringify(userSettings)
                );
                console.groupEnd();
            }

            if (this.$devMode) {
                //staging PIWIK
                this.piwikAnalytics(['setCustomDimensionValue', 1,  this.user?.companyId || 'not logged in']);
            } else {
                // prod PIWIK
                this.piwikAnalytics(['setCustomDimensionValue', 4, this.user?.companyId || 'not logged in']);
            }

            window.l('original $i18n.locale:', this.$i18n.locale);

            let DMlangs = userSettings.languages;
            if (
                userSettings.userLanguage &&
                DMlangs.includes(userSettings.userLanguage)
            ) {
                this.$i18n.locale = userSettings.userLanguage;
                document.documentElement.lang = userSettings.userLanguage;
            } else if (DMlangs.includes(userSettings.languages[0])) {
                this.$i18n.locale = userSettings.languages[0];
                document.documentElement.lang = userSettings.languages[0];
            }

            window.l('used $i18n.locale:', this.$i18n.locale);

            if (this.$i18n.locale) {
                //set vue-moment locale
                this.$moment.locale(this.$i18n.locale);
            }

            return storeSetSettingsPromise;
        });
    }

    get showSnack(): boolean {
        return this.$store.state.snackBar.show;
    }

    get loggedIn(): boolean {
        window.l('in get loggedIn:', this.$store.state.auth.status.loggedIn);
        return this.$store.state.auth.status.loggedIn;
    }
    get user() {
        return this.$store.state.auth.user;
    }
    get logoutMode(): boolean {
        return this.$store.state.loggingOut;
    }

    get topNav() {
        //  hide top navbar & drawer menu in certain pages
        const hiddenPages = [
            "login",
            "login-success",
            "start",
            "question",
            "end",
            "intro",
            "gdpr",
            "error",
            "feature-error",
            "reset-password",
            "tb",
            "2fa"
        ];
        return !hiddenPages.includes(this.$route.name as string);
    }

    get isImpersonating(): boolean {
        var state = this.$store.getters["auth/isImpersonating"];
        window.l('isImpersonating:', state);
        return state;
    }

    stopImpersonation(): void {
        window.l('in stopImpersonation');
        this.$store.dispatch("auth/stopImpersonation");
    }

    @Watch("$i18n.locale")
    titleWatcher() {
        //update browser tab title whenever GUI language changes
        this.setDocumentTitle(this.$route.meta?.title);
    }

    @Watch("loggedIn")
    logInWatcher(val: string, oldVal: string) {
        window.l('in logInWatcher, val, oldVal:', val, oldVal);
    }

    @Watch("$route")
    routeWatcher(to, from) {
        if (to.meta.hasOwnProperty("title")) {
            this.setDocumentTitle(to.meta.title);
        }
    }
}
</script>
<style>

.impersonation-banner {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    max-width: 80%; /* Not full width */
    background-color: yellow;
    color: black;
    text-align: center;
    padding: 10px 20px;
    border-radius: 4px;
    z-index: 1000; /* Ensure it's above other elements */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add some shadow to lift the banner */
}

.bg-transparent {
        background-color: transparent !important;
    }

    .hide-for-preload {
        pointer-events: none;
        height: 1px;
        left: 0;
        opacity: 1%;
        overflow: hidden;
        position: absolute;
        top:0;
        width: 1px;
    }

    .v-select__selections input[readonly] {
        cursor: pointer;
    }
</style>
