<template>
    <v-container class="fill-height" style="position: relative">
        <v-row justify="center" align="center" v-if="showGreeting">
            <v-col>
                <div class="mt-12 text-h4 text-center">
                    {{ $t("hi")
                    }}<span v-if="currentUserName">, {{ currentUserName }}</span
                    >!
                </div>

                <v-img
                    class="pulse quick-fade-in d-flex mx-auto"
                    contain
                    :src="require('@/assets/images/surveyIcons/coffee/5.png')"
                    width="200"
                ></v-img>

                <v-progress-circular
                    class="d-flex mx-auto"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import User from "../models/user";
import { settings } from "@/api/settings.api";
import { getSurveys } from "@/api/takeSurvey";
import Configuration from "@/services/configuration";

export default {
    name: "Login-success",
    props: [""],
    components: {},
    data: () => ({
        user: new User("", ""),
        currentUserName: null,
        showGreeting: false,
        targetPath: null
    }),
    mounted() {
        this.tokenLogin();

        if (this.$route?.query?.target) {
            l("[LoginSuccess] existing target path: " + this.$route.query.target);

            this.targetPath = this.$route.query.target;
        }
    },
    methods: {
        tokenLogin() {
            l("in tokenLogin");

            this.$store
                .dispatch("auth/tokenLogin")
                .then(this.handleLoginResponse)
                .catch(error => {
                    le("[LoginFail]: tokenLogin not successful, routing back to /login");
                    le(error);
                    return this.$store.dispatch("auth/logout");
                });
        },

        handleLoginResponse(user) {
            l("in handleLoginResponse");
            // language can be null, hence fallback check via settings below as well
            if (user.language) {
                this.$i18n.locale = user.language;
            } else {
                l("[LoginSuccess]: tokenLogin, user.language:", user?.language);
            }

            this.currentUserName = user.firstName;
            this.showGreeting = true;

            // set settings in Vuex
            return this.setSettings(user)
                .then(this.setNextPath);
        },

        setSettings(user) {
            l("[LoginSuccess]: setSettings");

            // set settings in Vuex
            return settings(user?.companyId, user?.userId).then((response) => {
                l("[LoginSuccess]: setSettings.then");

                let userSettings = response.data;
                const storeSetSettingsPromise = this.$store.dispatch("setSettings", response.data);
                this.$store.dispatch(
                    "questionEditor/setQuestionIcons",
                    user.companyId
                );

                const roles = this.$store.getters["auth/userRoles"];
                console.log("user roles: " + roles);
                let maxRole = "member";
                if (roles.includes("ROLE_ADMIN")) {
                    maxRole = "teamAdmin";
                }
                if (roles.includes("ROLE_SUPERADMIN")) {
                    maxRole = "companyAdmin";
                }
                //  log some important stats when running locally on dev mode
                if (this.$devMode) {
                    console.group("Main details");
                    console.log(
                        "%cCOMPANY ID",
                        "font-weight: bold",
                        user.companyId
                    );

                    console.log(
                        "%cUSER ID",
                        "font-weight: bold",
                        user ? user.userId : "NOT FOUND"
                    );

                    console.log(
                        "%cSETTINGS",
                        "font-weight: bold",
                        s(userSettings)
                    );
                    console.groupEnd();
                }

                if (this.$devMode) {
                    //staging PIWIK
                    this.piwikAnalytics(['setCustomDimensionValue', 1,  user.companyId]);
                } else {
                    // prod PIWIK
                    this.piwikAnalytics(['setCustomDimensionValue', 4, user.companyId]);
                }

                window.l('original $i18n.locale:', this.$i18n.locale);

                let userLanguage = (
                           userSettings.userLanguage
                        && userSettings.languages.includes(userSettings.userLanguage)
                    )
                        ? userSettings.userLanguage
                        : userSettings.languages[0];

                if (userLanguage && this.$i18n.locale != userLanguage) {
                    this.$i18n.locale = userLanguage;
                }

                if (userLanguage && document.documentElement.lang != userLanguage) {
                    document.documentElement.lang = userLanguage;
                }

                window.l('used $i18n.locale:', this.$i18n.locale);

                if (this.$i18n.locale) {
                    //set vue-moment locale
                    this.$moment.locale(this.$i18n.locale);
                }

                return storeSetSettingsPromise;
            });
        },

        setNextPath() {
            l("in setNextPath");

            let userSetPromise = new Promise((success, error) => {
                l('in userSetPromise');

                try {
                    if (JSON.parse(localStorage.getItem("user"))) {
                        l('in userSetPromise, had user');
                        success(true);

                        return;
                    }

                    l('in userSetPromise, NO user yet');

                } catch (ex) { }

                jvm.$once('localStorage:setItem:user', (value) => {
                    l('in userSetPromise, $once; value:', value);

                    if (value) {
                        success(value);
                    }
                });
            })

            return Promise.allSettled([ getSurveys(), userSetPromise ])
                .then(([ response, userSet ]) => {
                    if (response.status !== 'fulfilled') {
                        throw response.reason;
                    }

                    if (userSet.status !== 'fulfilled') {
                        throw userSet.reason;
                    }

                    let activeSurveyFlag = response.value.data.length > 0;
                    let nextPath;

                    if (activeSurveyFlag) {
                        window.sessionStorage.setItem('hasActiveSurvey', 'true');
                    }

                    // TODO: review if this is needed or if the routerSecurity guards are enough once Meteor is removed
                    let user = JSON.parse(localStorage.getItem("user"));
                    if (user.showIntro) {
                        nextPath = `/intro${this.targetSuffix}`;
                    }
                    else if (!user.gdprAccepted) {
                        nextPath = `/gdpr${this.targetSuffix}`;
                    }
                    else if (activeSurveyFlag) {
                        nextPath = "/survey/start";
                        window.sessionStorage.removeItem('hasActiveSurvey');
                    }
                    else {
                        //no active surveys, intro / GDPR flags -> go to either targetPath or TB dashboard
                        l(`[LoginSuccess] setNextPath: no pre-redirect`);

                        nextPath = this.targetPath || "/tb";
                    }

                    l("[LoginSuccess]: setting next path to " + nextPath);
                    return this.goNext(nextPath);
                })
                .catch(error => {
                    le("[LoginStatus]: can't retrieve surveys to calculate next path, falling back to TB");
                    le(error);

                    return this.goNext("/tb");
                });
        },

        goNext(path) {
            this.showGreeting = false;
            return this.$router.push(path);
        }
    },
    computed: {
        targetSuffix() {
            return this.targetPath ? `?target=${this.targetPath}` : "";
        }
    }
};
</script>
