<template>
    <v-container v-if="stats" class="fade-in">
        <div class="text-center text-h4 font-weight-light py-6">
            {{ $t("completion.title") }}
        </div>

        <!-- not sure if nr of survey questions gives value -->
        <!-- <div class="statCircle" style="background-color: #FF9E9E">
         {{surveyData.length}}
    <div class="font-weight-light pa-2">QUESTIONS DISCUSSED</div> </div>-->

        <v-row justify="center">
            <v-col cols="12" sm="10" md="5">
                <v-row justify="center">
                    <!-- item -->
                    <v-col align="center">
                        <div class="align-center">
                            <div
                                class="statCircle"
                                style="background-color: #71D6C4"
                            >
                                {{ stats.totalTasks }}
                            </div>
                            <div class="font-weight-light pa-2 text-uppercase">
                                {{ $t("completion.tasksCreated") }}
                            </div>
                        </div>
                    </v-col>
                    <!-- /item -->

                    <!-- item -->
                    <v-col align="center">
                        <div class="align-center">
                            <div
                                class="statCircle"
                                style="background-color: #86C6EB"
                            >
                                {{ stats.closedFeedForwards }}
                            </div>
                            <div class="font-weight-light pa-2 text-uppercase">
                                {{ $t("completion.ffDiscussed") }}
                            </div>
                        </div>
                    </v-col>
                    <!-- /item -->
                </v-row>
            </v-col>
        </v-row>

        <!-- sun-->
        <div align="center" class="bounce-in">
            <v-img
                contain
                :src="require('../assets/images/sun.png')"
                style="max-width:180px"
            ></v-img>
        </div>
        <!-- /sun -->

        <v-row justify="center">
            <v-btn
                @click="goToTaskboard"
                x-large
                color="primary"
                class="mt-5"
                >{{ $t("completion.go") }}</v-btn
            >
        </v-row>
    </v-container>
</template>


<script>
import { getStatistics } from "../api/discussion.api";
export default {
    components: {},
    data: () => ({
        stats: null
    }),
    mounted() {
        getStatistics(this.$route.params.discussion_id).then(
            response => (this.stats = response.data)
        );
    },
    methods: {
        goToTaskboard() {
            this.$store.commit("SET_NAV", true);
            this.$router.push({ name: "taskboard" });
        }
    }
};
</script>

<style>
.statCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight: 800;
}
</style>
