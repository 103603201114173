<template>
    <div>
        <v-card outlined class="my-1">
            <div class="d-flex align-center">

                    <v-textarea
                        v-model.trim="text"
                        class="body-2"
                        solo
                        auto-grow
                        hide-details
                        flat
                        rows="2"
                        name="comment"
                        :placeholder="`${$t('tooltips.addComment')}...`"
                    ></v-textarea>



                    <v-tooltip bottom color="grey lighten-3" open-delay="1000">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                :loading="loading"
                                :disabled="loading || !text"
                                v-on="on"
                                :aria-label="$t('tooltips.addComment')"
                                @click="makeComment"
                                depressed
                                color="primary"
                                class="mx-1 makeCommentBtn"
                            >
                             {{ $t('buttons.save') }}

                            </v-btn>
                        </template>
                        <span class="grey--text text--darken-2">{{
                            $t("tooltips.addComment")
                        }}</span>
                    </v-tooltip>

            </div>
        </v-card>
        <v-alert v-if="errorMessage" dense outlined type="error" text>{{
            errorMessage
        }}</v-alert>
    </div>
</template>
<script>
import { addComment } from "../api/tasks.api";
export default {
    props: ["task"],
    data: () => ({
        text: "",
        loading: false,
        errorMessage: null
    }),
    methods: {
        makeComment() {
            if (this.text) {
            this.loading = true;
            this.errorMessage = null;
                let newComment = {
                    comment: this.text
                };
                addComment(this.task.id, newComment)
                    .then(response => {
                        this.piwikAnalytics(['trackEvent','Task actions', 'Comment added'])
                        this.task.comments.push(response.data);
                        this.text = "";
                    })
                    .catch(error => {
                        this.errorMessage = this.parseError(error);
                    })
                    .finally(() => this.loading = false);
            }
        }
    },
    computed: {}
};
</script>

<style>
.sendComment {
    transform: rotate(-20deg);
}
</style>
