export const adhoc = {
    namespaced: true,
    state: {
        members: [], //detailed list of members for GUI
        survey: {
            startDate: null,
            endDate: null,
            title: null,
            participants: [], //just ids
            questions: []
        },

    },
    mutations: {
        ADD_QUESTION(state, question) {
            state.survey.questions.push(question);
        },

        DELETE_QUESTION(state, id) {
            state.survey.questions = state.survey.questions.filter(
                question => question.id !== id
            );
        },
        REORDER_QUESTIONS(state, list) {
            state.survey.questions = list
        },
        SET_DETAILS(state, details) {
            state.survey.startDate = details.startDate;
            state.survey.endDate = details.endDate;
            state.survey.title = details.title;
        },
        SET_MEMBERS(state, members) {
            state.members = members; //set array with names for GUI purposes
            state.survey.participants = members.map(member => member.id) //set array with just ids for API
        },
        RESET_ALL(state){
            state.members = [];
            state.survey =  {
                startDate: null,
                endDate: null,
                title: null,
                participants:[],
                questions:[]

        }}

    },
    getters: {

    },
    actions: {

    }
};
