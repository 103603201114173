<template>
    <!-- Team tree for moving the user to another level 0 entity  -->
    <v-container v-if="teamMenu">
        <v-treeview
            :selection-type="'independent'"
            hoverable
            activatable
            return-object
            :items="teamMenu"
            class="hover-pointer"
        >
            <template
                v-slot:prepend="{ item }"
                v-if="$vuetify.breakpoint.smAndUp"
            >
            <EntityIcon :entity="item"/>
            </template>
            <template v-slot:label="{ item, active }">
                <span class="black--text">{{ item.name }} </span>
                <span
                    class="grey--text text-caption"
                    v-if="item.id === currentTeamId"
                >
                  ({{ $t('currentTeam') }})

                </span>
                <v-btn
                    v-if="active"
                    :loading="loading"
                    :ripple="false"
                    @click.stop="moveHere(item)"
                    color="primary"
                    class="ml-3"
                    >{{ $t("moveHere") }}
                </v-btn>
            </template>
        </v-treeview>
    </v-container>
</template>

<script>
import { generateTeamTree } from "@/utils/entityListCalc.js";
export default {
    props: ["teams", "currentTeamId", "loading"],
    mounted() {
        this.initialize();
    },
    data: () => ({
        teamMenu: null,
        selection: null
    }),

    methods: {
        initialize() {
            this.teamMenu = generateTeamTree(this.teams);
            this.setDisabledState(this.teamMenu);
        },
        moveHere(entity) {
            this.piwikAnalytics(['trackEvent','Entity management actions', 'Moved teams via Profile']);
            this.$emit("entityChosen", { name: entity.name, id: entity.id });
        },

        setDisabledState(teamMenu) {
            teamMenu.forEach((team) => {
                this.$set(
                    team,
                    "disabled",
                    team.level > 0 || team.id === this.currentTeamId
                );

                if (team.subEntityIds.length > 0) {
                    this.setDisabledState(team.children);
                }
            });
        },
    },
    computed: {},
    watch: {},
};
</script>

<style></style>
