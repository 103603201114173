import { getCompanyIcons, standardIcons} from "@/utils/companyIcons.js"
import { MAX_NORMAL_STRUCTURE_LEVEL }  from '@/components/global/EntityIcon.data.js';

export const questionEditor = {
    namespaced: true,
    state: {
        teams: [],
        allTeamIds: null,
        selectedTeamId: null, //-1 stands for company level
        existingQuestions: [],
        categories: null,
        avatars: standardIcons,
    },
    mutations: {
        LOAD_CATEGORIES(state, categories) {
            state.categories = categories.sort((a, b) => (Object.prototype.hasOwnProperty.call(a, 'defaultSet') ? 1 : -1))
        },
        //checks whether there are company specific icons and adds them to our usual icons
        SET_SPECIAL_ICONS(state,companyId){
            let specialIcons = getCompanyIcons(companyId)
            if(specialIcons){
            console.log("Adding company specific icons: " + JSON.stringify(specialIcons))
            state.avatars = specialIcons.concat(state.avatars);
        }

        },
        // TODO: set teams in main Vuex
        SET_TEAMS(state, list) {
            state.teams = list;
            state.allTeamIds = list.map(team => team.id);
        },
        SET_EXISTING_QUESTIONS(state, list) {
            // state.existingQuestionIds= list
            state.existingQuestions = list.map(
                question => question.questionText
            );
        },
        SET_SELECTED_TEAM(state, id) {
            state.selectedTeamId = id;
        },
        RESET(state) {
            state.teams = [];
            state.allTeamIds = null;
            state.selectedTeamId = null; //-1 stands for company level
            state.existingQuestions =  [];
            state.categories =  null;
            state.avatars = standardIcons;
        },
    },
    getters: {
        isCompanyView(state) {
            // returns true if the user currently browses company questions
            return state.selectedTeamId === "-1";
        },
        highestTeamLevel(state) {
            let highestLevel = 0;
            state?.teams.forEach(team => {
                if (team.level > highestLevel && team.level <= MAX_NORMAL_STRUCTURE_LEVEL) {
                    highestLevel = team.level;
                }
            });
            return highestLevel;
        }
    },
    actions: {
        setQuestionIcons(context, companyId){
            context.commit("SET_SPECIAL_ICONS", companyId);
        },
        loadCategories(context, categories) {
            context.commit("LOAD_CATEGORIES", categories);
        },
        setTeams(context, list) {
            context.commit("SET_TEAMS", list);
        },
        setExistingQuestions(context, list) {
            context.commit("SET_EXISTING_QUESTIONS", list);
        },
        setTeamId(context, id) {
            context.commit("SET_SELECTED_TEAM", id);
        },

    }
};
