<template>
    <v-dialog v-model="dialogState" max-width="400">
        <v-card>
            <v-toolbar flat color="grey lighten-5">
                <v-toolbar-title>{{ $t('updateLanguage') }}
                </v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn @click="$emit('dialog', false)" icon
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text>
                <v-select :items="supportedLangs" v-model="newLang" class="my-3">
                    <template v-slot:item="{ item }">
                        <Flag :locale="item" class="langFlag" />
                        {{ item.toUpperCase() }}
                    </template>
                    <template v-slot:selection="{ item }">
                        <Flag :locale="item" class="langFlag" />
                        {{ item.toUpperCase() }}
                    </template>
                </v-select>

                <v-alert v-if="error" type="error" outlined text>
                    {{ error }}
                </v-alert>
                <v-btn :disabled="loading" large @click="updateLang" color="primary"
                    >{{ $t("buttons.save") }}
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { updateUserDetails } from "@/api/users.api";
import Flag from "@/components/QuestionEditor/Flag.vue";

export default {
    props: ["user", "dialog", "isMyProfile"],
    components: { Flag },
    data: () => ({
        newLang: null,
        loading: false,
        error: null,
    }),
    mounted() {
        this.setFields();
    },
    methods: {
        updateLang() {
            this.piwikAnalytics(['trackEvent','User management actions', 'Updated language via Profile']);
            this.error = null;
            this.loading = true;
            let updatedUser = { ...this.user };
            updatedUser.language = this.newLang;

            updateUserDetails(this.user.id, updatedUser)
                .then((resp) => {
                    this.$store.commit("auth/updateLanguage", resp?.data?.language)
                    this.$store.dispatch("showSnack", [true,"detailsUpdated"]);
                    this.$emit("dialog", false);
                    this.$emit("refresh");
                })
                .catch((error) => {
                    this.error = this.parseError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setFields() {
            this.error = null;

            if (this.user.language) {
                this.newLang = this.user.language
            } else {
                this.newLang = null;
            }
        },
    },
    computed: {
        dialogState: {
            get() {
                return this.dialog;
            },
            set(val) {
                this.$emit("dialog", val);
            },
        },
        supportedLangs() {
            return this.$store.state.supportedLangs;
        },
    },
    watch: {
        dialog(val) {
            if (val) {
                this.setFields();
            }
        },
    },
};
</script>

<style scoped>
.langFlag {
    margin-right: 6px;
    margin-top: -2px;
}
</style>
