import Vue from 'vue'
import VueI18n from 'vue-i18n'

//window.l('VueI18n', VueI18n);

Vue.use(VueI18n)

// use icons within translation
// "greeting": "Hello {0} world!"

// <i18n path="greeting">
// <v-icon>mdi-earth</v-icon>
// </i18n>

function loadLocaleMessages () {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.js$/i)
    const messages = {}

    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)

      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key).default
      }
    })

    return messages
  }

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: false
})
