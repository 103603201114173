import { httpClient, cachedHttpClient} from "./httpClient";

const getTeamMembers = team_id =>
    httpClient.get(`teams/${team_id}/members`);

const getTeams = () => httpClient.get(`/teams`);

// Get the current entities
const getEntities = () => httpClient.get(`/entities`);

// Get the detail view of the current entity
const getEntityDetails = entity_id => httpClient.get(`/entities/${entity_id}`);

// Update entity payload:
// {
//   "name": "string",
//   "level": 0
// }
const updateEntity = (entity_id, entity_obj) => httpClient.put(`/entities/${entity_id}`, entity_obj);

const deleteEntity = entity_id => httpClient.delete(`/entities/${entity_id}`);


/*
Create entity payload:
{
    "name": "string",
    "level": 0,
    "parentId": "string" (null if not be nested under anything)
  } */
const createEntity = entity_obj => httpClient.post(`/entities`, entity_obj);

// remove entity from its parent and place it directly under company
const detachEntity = (parent_id, entity_id) => httpClient.delete(`/entities/${parent_id}/entities/${entity_id}`);

// move entity from its parent and place it under another entity. parent entity lvl must be bigger
const moveEntity = (parent_id, entity_id) => httpClient.put(`/entities/${parent_id}/entities/${entity_id}`);

// get all entities state to the time of the survey
const getSurveyEntities = survey_id => cachedHttpClient.get(`/surveys/${survey_id}/entities`);
const getSurveyEntitiesWithUserTeam = survey_id => cachedHttpClient.get(`/surveys/${survey_id}/entities?includeUserTeam=true`);
const getSurveyEntityDetails = (survey_id, entity_id) => cachedHttpClient.get(`/surveys/${survey_id}/entities/${entity_id}`);


// remove admin from entity
const removeAdmin = (user_id, entity_id) => httpClient.delete(`/entities/${entity_id}/admins/${user_id}`);

// add existing member as admin to entity
const addAdmin = (user_id, entity_id) => httpClient.put(`/entities/${entity_id}/admins/${user_id}`);

// remove member from entity
const removeMember = (user_id, entity_id) => httpClient.delete(`/entities/${entity_id}/members/${user_id}`);

// add existing member to entity
const addMember= (user_id, entity_id) => httpClient.put(`/entities/${entity_id}/members/${user_id}`);


export {
    getTeamMembers,
    getTeams,
    getEntities,
    getEntityDetails,
    updateEntity,
    getSurveyEntities,
    getSurveyEntitiesWithUserTeam,
    deleteEntity,
    createEntity,
    removeAdmin,
    addAdmin,
    removeMember,
    addMember,
    detachEntity,
    moveEntity,
    getSurveyEntityDetails
};
