import Vue from "vue";
import VueRouter from "vue-router";
import Discussion from "../views/Discussion";
import ProgressTracker from "../components/ProgressTracker";
import Completion from "../components/Completion";
import SelectSurvey from "../views/SelectSurvey";
import routerSecurity from "./routerSecurity.js";
import LoginSuccess from "../views/LoginSuccess";
import Profile from "../views/Profile";


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    } else {
        return originalPush.call(this, location).catch((err) => {
            if (VueRouter.isNavigationFailure(err)) {
                l('[router redirect detected - warning:]', err.message);
                return err;
            } else {
                return Promise.reject(err);
            }
        });
    }
};


Vue.use(VueRouter);

export const routes = [
    {
        path: "/login",
        name: "login",
        // component: require('../views/Login.vue').default,
        component: () => import("../views/Login.vue"),
        meta: {
            title: "Log in",
            authRequired: false,
            roles: null,
            featureFlags: null,
        },
    },
    {
        path: "/tb",
        name: "tb",
        component: () => import("../views/TB.vue"),
        meta: {
            authRequired: true,
        },
    },
    {
        path: "/team-tasks",
        name: "taskboard",
        component: () => import("../views/Taskboard.vue"),
        meta: {
            title: "nav.teamTasks",
            authRequired: true,
            roles: null,
            featureFlags: ["DISCUSSION_MODE"],
        },
    },
    {
        path: "/task/:task_id",
        name: "task",
        component: () => import("../views/TaskPage.vue"),
        meta: {
            title: "tasks.taskPage",
            authRequired: true,
            roles: null,
            featureFlags: ["DISCUSSION_MODE"],
        },
    },
    {
        path: "/members",
        name: "members",
        component: () => import("../views/Members.vue"),
        meta: {
            title: "nav.members",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
            featureFlags: null,
        },
    },
    {
        path: "/team",
        name: "team",
        component: () => import("../views/Team.vue"),
        meta: {
            title: "nav.structure",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
            featureFlags: null,
        },
    },
    {
        path: "/team/:id",
        name: "team-detail",
        component: () => import("../views/TeamDetail.vue"),
        meta: {
            title: "teamDetails",
            roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
            featureFlags: null,
            authRequired: true,
        },
    },
    {
        name: "select",
        path: "/discussion",
        component: SelectSurvey,
        meta: {
            title: "nav.discussion",
            authRequired: true,
            roles: null,
            featureFlags: ["DISCUSSION_MODE"],
        },
    },
    {
        name: "profile",
        path: "/profile/:user_id",
        component: Profile,
        meta: {
            title: "nav.profile",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
            featureFlags: null,
        },
    },
    {
        path: "/discussion/:discussion_id",
        component: Discussion,
        meta: {
            title: "nav.discussion",
            authRequired: true,
            roles: null,
            featureFlags: ["DISCUSSION_MODE"],
        },
        children: [
            {
                path: "",
                component: ProgressTracker,
                meta: {
                    title: "nav.discussion",
                    authRequired: true,
                    roles: null,
                    featureFlags: ["DISCUSSION_MODE"],
                },
            },
            {
                path: "completion",
                name: "completion",
                component: Completion,
                meta: {
                    title: "nav.discussion",
                    authRequired: true,
                    roles: null,
                    featureFlags: ["DISCUSSION_MODE"],
                },
            },
        ],
    },
    {
        path: "/delegated-tasks",
        name: "admin",
        component: () => import("../views/AdminList.vue"),
        meta: {
            title: "nav.delegatedTasks",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN"],
            featureFlags: null,
        },
    },
    {
        path: "/reports",
        name: "reports",
        component: () => import("../views/Reports.vue"),
        meta: {
            title: "nav.reports",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
            featureFlags: null,
        },
    },
    {
        path: "/feedforwards",
        name: "feedforwards",
        component: () => import("../views/FeedForwards.vue"),
        meta: {
            title: "nav.feedForwards",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
            featureFlags: ["AI_SUMMARIZATION_ENABLED"],
        },
    },
    {
        path: "/feedforwardanalysis",
        name: "feedforward analysis",
        component: () => import("../views/FeedForwardAnalysis.vue"),
        meta: {
            title: "nav.ffanalysis",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
            featureFlags: null,
        },
    },
    {
        path: "/adhoc-survey",
        name: "adhoc",
        component: () => import("../views/Adhoc/Adhoc.vue"),
        meta: {
            title: "nav.adhoc",
            authRequired: true,
            roles: null,
            featureFlags: ["ADHOC_SURVEY"],
        },
    },
    {
        path: "/adhoc-survey/:surveyId",
        name: "adhoc-dashboard",
        component: () => import("../views/Adhoc/AdhocDashboard.vue"),
        meta: {
            title: "dashboard.surveyResults",
            authRequired: true,
            roles: null,
            featureFlags: ["ADHOC_SURVEY"],
        },
    },
    {
        path: "/adhoc-success",
        name: "adhoc-success",
        component: () => import("../views/Adhoc/AdhocSuccess.vue"),
        meta: {
            title: "nav.adhoc",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
            featureFlags: ["ADHOC_SURVEY"],
        },
    },
    {
        path: "/adhoc-create",
        name: "adhoc-create",
        component: () => import("../views/Adhoc/AdhocCreate.vue"),
        meta: {
            title: "adhoc.description1",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
            featureFlags: ["ADHOC_SURVEY"],
        },
    },
    {
        path: "/question-editor",
        name: "question-editor",
        component: () => import("../views/QuestionEditor.vue"),
        meta: {
            title: "nav.questions",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
            featureFlags: null,
        },
    },
    {
        path: "/email-templates/:companyId",
        name: "email-templates",
        component: () => import("../views/Emails/CompanyTemplates.vue"),
        meta: {
            title: "nav.emailTemplates",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN",],
            featureFlags: ["TEMPLATE_EDITOR_ENABLED"],
        },
    },
    {
        path: "/survey/start",
        name: "start",
        component: () => import("../views/Survey/SurveyStart.vue"),
        meta: {
            title: "startSurvey",
            authRequired: true,
            roles: null,
            featureFlags: null,
        },
    },
    {
        path: "/survey/question/:surveyId/:index",
        name: "question",
        component: () => import("../views/Survey/SurveyQuestion.vue"),
        meta: {
            title: "questionEditor.survey",
            authRequired: true,
            roles: null,
            featureFlags: null,
        },
    },

    {
        path: "/survey/end/:surveyId",
        name: "end",
        component: () => import("../views/Survey/SurveyEnd.vue"),
        meta: {
            title: "endSurvey",
            authRequired: true,
            roles: null,
            featureFlags: null,
        },
    },
    {
        path: "/intro",
        name: "intro",
        component: () => import("../views/Intro.vue"),
        meta: {
            title: "Intro",
            authRequired: true,
            roles: null,
            featureFlags: null,
        },
    },
    {
        path: "/gdpr",
        name: "gdpr",
        component: () => import("../views/GDPR.vue"),
        meta: {
            title: "GDPR",
            authRequired: true,
            roles: null,
            featureFlags: null,
        },
    },
    {
        path: "/login-success",
        name: "login-success",
        component: LoginSuccess,
        meta: {
            authRequired: false,
            title: "Log in",
            roles: null,
            featureFlags: null,
        },
    },
    {
        path: "/404",
        name: "error",
        component: () => import("../views/Error.vue"),
        meta: {
            title: "Error",
            authRequired: false,
            roles: null,
            featureFlags: null,
        },
    },
    {
        path: "/voting",
        name: "voting",
        component: () => import("../views/Voting.vue"),
        meta: {
            title: "Vote Feedforwards",
            authRequired: true,
            roles: null,
            featureFlags: null,
        },
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("../views/ResetPassword.vue"),
        meta: {
            title: "resetPw",
            authRequired: false,
            roles: null,
            featureFlags: null,
        },
    },
    {
        path: "/calendar",
        name: "calendar",
        component: () => import("../views/Calendar.vue"),
        meta: {
            title: "nav.calendar",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN"],
            featureFlags: null,
        },
    },
    {
        path: "/qr",
        name: "QR",
        component: () => import("../views/QR.vue"),
        meta: {
            title: "nav.qrCodes",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN"],
            featureFlags: null,
        },
    },
    {
        path: "/masterfile",
        name: "masterfile",
        component: () => import("../views/Masterfile.vue"),
        meta: {
            title: "Masterfile",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN"],
            featureFlags: null,
        },
    },
    {
        path: "/processdatafile",
        name: "processdatafile",
        component: () => import("../views/ProcessDataFile.vue"),
        meta: {
            title: "ProcessData",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN"],
            featureFlags: null,
        },
    },
    {
        path: "/my-profile",
        name: "myprofile",
        component: () => import("../views/MyProfile.vue"),
        meta: {
            title: "myProfile",
            authRequired: true,
            roles: null,
            featureFlags: null,
        },
    },
    {
        path: "/companyadmins",
        name: "companyadmins",
        component: () => import("../views/CompanyAdmins.vue"),
        meta: {
            title: "nav.companyAdmins",
            authRequired: true,
            roles: ["ROLE_SUPERADMIN"],
            featureFlags: null,
        },
    },

    {
        path: "/tfa",
        name: "2fa",
        component: () => import("../views/TFAAuthentication.vue"),
        meta: {
            title: "nav.tfa",
            authRequired: true,
            roles: null,
            featureFlags: null,
        },
    },

    {
        path: "/",
        name: "home",
        component: () => import("../views/TagDashboard.vue"),
        meta: {
            title: "nav.dashboard",
            authRequired: true,
            roles: ["ROLE_ROOT", "ROLE_SUPERADMIN", "ROLE_ADMIN", "ROLE_MEMBER"],
            featureFlags: null,
        },
    },

    // { path: "**", redirect: { name: "error" } }
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    return Promise
        .resolve(routerSecurity(to, from))
        .then(next)
        .catch((error)=> {
            le('beforeEach error:', error);
            next(false);
        });
});

export default router;
