import dotenv from 'dotenv'
dotenv.config()

export default class Configuration {
    static wasConfigLogged = false

    static _config = {
        authUrl: '$VUE_APP_AUTH_BASE_URL',
        backendUrl: '$VUE_APP_API_BASE_URL',
        loginUrl: '$VUE_APP_DM_LOGIN_URL'
    }

    static get CONFIG () {
        this.wasConfigLogged || ((this.wasConfigLogged = true) && window.l('CONFIG:', Configuration.CONFIG))
        return this._config;
    }

    static booleanValue(name) {
       let value = this.value(name) === 'true';
       return value;
    }

    static value (name) {
        if (!(name in this.CONFIG)) {
            window.le(`Configuration: There is no key named "${name}"`)
            return
        }

        let value = this.CONFIG[name]

        if (value.startsWith('$VUE_APP_')) {
            // value was not replaced, it seems we are in development.
            // Remove $ and get current value from process.env
            const envName = value.substr(1)
            const envVars = process.env

            if (!(envName in envVars)) {
                window.le(`Configuration: "${envName}" (used for config "${name})" is not defined in the ENV vars`)
                return
            }

            value = envVars[envName]
        }

        return value
    }
}
