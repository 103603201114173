<template>
    <v-dialog
        class="modalContainer"
        :content-class="'sizeMax ' + (fullscreen ? '' : 'display-flex') "
        :fullscreen="fullscreen"
        :persistent="true"
        :height="height"
        v-model="isVideoDialogVisible"
        :width="width"
    >
        <v-card>
            <v-card-title
                    v-if="fullscreen">
                <v-btn
                    class="ma-1"
                    @click="closeDialog()"
                    icon
                    large
                    ><v-icon>mdi-close-circle</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="video-panel">
                <div
                    v-if="isPlayOverlayVisible"
                    class="fullSizeOverlay"
                    @click="play(); isPlayOverlayVisible = false"
                >
                    <div class="d-flex fullSizeOverlayInner">
                        <v-icon class="margin-auto" size="25vmin">mdi-play</v-icon>
                    </div>
                </div>
                <video
                    :autoplay="(isVideoDialogVisible && autoplay) ? 'autoplay' : null"
                    class="pt-3 object-fit_contain sizeMax"
                    controls
                    disablepictureinpicture
                    :id="videoElementId"
                    ref="videoPlayerEl"
                    :src="url"
                    type="video/mp4"
                    v-on:play="onplay"
                    v-on:pause="onpause"
                    v-on:ended="onend"
                ></video>
            </v-card-text>

            <v-btn
                v-if="!fullscreen"
                class="ml-6 primary"
                @click="closeDialog()"
                large
                >{{ $t('videoModal.gotIt') }}</v-icon>
            </v-btn>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ModalVideo',
    props: ['isVideoDialogVisible', 'autoplay','url', ],
    props: {
        autoplay: {
            type: Number,
            default: 0,
        },
        isVideoDialogVisible: {
            type: Boolean,
            required: true,
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number,
            default: null,
        },
        width: {
            type: Number,
            default: null,
        },
        url: {
            type: String,
            required: true,
        },
    },
    components: {},
    data: () => ({
        isPlayOverlayVisible: false,
        videoElementId: null,
        wasPlaying: false,
    }),

    created() {
        this.isPlayOverlayVisible = this.autoplay;
    },

    mounted() {
        this.videoElementId = 'video_' + new Date().valueOf() + Math.random().toString().substr(2);
        if (this.isPlayOverlayVisible) {

            const handleOverlay = () => {
                if (this.$refs.videoPlayerEl && this.$refs.videoPlayerEl.paused) {
                    this.$refs.videoPlayerEl.addEventListener('play', () => { this.isPlayOverlayVisible = false; }, { once: true });
                } else {
                    this.isPlayOverlayVisible = false;
                }
            };

            if (this.$refs.videoPlayerEl) {
                handleOverlay();
            } else {
                this.$nextTick(handleOverlay);
            }
        }
    },

    methods: {
        closeDialog() {
            this.$emit('update:isVideoDialogVisible', false);
            this.$refs.videoPlayerEl && this.$refs.videoPlayerEl.pause();

            this.piwikAnalytics([
                'trackEvent',
                'Video',
                'player closed manually',
                this.url,
            ]);
        },
        play() {
            l('play starting');

            this.$refs.videoPlayerEl.paused && this.$refs.videoPlayerEl.play();
        },
        onplay() {
            l('play: ', this.videoElementId);

            this.piwikAnalytics([
                'trackEvent',
                'Video',
                `play ${this.wasPlaying ? 'resumed' : 'started'}`,
                this.url,
            ]);

            this.wasPlaying = true;
        },
        onpause() {
            l('pause: ', this.videoElementId);

            this.piwikAnalytics([
                'trackEvent',
                'Video',
                'play paused',
                this.url,
                `${this.$refs.videoPlayerEl?.currentTime} / ${this.$refs.videoPlayerEl?.duration}`,
            ]);
        },
        onend() {
            l('end: ', this.videoElementId);

            this.piwikAnalytics([
                'trackEvent',
                'Video',
                'play ended',
                this.url,
            ]);

            this.$emit('udate:onend', true);
        },
    },
    computed: {

    },
};
</script>

<style>
.display-flex {
    display: flex;
}
.sizeMax {
    height: 100%;
    width: 100%;
}
</style>
<style scoped>
.fullSizeOverlay {
    background-color: lightgray;
    font-size: 15em !important;
    height: 100%;
    left: 0;
    opacity: .4;
    position: absolute !important;
    top: 0;
    width: 100%;
    z-index: 99999;
}
.fullSizeOverlayInner {
    height: 100%;
}
.margin-auto {
    margin: auto;
}
.modalContainer {
    z-index: 9999;
}
.object-fit_contain {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}
.video-panel {
    height: calc(100% - 56px);
    width: 100%;
    position: relative;
}
</style>
