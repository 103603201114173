export const ENTITY_LEVEL_TEAM = 0;
export const ENTITY_LEVEL_COMPANY = 100;
export const ENTITY_LEVEL_COMPANY_LOW = -1;
export const ENTITY_LEVEL_NEW_ITEM = 101;

export const MAX_NORMAL_STRUCTURE_LEVEL = ENTITY_LEVEL_COMPANY - 1;

const entityIconLegend = {
        [ENTITY_LEVEL_TEAM]: { icon: 'mdi-account-group', color: 'primary' },
     [ENTITY_LEVEL_COMPANY]: { icon: 'mdi-domain', color: 'black' },
    [ENTITY_LEVEL_NEW_ITEM]: { icon: 'mdi-JN_NONE', color: 'black' },
};

entityIconLegend[ENTITY_LEVEL_COMPANY_LOW] = entityIconLegend[ENTITY_LEVEL_COMPANY];

const entityIconLegendFolderItem = { icon: 'mdi-folder-account', color: 'primary' };

let i = 1;
for (i; i <= MAX_NORMAL_STRUCTURE_LEVEL; i++) {
    entityIconLegend[i] = entityIconLegendFolderItem;
}

export default entityIconLegend;