<template>
    <!-- /discussion -->
    <div style="max-width: 1100px">
        <ModalVideo
            :isVideoDialogVisible.sync="videoDialog2"
            :url="tutorialUrl"
            @udate:onend="onend($event)"
        ></ModalVideo>

        <Alert v-if="errorMessage" :type="'error'" :message="errorMessage" />

        <Loader v-if="loading" />


        <div v-else>
            <div
                v-if="
                    Array.isArray(allDiscussions) && allDiscussions.length > 0
                "
            >
                <!-- banner -->
                <div
                    class="header-banner pattern-bg mt-0 mt-sm-6"
                    style="min-height: 150px; position: relative"
                >
                    <v-card-text class="pa-5">
                        <div class="d-flex flex-wrap">
                            <h1 class="dash-h1">
                                {{ $t("nav.discussion") }}
                            </h1>

                            <v-spacer></v-spacer>
                            <v-btn
                                small
                                v-if="validSurvey"
                                text
                                class=""
                                @click="fetchSlides"
                                :loading="fetchingSlides"
                                dark
                                color="primary"
                                :title="$t('report.downloadSummary')"
                                ><v-icon left small>mdi-download</v-icon>
                                {{ $t("report.presentation") }}
                            </v-btn>
                        </div>

                        <div
                            class="banner-text mt-6"
                            style="font-size: 1.15rem"
                        >
                            <v-row>
                                <v-col>
                                    <div class="mr-2">
                                        {{
                                            isAdmin
                                                ? $t("manageSurvey")
                                                : $t("questionEditor.survey")
                                        }}
                                    </div>

                                    <v-select
                                        class="surveySelector"
                                        solo
                                        flat
                                        :item-text="surveyText"
                                        @input="filterSurveys"
                                        v-model="surveyId"
                                        background-color="#E9F5FF"
                                        :items="sortedDiscussions"
                                        item-value="surveyId"
                                        :label="$t('selectSurvey.placeholder')"
                                        hide-details
                                    >
                                        <template
                                            v-slot:prepend-inner
                                            v-if="$vuetify.breakpoint.smAndUp"
                                        >
                                            <v-icon
                                                color="#00458D"
                                                style="margin-right: 2px"
                                                >mdi-calendar-arrow-right</v-icon
                                            >
                                        </template>

                                        <template v-slot:selection="{ item }">
                                            <span class="banner-text">
                                                {{ surveyText(item) }}
                                            </span>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <span>
                                                {{ surveyText(item) }}
                                            </span>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col>
                                    <div class="mx-2">
                                        {{ $t("discussionFor") }}
                                    </div>

                                    <div>
                                        <v-autocomplete
                                            background-color="#E9F5FF"
                                            solo
                                            flat
                                            @input="filterSurveys"
                                            v-model="selectedTeam"
                                            :items="availableTeams"
                                            :label="
                                                $t('selectSurvey.selectTeam')
                                            "
                                            hide-details
                                            ><template
                                                v-slot:prepend-inner
                                                v-if="
                                                    $vuetify.breakpoint.smAndUp
                                                "
                                            >
                                                <v-icon
                                                    color="#00458D"
                                                    style="margin-right: 2px"
                                                    >mdi-account-group</v-icon
                                                >
                                            </template>
                                            <template v-slot:no-data>
                                                <v-list-item>
                                                    <v-list-item-title>
                                                        {{
                                                            $t(
                                                                "selectSurvey.noTeam"
                                                            )
                                                        }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </template></v-autocomplete
                                        >
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </div>
                <!-- /banner -->

                <v-row no-gutters class="mt-2" v-if="validSurvey">
                    <v-col cols="12" md="8" lg="9">
                        <Questions
                            :highLightQuestions="highLightQuestions"
                            :discussionId="discussionId"
                            :totalFeedForwards="totalFeedForwards"
                            :stats="discussionSummary"
                            :toDos="toDos"
                            :discussionDone="discussionDone"
                        />
                    </v-col>
                    <v-col
                        cols="4"
                        lg="3"
                        class="pl-2"
                        v-if="$vuetify.breakpoint.mdAndUp"
                    >
                        <v-card
                            class="slide-in"
                            style="background-color: #fff8b9"
                            flat
                            outlined
                            :key="discussionId"
                        >
                            <!-- FF kitty -->
                            <div
                                class="pos-relative"
                                style="
                                    overflow: hidden;
                                    background-color: #fffeee;
                                "
                            >
                                <div
                                    class="d-flex align-center"
                                    style="width: 100%"
                                >
                                    <div
                                        class="pa-3"
                                        style="
                                            width: 70%;
                                            min-width: 70%;
                                            z-index: 2;
                                        "
                                    >
                                        <div>
                                            <div v-if="totalFeedForwards === 0">
                                                <i18n path="thisTimeNoFF">
                                                    <strong
                                                        >{{
                                                            $t(
                                                                "dashboard.noFFs"
                                                            )[0].toLowerCase() +
                                                            $t(
                                                                "dashboard.noFFs"
                                                            ).substring(1)
                                                        }}
                                                    </strong>
                                                </i18n>
                                            </div>

                                            <div v-if="totalFeedForwards > 0">
                                                <div v-if="FFhandleRate < 100">
                                                    {{ $t("bravo") }},
                                                    <br />
                                                    <strong
                                                        class="purple--text"
                                                        style="
                                                            font-size: 1.2rem;
                                                        "
                                                        >{{
                                                            totalFeedForwards
                                                        }}
                                                        {{
                                                            $t(
                                                                "discussionMode.feedforwards"
                                                            )
                                                        }}</strong
                                                    ><br />{{
                                                        $t("ffFromTeam")
                                                    }}!
                                                </div>

                                                <div
                                                    v-if="FFhandleRate === 100"
                                                >
                                                    <i18n
                                                        v-if="
                                                            totalFeedForwards >
                                                            1
                                                        "
                                                        path="allDiscussedNr"
                                                    >
                                                        <strong
                                                            class="purple--text"
                                                            style="
                                                                font-size: 1.2rem;
                                                            "
                                                            >{{
                                                                totalFeedForwards
                                                            }}
                                                            {{
                                                                $t(
                                                                    "discussionMode.feedforwards"
                                                                )
                                                            }}</strong
                                                        >
                                                    </i18n>

                                                    <span v-else
                                                        >{{
                                                            $t("allDiscussed")
                                                        }}
                                                    </span>
                                                </div>

                                                <v-progress-linear
                                                    style="border-radius: 5px"
                                                    :value="FFhandleRate"
                                                    color="purple lighten-1"
                                                    height="30"
                                                    :background-opacity="0.3"
                                                    :background-color="'purple lighten-1'"
                                                    class="mt-2"
                                                >
                                                    <template v-slot:default>
                                                        <span
                                                            class="text-caption"
                                                            >{{
                                                                discussionSummary.taskedFeedForwards +
                                                                discussionSummary.closedFeedForwards
                                                            }}/{{
                                                                totalFeedForwards
                                                            }}
                                                            <span
                                                                >{{
                                                                    $t(
                                                                        "buttons.markDone"
                                                                    ).toLowerCase()
                                                                }}
                                                            </span></span
                                                        >
                                                    </template>
                                                </v-progress-linear>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- image placeholder -->
                                    <div style="width: 30%">
                                        <img
                                            v-if="
                                                FFpicture && FFhandleRate < 100
                                            "
                                            style="width: 100%; height: auto"
                                            :src="
                                                require(`../assets/images/${FFpicture}.png`)
                                            "
                                        />

                                        <img
                                            :style="`width: ${
                                                $vuetify.breakpoint.mdAndUp
                                                    ? 150
                                                    : 100
                                            }px`"
                                            style="
                                                z-index: 1;
                                                height: auto;
                                                position: absolute;
                                                right: -40px;
                                                transform: translateY(-50%);
                                            "
                                            v-if="FFhandleRate === 100"
                                            src="../assets/images/celebrate-slim.png"
                                        />
                                    </div>
                                </div>
                            </div>

                            <!-- tips and tricks -->
                            <div class="pa-3">
                                <div class="d-flex align-center">
                                    <v-img
                                        v-if="FFpicture"
                                        class="slide-in mr-2"
                                        contain
                                        max-width="38"
                                        :src="
                                            require('../assets/images/bulb-slim.png')
                                        "
                                    ></v-img>
                                    <div>
                                        <h3>
                                            {{ $t("helpTitle") }}
                                        </h3>
                                        <div style="color: #a07313">
                                            {{ $t("helpText") }}
                                        </div>
                                    </div>
                                </div>

                                <v-container
                                    class="pa-5"
                                    style="font-size: 1.2rem"
                                >
                                    <!-- TL handbuch -->
                                    <div
                                        v-if="canShowHandbuch"
                                        class="d-flex align-center"
                                    >
                                        <div class="mr-2">&#128221;</div>

                                        <div>
                                            {{ $t("handBuchText") }}
                                            <v-btn
                                                @click="showHandbuchDialog"
                                                class="txt-btn"
                                                color="primary"
                                                text
                                                id="handbuchLink"
                                                >{{ $t("here") }}</v-btn
                                            >
                                        </div>
                                    </div>

                                    <div
                                        class="mt-5 d-flex align-center"
                                        v-if="canShowAskExpert"
                                    >
                                        <div class="mr-2">&#128231;</div>
                                        <AskExpert />
                                    </div>

                                    <div
                                        class="mt-5 d-flex align-center"
                                        v-if="canShowDiscussionVideo"
                                    >
                                        <div class="mr-2">&#128250;</div>
                                        <div>
                                            {{ $t("discussionVideoTitle") }}
                                            <v-btn
                                                @click="showDMVideoDialog"
                                                class="txt-btn"
                                                color="primary"
                                                id="discussionVideoLink"
                                                text
                                                ><span>
                                                    {{ $t("here") }}</span
                                                ></v-btn
                                            >
                                        </div>
                                    </div>

                                    <div class="mt-5 d-flex align-center">
                                        <div>
                                            <v-img
                                                contain
                                                width="1.6rem"
                                                :src="
                                                    require(`../assets/images/clipboard.png`)
                                                "
                                            ></v-img>
                                        </div>
                                        <div
                                            class="pl-2 todoTip d-flex align-center hover-pointer"
                                        >
                                            <div @click="showTodo = !showTodo">
                                                {{
                                                    showTodo
                                                        ? $t("hideTodo")
                                                        : $t("showTodo")
                                                }}
                                            </div>

                                            <v-btn
                                                class="expandTodoBtn"
                                                :ripple="false"
                                                @click="showTodo = !showTodo"
                                                icon
                                                ><v-icon>{{
                                                    showTodo
                                                        ? "mdi-chevron-up"
                                                        : "mdi-chevron-down"
                                                }}</v-icon></v-btn
                                            >
                                        </div>
                                    </div>
                                </v-container>
                            </div>

                            <!-- expanded todo list -->
                            <v-slide-y-transition>
                                <div
                                    v-if="showTodo"
                                    class="mt-n4"
                                    style="background-color: #fffeee"
                                >
                                    <Todo
                                        :discussionId="discussionId"
                                        :selectedTeam="selectedTeam"
                                        @highLightQuestions="
                                            highLightQuestions = $event
                                        "
                                        :FFhandleRate="FFhandleRate"
                                        @discussionDone="
                                            discussionDone = $event
                                        "
                                        @setTodos="toDos = $event"
                                    />
                                </div>
                            </v-slide-y-transition>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- invalid survey (not enough respondents) -->
                <v-row no-gutters class="mt-2" v-if="validSurvey === false">
                    <v-col>
                        <v-card
                            flat
                            color="transparent"
                            max-width="500"
                            class="mx-auto"
                            :key="discussionId"
                        >
                            <v-card-text
                                class="d-flex align-center"
                                style="font-size: 1.2rem"
                            >
                                <TBicon
                                    :width="160"
                                    :icon="'pen'"
                                    :rating="1"
                                />
                                <div v-if="!selectedTeam">
                                    {{ $t("notEnoughAnswers") }}
                                </div>
                                <i18n v-else path="notEnoughAnswersTeamName">
                                    {{ selectedTeam }}
                                </i18n>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-card
                    flat
                    color="transparent"
                    v-if="!discussionId && !selectedTeam"
                    max-width="400"
                    class="mx-auto"
                >
                    <v-card-text
                        class="d-flex align-center"
                        style="font-size: 1.2rem"
                    >
                        <v-img
                            class="slide-in mr-4"
                            contain
                            :max-width="100"
                            :src="require(`../assets/images/kitty_search.png`)"
                            style=""
                        ></v-img>
                        <div>
                            {{ $t("pickValidTeam") }}
                        </div>
                    </v-card-text>
                </v-card>
            </div>

            <v-card
                flat
                color="transparent"
                v-else
                max-width="600"
                class="mx-auto"
            >
                <v-card-text
                    class="d-flex align-center"
                    style="font-size: 1.2rem"
                >
                    <v-img
                        class="fade-in mr-4"
                        contain
                        :src="require('@/assets/images/nodata.png')"
                        style="max-width: 150px"
                    ></v-img>
                    <div>
                        <p class="text-h5 black--text">
                            {{ $t("selectSurvey.noSurvey") }}
                        </p>
                        <p>{{ $t("selectSurvey.comeBack") }}</p>

                        <v-btn @click="toDashboard" large color="primary"
                            ><v-icon left>arrow_back</v-icon
                            >{{ $t("buttons.backdash") }}</v-btn
                        >
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <v-dialog
            v-model="handBuchDialog"
            :max-width="
                handBuchSelection || $i18n.locale !== 'de' ? '100vw' : 600
            "
        >
            <v-card
                :height="
                    handBuchSelection || $i18n.locale !== 'de'
                        ? '100vh'
                        : 'auto'
                "
                :width="
                    handBuchSelection || $i18n.locale !== 'de'
                        ? '100vw'
                        : 'auto'
                "
            >
                <div class="d-flex">
                    <v-btn
                        v-show="handBuchSelection"
                        text
                        @click="handBuchSelection = null"
                        class="mt-1 ml-1"
                        ><v-icon left>mdi-arrow-left</v-icon
                        >{{ $t("buttons.back") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn large icon @click="handBuchDialog = false"
                        ><v-icon>mdi-close-circle</v-icon></v-btn
                    >
                </div>

                <div
                    v-if="$i18n.locale === 'de'"
                    style="height: 100%; width: 100%"
                >
                    <!-- show buttons offering between a normal handbuch and one for bigger teams -->
                    <v-container v-if="!handBuchSelection" class="text-center">
                        <div class="mb-9">{{ $t("someGuides") }}</div>
                        <v-btn
                            @click="handBuchSelection = 'normal'"
                            depressed
                            rounded
                            dark
                            color="joineer"
                            x-large
                            class="py-8"
                            >{{ $t("handBuchText") }}
                        </v-btn>
                        <br />
                        <div class="my-5">{{ $t("or") }}</div>
                        <v-btn
                            @click="handBuchSelection = 'large'"
                            depressed
                            rounded
                            dark
                            color="joineer"
                            x-large
                            class="py-8 mb-4"
                        >
                            <div class="d-flex flex-column">
                                <div>{{ $t("handBuchText") }}</div>
                                <div
                                    class="text-caption pink--text text--lighten-4"
                                >
                                    {{ $t("20people") }}
                                </div>
                            </div>
                        </v-btn>
                    </v-container>
                    <iframe
                        v-else
                        style="height: 100%; width: 100%; border: none"
                        :src="`https://tbpics.joineer.com/documents/${
                            handBuchSelection === 'normal'
                                ? 'TL_handbuch_2021'
                                : 'prepguide_large'
                        }_${$i18n.locale}.pdf`"
                    >
                    </iframe>
                </div>
                <div v-else style="height: 100%; width: 100%">
                    <iframe
                        style="height: 100%; width: 100%; border: none"
                        :src="`https://tbpics.joineer.com/documents/TL_handbuch_2021_${$i18n.locale}.pdf`"
                    >
                    </iframe>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="videoDialog" max-width="800">
            <v-card>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn large icon @click="videoDialog = false" class="ma-1"
                        ><v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </div>

                <v-card-text>
                    <youtube
                        class="w-100"
                        :video-id="dmVideos[$i18n.locale]+'?rel=0&enablejsapi=1&origin=http://localhost:8081&widgetid=3#'"
                        ref="youtube"
                    ></youtube>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
import { Vue, Watch, Component } from "vue-property-decorator";
import { saveAs } from "file-saver";
import {
    getAllDiscussions,
    getTeamSlides,
    getStatistics,
} from "../api/discussion.api";
import AskExpert from "@/components/SelectSurvey/AskExpert.vue";

import Todo from "@/components/SelectSurvey/Todo.vue";
import Questions from "@/components/SelectSurvey/Questions.vue";
import ModalVideo from "@/components/global/ModalVideo.vue";
import { showTutorial, tutorialUrl } from "@/services/tutorial.service.js";
import { calculateHandleRate } from "@/utils/dashboardCode.js";

import {
    discussionSurvey,
    discussionSummary,
    discussionDisplay,
} from "@/models/discussion";


@Component({
    components: {
        AskExpert,
        Todo,
        Questions,
        ModalVideo,
    },
})
export default class SelectSurvey extends Vue {
    name: string = 'SelectSurvey';
    autoplay: 0 | 1;
    errorMessage: string | null = null;
    loading: boolean = true;
    videoDialog2: boolean = false;
    videoElementId: string | null = null;
    tutrialUrls: object = {
        de: 'https://tbpics.joineer.com/videos/tutorial/Diskussionsmodus tutorial.mp4',
        en: 'https://tbpics.joineer.com/videos/tutorial/Discussion Mode Tutorial EN.mp4',
    };

    mounted() {
        this.autoplay = 1;
        this.errorMessage = null;
        this.loading = true;
        this.videoElementId = 'video_' + new Date().valueOf() + Math.random().toString().substr(2);
        this.videoDialog2 = this.showTutorial;

        getAllDiscussions()
            .then((response) => {
                const data = response.data;
                // stop when there are no surveys yet
                if (!data || data.length === 0) {
                    this.allDiscussions = [];
                    return;
                } else if (data.length > 0) {
                    this.allDiscussions = data.reverse();
                    if (this.allDiscussions) {
                        // make a collection of individual team surveys happening in the same period
                        let surveyIds: any = [];
                        let surveySets: discussionDisplay[] = [];
                        for (let i = 0; i < this.allDiscussions.length; i++) {
                            if (
                                surveyIds.indexOf(
                                    this.allDiscussions[i].surveyId
                                ) === -1
                            ) {
                                surveyIds.push(this.allDiscussions[i].surveyId);
                                surveySets.push({
                                    surveyId: this.allDiscussions[i].surveyId,
                                    startedAt: this.allDiscussions[i].startedAt,
                                    endedAt: this.allDiscussions[i].endedAt,
                                });
                            }
                        }

                        this.sortedDiscussions = surveySets;
                        this.surveyId = this.sortedDiscussions[0].surveyId; //set latest survey to be default option
                        this.selectedTeam = this.availableTeams[0];
                        this.filterSurveys();
                    }
                }
            })
            .catch((error) => {
                this.errorMessage = this.parseError(error);
            })
            .finally(() => (this.loading = false));
    }

    sortedDiscussions: discussionDisplay[] | null = null; //simplified survey item in dropdown
    surveyId: string = ""; //by default the latest survey

    selectedTeam: null | string = null;
    discussionId: string | null = null; //specific team survey to proceed with
    allDiscussions: null | discussionSurvey[] = null;

    sortDiscussions(discussions: discussionSurvey[]) {
        let sortedDiscussions = [];
        if (!discussions || discussions.length === 0) {
            return sortedDiscussions;
        } else {
            discussions.reverse();
        }
    }

    // get a specific team's discussionId for the given survey
    filterSurveys() {
        if (this.allDiscussions) {
            let discussion = this.allDiscussions.find(
                (discussion) =>
                    discussion.teamName === this.selectedTeam &&
                    discussion.surveyId === this.surveyId
            );

            if (discussion) {
                this.discussionId = discussion.id;
            } else {
                this.discussionId = null;
                this.selectedTeam = null;
            }
        }
    }

    discussionSummary: null | discussionSummary = null; // nr of FFs etc
    totalFeedForwards: null | number = null;
    toDos: any = [];
    discussionDone: boolean | null = null;
    validSurvey: null | boolean = null; //whether currently selected survey has enough statistics to count
    fetchStats(): void {
        if (this.discussionId) {
            getStatistics(this.discussionId).then((response) => {
                this.discussionSummary = response.data;
                this.totalFeedForwards = response.data.totalFeedForwards;

                if (response.data.statistic == null) {
                    this.validSurvey = false;
                    this.toDos = [];
                    this.discussionDone = null;
                } else {
                    this.validSurvey = true;
                }
            });
        } else {
            this.discussionSummary = null;
            this.totalFeedForwards = null;
            this.validSurvey = null;
            this.toDos = [];
            this.discussionDone = null;
        }
    }

    handBuchDialog: boolean = false;
    handBuchSelection: null | "normal" | "large" = null; //'large' stands for large team version
    showHandbuchDialog(): void {
        this.piwikAnalytics([
            "trackEvent",
            "Discussion actions",
            "Show DM Handbuch",
            this.$i18n.locale,
        ]);
        this.handBuchDialog = true;
    }

    dmVideos = {
        en: "EJVOdeM25NM",
        de: "i63vwECqSSs",
        fr: "nkEAEsnb_SU",
    };
    videoDialog: boolean = false;
    showDMVideoDialog() {
        this.piwikAnalytics([
            "trackEvent",
            "Discussion actions",
            "Show DM video",
            this.$i18n.locale,
        ]);
        this.videoDialog = true;
    }

    onend(val: boolean): void {
        this.l('in onend', val);

        if (val) {
            this.videoDialog2 = false; //hide when finished
        }
    }

    startPresentation(): void {
        this.$router.push({ path: `/discussion/${this.discussionId}` });
    }

    fetchingSlides: boolean = false;
    fetchSlides(): void {
        this.fetchingSlides = true;
        getTeamSlides(this.discussionId, this.$i18n.locale)
            .then((response) => {
                this.piwikAnalytics([
                    "trackEvent",
                    "Discussion actions",
                    "Slides downloaded",
                ]);
                this.$store.dispatch("showSnack", [true, "downloading"]);
                let fileName =
                    response.headers["content-disposition"].split(
                        "filename="
                    )[1];
                let contentType = response.headers["content-type"];

                saveAs(
                    new Blob([response.data], { type: contentType }),
                    fileName
                );
            })
            .catch((error) => {
                console.error("Error downloading team slides:", error);
                this.$store.dispatch("showSnack", [true, "downloadError"]);
            })
            .finally(() => {
                this.fetchingSlides = false;
            });
    }

    toDashboard(): void {
        this.$router.push({name: 'tb'});
    }

    renderDate(date): string {
        if (this.$vuetify.breakpoint.mdAndUp) {
            //@ts-ignore
            return this.$moment(date).format("ddd DD MMM YYYY");
        } else {
            //@ts-ignore
            return this.$moment(date).format("DD/MM/YY");
        }
    }
    surveyText(item): string {
        let startTime = this.renderDate(item.startedAt);
        let endTime = this.renderDate(item.endedAt);

        return startTime + " - " + endTime;
    }

    get companyId(): string {
        return this.$store.getters["auth/companyId"];
    }
    get canShowAskExpert(): boolean {
        return this.$store.getters.checkFeature("ASK_EXPERT");
    }
    get canShowDiscussionVideo(): boolean {
        const videoLanguages = ["en", "de", "fr"];
        return videoLanguages.includes(this.$i18n.locale);
    }
    get FFpicture(): null | string {
        if (
            this.totalFeedForwards !== null &&
            this.totalFeedForwards !== undefined
        ) {
            if (this.FFhandleRate === 100) {
                return "celebrate-slim";
            } else {
                return this.totalFeedForwards === 0 ? "kitty_search" : "gift2";
            }
        } else {
            return null;
        }
    }
    get FFhandleRate(): null | number {
        if (this.discussionSummary) {
            let handleRate = calculateHandleRate(
                this.discussionSummary.totalFeedForwards,
                this.discussionSummary.closedFeedForwards,
                this.discussionSummary.taskedFeedForwards
            );
            return handleRate;
        } else {
            return null;
        }
    }
    get canShowHandbuch(): boolean {
        const handbuchLanguages = ["en", "de", "fr", "it"];
        // whether we can show the user our TL handbuch PDF

        return handbuchLanguages.includes(this.$i18n.locale);
    }
    get userRoles() {
        return this.$store.getters["auth/userRoles"];
    }
    get isAdmin(): boolean {
        if (this.userRoles) {
            return (
                this.userRoles.includes("ROLE_ADMIN") ||
                this.userRoles.includes("ROLE_SUPERADMIN")
            );
        } else {
            return false;
        }
    }

    get availableTeams() {
        if (this.allDiscussions && this.surveyId) {
            let teams: string[] = [];
            this.allDiscussions.filter((discussion) => {
                if (
                    // if not already added to teams (unique)
                    teams.indexOf(discussion.teamName) === -1 &&
                    // if this team did a particular survey (same surveyId)
                    discussion.surveyId === this.surveyId
                ) {
                    teams.push(discussion.teamName);
                }
            });
            teams.sort(function (a, b) {
                return (a as string)
                    .toLowerCase()
                    .localeCompare((b as string).toLowerCase());
            });
            return teams;
        } else {
            return [];
        }
    }

    //--- tutorial

    get showTutorial() {
        return showTutorial(this.$options.name, this.$store, this.tutrialUrls, this.$i18n.locale);
    }

    get tutorialUrl() {
        return tutorialUrl(this.tutrialUrls, this.$i18n.locale, this.$store);
    }

    @Watch("discussionId")
    onDiscussionChanged() {
        this.fetchStats();
    }

    @Watch("videoDialog")
    onVideoDialogChanged(val) {
        if (!val) {
            //@ts-ignore
            this.$refs.youtube.player.pauseVideo();
        }
    }

    highLightQuestions: boolean = false; //styling variable
    showTodo: boolean = false; //whether todo section is expanded
}
</script>

<style>
.expandTodoBtn::before {
    color: #f7ee97 !important;
}

.v-select.surveySelector .v-select__selections input {
    width: 0 !important;
    min-width: 0 !important;
}

.todoTip {
    border-radius: 4px;
    transition: background-color 0.1s linear;
}

.todoTip:hover {
    background-color: #f7ee97;
}

.dialog_content_class {
    height: 100%;
    width: 100%;
}

.w-100 {
    width: 100%
}
</style>
