var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.topGrade)?_c('div',{staticClass:"d-flex align-center justify-center",class:{ 'font-l': _vm.$vuetify.breakpoint.smAndUp }},[_c('AvgTooltip',{attrs:{"stats":_vm.statistics,"questionAverage":true},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"stats"},[_c('span',{staticClass:"stat-text"},[_vm._v("Ø "+_vm._s(_vm.curatedNumber(_vm.statistics.average)))]),(_vm.statistics.changeAverage)?_c('span',{class:{
                        'green--text': _vm.avgChangeGood,
                        'red--text': !_vm.avgChangeGood
                    }},[_vm._v(" "+_vm._s(_vm.avgChangeGood ? "↗" : "↘")),_c('span',[_vm._v(_vm._s(_vm.curatedNumber(_vm.avgChange))+"%")])]):_vm._e()])]},proxy:true}],null,false,2659939117)}),_c('DevTooltip',{attrs:{"change":_vm.statistics.changeDeviation,"deviation":_vm.statistics.deviation,"questionDev":true},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"stats"},[_c('span',{staticClass:"stat-text ml-6"},[_vm._v("↔ "+_vm._s(_vm.curatedNumber(_vm.statistics.deviation)))]),(_vm.statistics.changeDeviation)?_c('span',{class:{
                        'green--text': _vm.devChangeGood,
                        'red--text': !_vm.devChangeGood
                    }},[_vm._v(" "+_vm._s(_vm.devChangeGood ? "↘" : "↗")),_c('span',[_vm._v(_vm._s(_vm.curatedNumber(_vm.statistics.changeDeviation)))])]):_vm._e()])]},proxy:true}],null,false,1719138954)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }