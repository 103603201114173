<template>
    <v-dialog v-model="dialogState" max-width="400">
        <v-card>
            <v-toolbar flat color="grey lighten-5">
                <v-toolbar-title>{{ $t("deleteProfile") }} </v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn @click="$emit('dialog', false)" icon
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text class="mt-3">
                <div class="mb-3">
                    {{ $t("deleteProfileConfirmation") }}
                </div>

                <v-alert v-if="error" type="error" outlined text>
                    {{ error }}
                </v-alert>

                <v-alert v-if="userDeleted" type="success" outlined text>
                    <div>{{ $t("profileDeleted") }}</div>

                    <div>
                        {{ $t("directingToPrevious") }}
                        ...
                        <span>{{ countDown }}</span>
                    </div>
                </v-alert>
                <div class="mt-3">
                    <v-btn
                        v-if="!userDeleted"
                        :disabled="loading"
                        large
                        @click="deleteUser"
                        color="primary"
                        >{{ $t("buttons.yesDelete") }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { removeUser } from "@/api/users.api";

export default {
    props: ["user", "dialog"],
    data: () => ({
        loading: false,
        error: null,
        userDeleted: false,
        countDown: 3,
    }),
    mounted() {}, 
    methods: {
        deleteUser() {
            this.piwikAnalytics(['trackEvent','User management actions', 'Deleted user via Profile']);
            this.error = null;
            this.loading = true;

            removeUser(this.user.id)
                .then(() => {
                    this.userDeleted = true;

                    this.startCountdown();
                })
                .catch((error) => {
                    this.error = this.parseError(error);
                })
                .finally(() => {
                    setTimeout(() => (this.loading = false), 700);
                });
        },
        startCountdown() {
            setTimeout(() => {
                this.countDown = 2;
            }, 500);
            setTimeout(() => {
                this.countDown = 1;
            }, 1000);
            setTimeout(() => {
                this.countDown = 0;
                this.$router.back();
            }, 1500);
        },
    },
    computed: {
        dialogState: {
            get() {
                return this.dialog;
            },
            set(val) {
                this.$emit("dialog", val);
            },
        },
    },
};
</script>
